import React, { useEffect } from 'react';
import axios from '../utils/axiosConfig';
var miStorage = window.localStorage;
const AzureUpload = () => {
  const containerName = 'images';
  const apiUrl = `https://visuallogisticsstorage.blob.core.windows.net/${containerName}`;

  useEffect(() => {
    // Simula la carga de imágenes al montar el componente
    cargarImagenes();
  }, []);

  const cargarImagenes = async () => {
    const carpetaImagenes = document.getElementById('inputImagenes').files;

    for (const imagen of carpetaImagenes) {
      try {
        const urlBlob = `${apiUrl}/${imagen.name}?sv=2022-11-02&ss=bf&srt=o&sp=wactfx&se=2024-02-28T05:17:52Z&st=2023-11-16T21:17:52Z&spr=https&sig=vlFteAUMz2V0zB32itZuTjwSRPXy2BNfnwm626%2FJEXg%3D`;

        // Cargar imagen utilizando axios
        const response = await axios.put(urlBlob, imagen, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            // Agrega otros encabezados si es necesario
          },
        });

        if (response.status === 201) {
          console.log(`La imagen '${imagen.name}' fue cargada exitosamente al contenedor '${containerName}'.`);
        } else {
          console.error(`Error al cargar la imagen '${imagen.name}'. Código de estado: ${response.status}`);
          console.error(response.data);
        }
      } catch (error) {
        console.error(`Error al cargar la imagen '${imagen.name}': ${error.message}`);
      }
    }
  };

  return (
    <div>
      <input type="file" id="inputImagenes" multiple />
      <button onClick={cargarImagenes}>Cargar Imágenes</button>
    </div>
  );
};

export default AzureUpload;
