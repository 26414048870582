import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../Pages/Css/react-sidenav.css';
import CryptoJS from 'crypto-js';
import axios from '../utils/axiosConfig';
import ModalReporteServicio from '../components/modals/modalReporteServicio';
import ModalReporteConsolidadoAjustado from '../components/modals/modalReporteConsolidadoAjustado';
import FormStaticsMapsRecorrido from '../components/formsSolicitudes/formStaticsMapsRecorrido';
import ReporteServicio from './Reporteservicio'
var miStorage = window.localStorage;
const Menu = () => {
    const [expanded, setExpanded] = useState(true);
    const [activeKey, setActiveKey] = useState('1');
    const [isDialogOpenRServicioAjustado, setIsDialogOpenRServicioAjustado] = useState(false);
    const [isDialogOpenModalReporteServicio, setIsDialogOpenModalReporteServicio] = useState(false);
    const [ultPunto, setUltPunto] = useState(-1);
    const [contractR, setContractR] = useState(-1);
    const [infoContrato, setInfoContrato] = useState({});

    async function encrypt(user, token) {
        const key = CryptoJS.enc.Utf8.parse(token);
        const message = user;

        const hmac = CryptoJS.HmacSHA256(message, key);
        const encryptedMessage = hmac.toString(CryptoJS.enc.Base64);
        return encryptedMessage;
    }
    const GetKeyApp = async (project) => {
        console.log("***************entrooo");
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER + '/operaciones/getkeyapp', {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                withCredentials: true
            });

            console.log(response);

            if (response.data.success === true) {
                redirectToAuthPage(response.data.user, response.data.data[0].KEYWS, project);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const redirectToAuthPage = async (user, key, project) => {
        const encryptedToken = await encrypt(user, key);
        const redirectUrl = `https://mapcenter.cargotronics.com/${project}/Autenticacion.jsp?user=${user}&token=${encryptedToken}`;
        window.open(redirectUrl, '_blank');
    };

    const onCloseReporteServicioAjustado = () => {
        setIsDialogOpenRServicioAjustado(false);
    }
    const onCloseModalReporteServicio = () => {
        setIsDialogOpenModalReporteServicio(false);
        setUltPunto(-1);
        setContractR(-1);
    }

    const handleContrato = (newValue) => {
        console.log("Elvalor newValue:", newValue);
        let dataContratoUnico = {
            "contrato": newValue
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/operaciones/getcontratounico", JSON.stringify(dataContratoUnico), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setInfoContrato(res.data.data[0]);
                }
            });
    }
    const handleUltPunto = (newValue) => {
        console.log("Ultimo Punto", newValue);
        setUltPunto(newValue);
    }

    const handleUltRecorrido = (newValue) => {
        console.log("UltimoRecorrdio", newValue);
        setContractR(newValue);
    }

    const handleSubmitAndOpenReporteAjustado = () => {
        console.log("Informacion de contrato==> ", infoContrato);
        if (infoContrato) {
            setIsDialogOpenRServicioAjustado(false);
            setIsDialogOpenModalReporteServicio(true);
        }
    }

    return (

        <>
            <SideNav
                onSelect={(selected) => {
                    // Add your code here
                    if (selected == "trafico") {
                        window.location = '/trafico';
                    }
                    if (selected == "trayecto") {
                        window.open('/trayecto', '_blank');
                    }
                    if (selected == "historico") {
                        window.location = '/historico';
                    }
                    if (selected == "reportes") {
                        window.open('/reportes', '_blank');
                    }
                    if (selected == "roles") {
                        window.location = '/roles';
                    }
                    if (selected == "usuarios") {
                        window.location = '/usuarios';
                    }
                    if (selected == "mapcenter") {
                        GetKeyApp("WL2");
                    }
                    if (selected == "control") {
                        window.open('/controldevices', '_blank');
                    }
                    if (selected == "solicitudes") {
                        window.open('/solicitudes', '_blank');
                    }
                    if (selected == "geocerca") {
                        GetKeyApp("Geocerca");
                    }
                    if (selected == "inventarios") {
                        GetKeyApp("Inventario");
                    }
                    if (selected == "reorteAjustado") {
                        setIsDialogOpenRServicioAjustado(true);
                    }

                }}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="Operaciones">
                        <NavIcon>
                            <i className='bx bxs-truck bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            OPERACIONES
                        </NavText>
                        <NavItem eventKey="solicitudes">
                            <NavText>
                                Solicitudes de Servicios
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="trafico">
                            <NavText>
                                Tráfico en Ruta
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="historico">
                            <NavText>
                                Servicios Históricos
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="mapcenter">
                            <NavText>
                                Mapcenter
                            </NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem eventKey="Geografia">
                        <NavIcon>
                            <i className='bx bxs-map-alt bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            GEOGRAFÍA
                        </NavText>
                        <NavItem eventKey="ciudades">
                            <NavText>
                                Ciudades
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="rutas">
                            <NavText>
                                Rutas
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="trayecto">
                            <NavText>
                                Georutas
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="geocerca">
                            <NavText>
                                Geocercas
                            </NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem eventKey="Dispositivos">
                        <NavIcon>
                            <i className='bx bxs-devices bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            DISPOSITIVOS
                        </NavText>
                        <NavItem eventKey="control">
                            <NavText>
                                Control Equipos
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="inventarios">
                            <NavText>
                                Inventarios
                            </NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem eventKey="Administración">
                        <NavIcon>
                            <i className='bx bxs-briefcase bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            ADMINISTRACIÓN
                        </NavText>
                        <NavItem eventKey="clientesNegociaciones">
                            <NavText>
                                Clientes y Negociaciones
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="facturacion">
                            <NavText>
                                Facturación
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="carteraClientes">
                            <NavText>
                                Cartera Clientes
                            </NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem eventKey="Reportes">
                        <NavIcon>
                            <i className='bx bxs-bar-chart-square bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            REPORTES
                        </NavText>
                        <NavItem eventKey="reportes">
                            <NavText>
                                Reportes
                            </NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem eventKey="Configuración">
                        <NavIcon>
                            <i className='bx bxs-cog bx-sm bx-tada-hover'></i>
                        </NavIcon>
                        <NavText>
                            CONFIGURACIÓN
                        </NavText>
                        <NavItem eventKey="roles">
                            <NavText>
                                Roles
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="usuarios">
                            <NavText>
                                Usuarios
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="reorteAjustado">
                            <NavText>
                                Reporte Ajustado
                            </NavText>
                        </NavItem>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>

            {/* Modal Reporte consolidado ajustado */}
            <ModalReporteConsolidadoAjustado
                isOpen={isDialogOpenRServicioAjustado}
                title={'Reporte consolidado ajustado'}
                onClose={onCloseReporteServicioAjustado}
                childComponent={<FormStaticsMapsRecorrido
                    // infoContrato={infoContrato}
                    handleContrato={(value) => handleContrato(value)}
                    handleUltPunto={(value) => handleUltPunto(value)}
                    handleUltRecorrido={(value) => handleUltRecorrido(value)}
                    onSubmit={handleSubmitAndOpenReporteAjustado}
                />}
            />
            {/* Modal Reporte consolidado ajustado */}
            {/* Modal Reporte servicio */}
            <ModalReporteServicio
                isOpen={isDialogOpenModalReporteServicio}
                onClose={onCloseModalReporteServicio}
                childComponent={<ReporteServicio
                    contrato={infoContrato}
                    ultPunto={ultPunto}
                    contractR={contractR}
                />}
            />
            {/* Modal Reporte servicio */}
        </>
    );
};
ReactDOM.createRoot(document.getElementById("menu")).render(<Menu />);
