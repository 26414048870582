import React, { useEffect, useState } from 'react';
import '../../Pages/Css/printComponet.css';
import { createRoot } from 'react-dom/client';
import { Button } from 'react-bootstrap';
import axios from '../../utils/axiosConfig';

const PrintComponent = ({ data, onRenderComplete }) => {
  const [allData, setAllData] = useState([]);
  const [nroContenedor, setNroContenedor] = useState('');
  const [horaPatio, setHoraPatio] = useState('');
  const [diaPatio, setDiaPatio] = useState('');
  const [mesPatio, setMesPatio] = useState('');
  const [anioPatio, setAnioPatio] = useState('');
  const [horaPlanta, setHoraPlanta] = useState('');
  const [diaPlanta, setDiaPlanta] = useState('');
  const [mesPlanta, setMesPlanta] = useState('');
  const [anioPlanta, setAnioPlanta] = useState('');
  const [respuestas1, setRespuesta1] = useState([]);
  const [respuestas2, setRespuesta2] = useState([]);


  const renderRespuestas1 = (index) => {
    if (!respuestas1.length) return null;
    const rta = respuestas1[index];
    return (
      <div key={index} className="op1">
        <div>{rta === 1 ? 'X' : ''}</div>
        <div>{rta === 2 ? 'X' : ''}</div>
        <div style={{ borderRight: 'none' }}>{rta === 3 ? 'X' : ''}</div>
      </div>
    );
  };
  const renderRespuestas2 = (index) => {
    if (!respuestas2.length) return null;
    const rta = respuestas2[index];
    return (
      <div key={index} className="op2">
        <div>{rta === 1 ? 'X' : ''}</div>
        <div>{rta === 2 ? 'X' : ''}</div>
        <div style={{ borderRight: 'none' }}>{rta === 3 ? 'X' : ''}</div>
      </div>
    );
  };

  const fillAllData = async () => {
    let dataContrato = {
      // contrato: data.ContractID
      contrato: "SERV-00384746"
    };
    console.log("El contrato a analizar para el reporte lista chequeo: ", dataContrato);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/contratos/getlistachequeo`,
        JSON.stringify(dataContrato),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );
      if (!res.data.success) {
        window.location = "/";
      } else {
        console.log("Informacion de la data para imprimir: ***", res.data.data);
        setAllData(res.data.data);
        setNroContenedor(res.data.data[0].contenedor);
        setRespuesta1(res.data.data[0].respuestas1.split(',').map(Number));
        setRespuesta2(res.data.data[0].respuestas2.split(',').map(Number));
        formattedPatio(res.data.data[0].datetime_patio);
        formattedPlanta(res.data.data[0].datetime_iplanta);
        console.log("Nro Contenedor: ***: ", res.data.data[0].contenedor);
      }
    } catch (err) {
      console.log(err);

    }
  };

  const formattedPlanta = (datetimeString) => {
    const date = new Date(datetimeString);

    // Extraer el día, mes, año, hora, minutos y segundos
    const day = date.getUTCDate(); // Día del mes
    const month = date.getUTCMonth() + 1; // Mes (0-based, por eso se suma 1)
    const year = date.getUTCFullYear(); // Año
    const hours = date.getUTCHours(); // Hora
    const minutes = date.getUTCMinutes(); // Minutos
    const seconds = date.getUTCSeconds(); // Segundos

    // Formatear para asegurarse de que los valores de mes, hora, minutos y segundos tengan dos dígitos
    const pad = (num) => num.toString().padStart(2, '0');

    // Obtener los valores en formato de dos dígitos si es necesario
    const formattedDay = pad(day);
    const formattedMonth = pad(month);
    const formattedYear = year; // Año no necesita formato adicional
    const formattedHours = pad(hours);
    const formattedMinutes = pad(minutes);
    const formattedSeconds = pad(seconds);

    // Formatear la hora como HH:MM:SS
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    console.log("Día:", formattedDay); // Ejemplo: 18
    console.log("Mes:", formattedMonth); // Ejemplo: 07
    console.log("Año:", formattedYear); // Ejemplo: 2024
    console.log("Hora:", formattedTime); // Ejemplo: 06:32:21

    setDiaPlanta(pad(day));
    setMesPlanta(pad(month));
    setAnioPlanta(year);
    setHoraPlanta(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`);
  }

  const formattedPatio = (datetimeString) => {
    const date = new Date(datetimeString);

    // Extraer el día, mes, año, hora, minutos y segundos
    const day = date.getUTCDate(); // Día del mes
    const month = date.getUTCMonth() + 1; // Mes (0-based, por eso se suma 1)
    const year = date.getUTCFullYear(); // Año
    const hours = date.getUTCHours(); // Hora
    const minutes = date.getUTCMinutes(); // Minutos
    const seconds = date.getUTCSeconds(); // Segundos

    // Formatear para asegurarse de que los valores de mes, hora, minutos y segundos tengan dos dígitos
    const pad = (num) => num.toString().padStart(2, '0');

    // Obtener los valores en formato de dos dígitos si es necesario
    const formattedDay = pad(day);
    const formattedMonth = pad(month);
    const formattedYear = year; // Año no necesita formato adicional
    const formattedHours = pad(hours);
    const formattedMinutes = pad(minutes);
    const formattedSeconds = pad(seconds);

    // Formatear la hora como HH:MM:SS
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    console.log("Día:", formattedDay); // Ejemplo: 18
    console.log("Mes:", formattedMonth); // Ejemplo: 07
    console.log("Año:", formattedYear); // Ejemplo: 2024
    console.log("Hora:", formattedTime); // Ejemplo: 06:32:21

    setDiaPatio(pad(day));
    setMesPatio(pad(month));
    setAnioPatio(year);
    setHoraPatio(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`);
  }

  const handlePrint = () => {
    const printContents = document.getElementById("print_container_checkList").outerHTML;
    const printTitle = `FORMATO INSPECCIÓN DE SEGURIDAD ${data.ContractID}`;


    const newWindow = window.open('', '_blank');

    const customStyles = `
    

     .container {
          width: 816px;
          height:1158px;
          display: flex;
          flex-direction: column;
          border: 1px solid black;
          font-size: small;

      }

      /*HEADER*/
      .header {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 15%;
      }

      .header>.headerTitle {
          font-weight: bold;
          padding: 12px;
          border-bottom: 1px solid black;
          text-align: center;
          height: 10%;
      }

      .header>.headerNumerocontenedor {
          width: 100%;
          height: 45%;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid black;
      }

      .header>.headerTipoContenedor {
          width: 100%;
          height: 45%;
          display: flex;
          flex-direction: row;
      }

      .header>.headerNumerocontenedor>.logo {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
      }

      .header>.headerNumerocontenedor>.nrooContainer {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
          border-left: 1px solid black;
      }

      .header>.headerNumerocontenedor>.nrooContainer>.titlenroContainer {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-bottom: 1px solid black;
      }

      .header>.headerNumerocontenedor>.nrooContainer>.nroDescriptionContainer {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .header>.headerNumerocontenedor>.nrooContainer>.nroDescriptionContainer>.div1 {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-right: 1px solid black;
      }

      .header>.headerNumerocontenedor>.nrooContainer>.nroDescriptionContainer>.div2 {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .header>.headerNumerocontenedor>.patioPlanta {
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.patio {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.patio>.titlePatio {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-bottom: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.patio>.Fecha {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-bottom: 1px solid black;
      }
      .header>.headerNumerocontenedor>.patioPlanta>.patio>.Fecha div{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-right: 1px solid black;
      }


      .header>.headerNumerocontenedor>.patioPlanta>.planta>.Fecha div{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-right: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.patio>.FechaResult {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;

      }

      .header>.headerNumerocontenedor>.patioPlanta>.patio>.FechaResult div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-right: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.planta {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.planta>.titlePlanta {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-bottom: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.planta>.Fecha {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-bottom: 1px solid black;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.planta>.FechaResult {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .header>.headerNumerocontenedor>.patioPlanta>.planta>.FechaResult div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-right: 1px solid black;
      }


      .header>.headerTipoContenedor>.nroIdChequeo {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;

      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
          border-left: 1px solid black;
      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.patioOrigen {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid black;

      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.patioOrigen>.titlePatio {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
          font-weight: bold;
      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.patioOrigen>.contentPatio {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.Naviera {
          width: 100%;
          height: 100%;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          display: flex;

      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.Naviera>.titleNaviera {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
          font-weight: bold;
      }

      .header>.headerTipoContenedor>.descripcionPatiosNaviera>.Naviera>.contentNaviera {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

      }

      .header>.headerTipoContenedor>.horaFecha {
          display: flex;
          width: 30%;
          height: 100%;
          flex-direction: row;
          justify-content: space-between;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent1 {
          display: flex;
          height: 100%;
          width: 50%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid black;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent1>.hora {
          display: flex;
          height: 40%;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid black;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent1>.hora>.horaTitle {
          display: flex;
          height: 100%;
          width: 34%;
          flex-direction: row;
          border-right: 1px solid black;
      }


      .header>.headerTipoContenedor>.horaFecha>.horaContent1>.hora>.horaDesc {
          display: flex;
          height: 100%;
          width: 70%;
          flex-direction: row;
          justify-content: center;
          align-items: start;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent2 {
          display: flex;
          height: 100%;
          width: 50%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent2>.hora {
          display: flex;
          height: 40%;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid black;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent2>.hora>.horaTitle {
          display: flex;
          height: 100%;
          width: 35%;
          flex-direction: row;
          border-right: 1px solid black;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent1>.tipoRegistro {
          display: flex;
          height: 60%;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 8pt;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent2>.tipoRegistro {
          display: flex;
          height: 60%;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 8pt;
      }

      .header>.headerTipoContenedor>.horaFecha>.horaContent2>.hora>.horaDesc {
          display: flex;
          height: 100%;
          width: 70%;
          flex-direction: row;
          justify-content: center;
          align-items: start;
      }


      /*HEADER*/


      /*CUESTIONARIO*/

      /*CUESTIONARIO*/

      .answers {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
      }

      .answers>.respuestasOpciones {
          width: 100%;
          height: 3%;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid black;
          border-top: 1px solid black;
      }

      .answers>.respuestasOpciones>.spceBlank {
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: row;
          border-right: 1px solid black;
      }

      .answers>.respuestasOpciones>.opciones {
          display: flex;
          flex-direction: row;
          width: 30%;
      }

      .answers>.respuestasOpciones>.opciones>.op1 {
          display: flex;
          flex-direction: row;
          width: 50%;
      }

      .answers>.respuestasOpciones>.opciones>.op1>div {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          border-right: 1px solid black;
          font-size: 10px;
      }

      .answers>.respuestasOpciones>.opciones>.op2 {
          display: flex;
          flex-direction: row;
          width: 50%;
      }

      .answers>.interiorContenenedorTitle {
          width: 100%;
          text-align: start;
          height: auto;
          padding: 0;
          border-bottom: 1px solid black;
      }

      .answers>.respuestasOpciones>.opciones>.op2>div {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          border-right: 1px solid black;
          font-size: 10px;
      }

      .answers>.interiorContenenedorTitle>.title {
          font-weight: bold;
          margin-bottom: 0;
          margin-top: 0;
          font-size: 12px;
          

      }

      .answers>.interiorContent {
          width: 100%;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid black;
      }

      .answers>.interiorContent .contents {
          display: flex;
          width: 70%;
          flex-direction: column;
          border-right: 1px solid black;
      }

      .answers>.interiorContent .contents .content1,
      .answers>.interiorContent .contents .content2 {
          text-align: start;
          display: flex;
          align-items: center;
          height: auto;
          /* Centra verticalmente */
          justify-content: flex-start;
          margin-left: 5px;
          font-size: 10px;

      }

      .answers>.interiorContent .opciones {
          display: flex;
          flex-direction: row;
          width: 30%;
      }

      .answers>.interiorContent .opciones .op1 {
          display: flex;
          width: 50%;
          border-right: 1px solid black;
      }

      .answers>.interiorContent .opciones .op1 div {
          display: flex;
          width: 100%;
          border-right: 1px solid black;
          align-items: center;
          text-align: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
      }

      .answers>.interiorContent .opciones .op2 {
          display: flex;
          width: 50%;
          
      }

      .answers>.interiorContent .opciones .op2 div {
          display: flex;
          width: 100%;
          border-right: 1px solid black;
          align-items: center;
          text-align: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;

      }


      .footer {
          width: 99.8%;
      }

      .footer1 {
          width: 100%;
          height: 155px;
          /* background-color: aqua; */
          
          display: flex;
          flex-direction: row;
      }

      .footer1 .primerParte {
          width: 50%;
          display: flex;
          flex-direction: column;
          /* background-color: blueviolet; */
          border-right: 1px solid black;
      }

      .footer1 .primerParte {
          border-right: 1px solid black;
      }

      .footer1 .footerContentSellos {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid black;
      }

      .footer1 .sellosCarat {
          text-align: start;
          width: 60%;
          height: auto;
          margin-left: 5px;
          border-right: 1px solid black;
          font-size: 10px;
          /* Espacio entre los elementos */
      }

      .footer1 .selloBtllaH {
          text-align: center;
          width: 40%;
          display: flex;
          flex-direction: column;
      }

      .footer1 .selloBtllaH .titleSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer1 .selloBtllaH .descrSello {
          font-weight: lighter;
          display: flex;
          align-items: center;
          justify-items: center;
          justify-content: center;
          height: 70%;
          text-transform: uppercase;
          font-size: 12px;
      }

      .footer1 .terminal {
          display: flex;
          flex-direction: row;
          width: 100%;
      }

      .footer1 .primerParte .terminal .vertical1 {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          text-align: center;
          font-size: 6pt;
          width: 12%;
          height: 98px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid black;
      }



      .footer1 .firmayNombre {
          display: flex;
          flex-direction: column;
          width: 57%;
          border-right: 1px solid black;
          height: 100px;
      }

      .footer1 .firmayNombre .firma {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 14.5%;
          border-bottom: 1px solid black;
          font-size: 12px;
      }

      .footer1 .firmayNombre .firma .firm {
          display: flex;
          flex-direction: column;
          width: 49%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
          text-align: center;
          align-items:center;
      }

      .footer1 .firmayNombre .firma .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          justify-content: center;
      }

      .footer1 .firmayNombre .nombre {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 45%;
          border-bottom: 1px solid black;
      }

      .footer1 .firmayNombre .nombre .firm {
          display: flex;
          flex-direction: column;
          width: 49%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          align-items:center;
          font-weight: bold;
          font-size: 12px;
      }

      .footer1 .firmayNombre .nombre .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          justify-content: center;
          font-size: 10px;
      }

      .footer1 .firmayNombre .cedula {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 36%;
          /* border-bottom: 1px solid black; */
      }

      .footer1 .firmayNombre .cedula .firm {
          display: flex;
          flex-direction: column;
          width: 49%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          align-items:center;
          font-weight: bold;
          font-size: 12px;
      }

      .footer1 .firmayNombre .cedula .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          justify-content: center;
          text-transform: uppercase;
          font-size: 10px;
      }

      .footer1 .sellos {
          display: flex;
          flex-direction: column;
          width: 40%;
          height: 75%;
      }


      .footer1 .sellos .titleSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer1 .sellos .contentSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 40%;
          align-items: center;
          justify-content: center;
          display: flex;
      }

      .footer1 .sellos .titleOpLogis {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer1 .secondParte {
          width: 50%;
          display: flex;
          flex-direction: flex;
          /* background-color: rgb(86, 48, 121); */
      }

      .footer1 .secondParte .vertical2 {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          text-align: center;
          font-size: small;
          width: 15%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid black;
          border-right: 1px solid black;
          font-size: 12px;


      }


      .footer1 .secondParte .seconmdContent {
          width: 100%;
          display: flex;
          flex-direction: column;
          /* border-left: 1px solid black;     */
      }

      .footer1 .secondParte .seconmdContent .row1 {
          display: flex;
          flex-direction: row;
          height: 30%;
          width: 100%;
          border-bottom: 1px solid black;
      }

      .footer1 .secondParte .seconmdContent .row2 {
          display: flex;
          flex-direction: column;
          height: 70%;
          width: 100%;
      }

      .footer1 .secondParte .seconmdContent .row1 .apto {
          width: 20%;
          height: 100%;
          border-right: 1px solid black;

      }

      .footer1 .secondParte .seconmdContent .row1 .apto .aptoTitle {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 30%;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer1 .secondParte .seconmdContent .row1 .precinto .precintoTitle {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 30%;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }



      .footer1 .secondParte .seconmdContent .row1 .precinto {
          width: 80%;
      }

      .footer1 .secondParte .seconmdContent .row1 .apto .blankApto,
      .footer1 .secondParte .seconmdContent .row1 .precinto .blankPrecinto {
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-size: 10px;

      }


      .footer1 .secondParte .seconmdContent .row1 .apto,
      .footer1 .secondParte .seconmdContent .row1 .precinto {
          display: flex;
          flex-direction: column;
      }

      .footer1 .secondParte .seconmdContent .row2 .firma {
          display: flex;
          flex-direction: row;
          height: 25%;
          border-bottom: 1px solid black;
      }

      .footer1 .secondParte .seconmdContent .row2 .nombre {
          display: flex;
          flex-direction: row;
          height: 50%;
          border-bottom: 1px solid black;
      }

      .footer1 .secondParte .seconmdContent .row2 .cedula {
          display: flex;
          flex-direction: row;
          height: 25%;
      }

      .footer1 .secondParte .seconmdContent .row2 .firma .firm,
      .footer1 .secondParte .seconmdContent .row2 .nombre .firm,
      .footer1 .secondParte .seconmdContent .row2 .cedula .firm {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          justify-content: center;
          font-weight: bold;
          font-size: 12px;
      }

      .footer1 .secondParte .seconmdContent .row2 .firma .blank,
      .footer1 .secondParte .seconmdContent .row2 .nombre .blank,
      .footer1 .secondParte .seconmdContent .row2 .cedula .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          border-left: 1px solid black;
          justify-content: center;
          text-transform: uppercase;
          align-items: center;
          font-weight: lighter;
          font-size: 10px;
      }

      .footer2 {
          width: 100%;
          height: 130px;
          /* background-color: aqua; */
          border-top:1px solid black;
          border-bottom:1px solid black;
          display: flex;
          flex-direction: row;
      }

      .footer2 .sellos {
          display: flex;
          flex-direction: column;
          width: 44%;
          height: 130px;
      }

      .footer2 .sellos .titleSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer2 .sellos .contentSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 40%;
          align-items: center;
          justify-content: center;
          display: flex;
      }

      .footer2 .sellos .titleOpLogis {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer2 .sellos .contentOtroSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 40%;
          align-items: center;
          justify-content: center;
          display: flex;
      }
      .footer2 .sellos .contentOtroSello2 {
          font-weight: bold;
          text-align: center;
          height: 40%;
          align-items: center;
          justify-content: center;
          display: flex;
      }

      .footer2 .sellos .otrosellos {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;
      }

      .footer2 .sellos .contentOtroSello {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 40%;
          align-items: center;
          justify-content: center;
          display: flex;
      }

      .footer2 .primerParte {
          width: 50%;
          display: flex;
          flex-direction: column;
          /* background-color: rgb(226, 43, 165); */
          border-right: 1px solid black;
      }

      .footer2 .primerParte {
          border-right: 1px solid black;
      }

      .footer2 .terminal {
          display: flex;
          flex-direction: row;
          width: 100%;
      }

      .footer2 .primerParte .terminal .vertical1 {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          text-align: center;
          font-size: 6pt;
          width: 13.2%;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid black;
      }

      .footer2 .firmayNombre {
          width: 63%;
          border-right: 1px solid black;
          height: 130px;
      }

      .footer2 .firmayNombre .firma {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 20%;
          align-items: center;
          justify-content: space-around;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .firma .firm {
          width: 49.5%;
          border-right: 1px solid black;
          height: 100%;
          font-size: 12px;
          font-weight: bold;
          display: flex;
          align-items: center;
      }

      .footer2 .firmayNombre .nombre {
          width: 100%;
          display: flex;
          flex-direction: row;
          height: 30%;
          border-bottom: 1px solid black;

      }

      .footer2 .firmayNombre .firm {
          width: 49.5%;
          border-right: 1px solid black;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-align: center;

      }

      .footer2 .firmayNombre .blank {
          width: 50%;
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;


      }

      .footer2 .firmayNombre .firma .percentI {
          display: flex;
          flex-direction: row;
          width: 78%;
          height: 100%;
          align-items: center;
      }

      .footer2 .firmayNombre .firma .percentI .firm {
          display: flex;
          flex-direction: column;
          width: 49.5%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .firmayNombre .firma .percentI .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .firmayNombre .firma .blankFirma {
          display: flex;
          flex-direction: column;
          width: 54%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .firmayNombre .nombreSelloDer {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 80%;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .nombreSelloDer .selloDer {
          display: flex;
          flex-direction: column;
          width: 29.3%;
          height: 100%;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          justify-content: center;
          align-items: center;


      }

      .footer2 .firmayNombre .nombreSelloDer .selloDer .firm {
          font-size: 11px;
          font-weight: bold;
      }

      .footer2 .firmayNombre .nombreSelloDer .selloDer .firm2 {
          font-size: 11px;
          font-weight: bold;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .nombreSelloDer .selloDer .blank {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          height: 100%;
      }

      .footer2 .firmayNombre .nombreSelloDer .infoIzq {
          display: flex;
          flex-direction: column;
          width: 71%;
          height: 100%;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .nombreSelloDer .infoIzq .blank {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 20%;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .nombreSelloDer .infoIzq .nombre {
          display: flex;
          flex-direction: row;
          height: 80%;

      }

      .footer2 .firmayNombre .nombreSelloDer .infoIzq .nombre .firm {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 40%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .firmayNombre .nombreSelloDer .infoIzq .nombre .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: lighter;
      }

      .footer2 .firmayNombre .cedula {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 10%;
          border-bottom: 1px solid black;
      }

      .footer2 .firmayNombre .cedula .firm {
          display: flex;
          flex-direction: row;
          width: 49.5%;
          border-right: 1px solid black;
          text-align: center;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .secondParte {
          display: flex;
          flex-direction: row;
          width: 50%;
      }

      .footer2 .secondParte .vertical2 {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          text-align: center;
          font-size: small;
          width: 15%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid black;
          border-right: 1px solid black;
      }

      .footer2 .secondParte .seconmdContent {
          width: 100%;
          display: flex;
          flex-direction: column;
          /* border-left: 1px solid black;     */
      }

      .footer2 .secondParte .seconmdContent .row1 {
          display: flex;
          flex-direction: row;
          height: 30%;
          width: 100%;
      }

      .footer2 .secondParte .seconmdContent .row2 {
          display: flex;
          flex-direction: column;
          height: 70%;
          width: 100%;
      }

      .footer2 .secondParte .seconmdContent .row1 .apto {
          width: 20%;
          height: 100%;
          border-right: 1px solid black;

      }

      .footer2 .secondParte .seconmdContent .row1 .apto .aptoTitle {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 30%;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 13px;
          
      }

      .footer2 .secondParte .seconmdContent .row1 .precinto .precintoTitle {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid black;
          height: 30%;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 12px;

      }


      .footer2 .secondParte .seconmdContent .row1 .precinto {
          width: 80%;
      }

      .footer2 .secondParte .seconmdContent .row1 .apto .blankApto,
      .footer2 .secondParte .seconmdContent .row1 .precinto .blankPrecinto {
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-size: 10px;
      }

      .footer2 .secondParte .seconmdContent .row1 .apto,
      .footer2 .secondParte .seconmdContent .row1 .precinto {
          display: flex;
          flex-direction: column;
      }

      .footer2 .secondParte .seconmdContent .row2 .firma {
          display: flex;
          flex-direction: row;
          height: 40%;
          border-bottom: 1px solid black;
          font-size: 12px;
      }

      .footer2 .secondParte .seconmdContent .row2 .nombre {
          display: flex;
          flex-direction: row;
          height: 40%;
          border-bottom: 1px solid black;
          font-size: 12px;
      }

      .footer2 .secondParte .seconmdContent .row2 .cedula {
          display: flex;
          flex-direction: row;
          height: 20%;
          border-bottom: 1px solid black;
          font-size: 12px;
      }

      .footer2 .secondParte .seconmdContent .row2 .firma .firm,
      .footer2 .secondParte .seconmdContent .row2 .nombre .firm,
      .footer2 .secondParte .seconmdContent .row2 .cedula .firm {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 23, 3%;
          border-right: 1px solid black;
          justify-content: center;
          font-weight: bold;
      }

      .footer2 .secondParte .seconmdContent .row2 .firma .blank,
      .footer2 .secondParte .seconmdContent .row2 .nombre .blank,
      .footer2 .secondParte .seconmdContent .row2 .cedula .blank {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 80%;
          justify-content: center;
          font-size: 10px;
          justify-content: center;
          align-items: center;
      }

      .footer2 .firmayNombre .placas {
          display: flex;
          flex-direction: row;
      }

      .footer2 .firmayNombre .placas .placaVehiculo {
          width: 50%;
          border-right: 1px solid black;
          height: 48px;
      }

      .footer2 .firmayNombre .placas .placaPlancha {
          width: 51%;
          height: 54px;
      }

      .footer2 .firmayNombre .placas .placaVehiculo .title {
          width: 100%;
          border-bottom: 1px solid black;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
      }

      .footer2 .firmayNombre .placas .placaVehiculo .blank {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          width: 100%;
          height: auto;
      }

      .footer2 .firmayNombre .placas .placaPlancha .title {
          width: 100%;
          border-bottom: 1px solid black;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
      }

      .footer2 .firmayNombre .placas .placaPlancha .blank {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          width: 100%;
          height: auto;
      }

      .losiguienteCierre {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
      }

      .losiguienteCierre .placas {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 60px;
      }

      .losiguienteCierre .placas .placaTruck {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          align-items: center;

      }

      .losiguienteCierre .placas .placaTruck .placaVehiculo {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40%;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .losiguienteCierre .placas .placaTruck .blankPlaca {
          display: flex;
          justify-content: center;
          align-items: center;
      }

      .losiguienteCierre .placas .placaPlancha {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          border-bottom: 1px solid black;

      }

      .losiguienteCierre .placas .placaPlancha .blankPlaca {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;


      }

      .losiguienteCierre .placas .placaPlancha .placaPla {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40%;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .losiguienteCierre .salidas {
          display: flex;
          flex-direction: row;
          width: 100%;
      }

      .salidas {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: auto;
      }

      .salidas .salida {
          display: flex;
          flex-direction: column;
          width: 48.5%;
          height: 300px;
          border-right: 1px solid black;
      }

      .salidas .salida .salidaTitle {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .salidas .salida .obv {
          display: flex;
          flex-direction: row;

      }

      .salidas .salida .obv .obv1 {
          display: flex;
          flex-direction: column;
          width: 50%;
          border-right: 1px solid black;
      }

      .salidas .salida .obv .obv1 .observacionesTitle {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 40px;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 12px;
      }

      .salidas .salida .obv .blankNote {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 277px;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .entrada {
          width: 50%;
      }

      .entrada .entradaTitle {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid black;
          justify-content: center;
          align-items: center;
          font-weight: bold;
      }

      .salidasEntradas {
          display: flex;
          flex-direction: row;
          border-bottom:1px solid black;
          justify-content: space-around;
          width: 100%;
      }

      .salidasEntradas .salidas {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 50%;
          border-right: 1px solid black;

      }

      .salidasEntradas .entradas {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 50%;

      }

      .observaciones {
          display: flex;
          width: 100.3%;
          height: 175px;
          flex-direction: row;
      }

      .observaciones .observacionesTitle {
          display: flex;
          border-right: 1px solid black;
          width: 15%;
          justify-content: center;
          align-items: center;

      }

      .observaciones .observacionesSalidas {
          display: flex;
          width: 104%;
          border-right: 1px solid;
          height: 175px;
      }

      @page {
          size: A4;
          /* Para impresión sin márgenes */
      }

      @media print {
          body {
              margin-top: 5mm;
              margin-right: 5mm; /* Corrige el error tipográfico */
              margin-left: 5mm;
              margin-bottom: 0mm; /* Agrega margen inferior si lo deseas */
          }
      }

    `

    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>${printTitle}</title>
            <style>${customStyles}</style>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);

      // const styleSheets = document.styleSheets;
      // const links = document.getElementsByTagName("link");

      // for (let i = 0; i < links.length; i++) {
      //   if (links[i].rel === "stylesheet") {
      //     newWindow.document.head.appendChild(links[i].cloneNode(true));
      //   }
      // }

      // for (let i = 0; i < styleSheets.length; i++) {
      //   const styleSheet = styleSheets[i];
      //   try {
      //     const xhr = new XMLHttpRequest();
      //     xhr.open('GET', styleSheet.href, false);
      //     xhr.send();
      //     const cssText = xhr.responseText;
      //     const style = document.createElement('style');
      //     style.type = 'text/css';
      //     style.appendChild(document.createTextNode(cssText));
      //     newWindow.document.head.appendChild(style);
      //   } catch (e) {
      //     // Ignorar las hojas de estilo que no se pueden acceder debido a restricciones de seguridad
      //   }
      // }

      newWindow.document.close();

      newWindow.onload = () => {
        newWindow.print();
        newWindow.close();
      };
    } else {
      console.error('No se pudo abrir la ventana de impresión.');
    }
  };

  useEffect(() => {
    fillAllData();
  }, []);

  // useEffect(() => {
  //   if (allData.length > 0) {
  //     onRenderComplete();
  //   }
  // }, [allData, onRenderComplete]);

  useEffect(() => {
    console.log("NroContenedor: ", nroContenedor);
  }, [nroContenedor]);



  return (
    allData.length > 0 ? (
      <>
        <div className='buttonDiv'>
          <button type="button" className="btn btn-outline-secondary float-end" onClick={handlePrint}><i className="bx bx-printer me-1"></i> Imprimir</button>
        </div>
        <div class="container" id='print_container_checkList'>
          <div class="header">
            <div class="headerTitle">
              FORMATO INSPECCIÓN DE SEGURIDAD PARA CONTENEDORES
            </div>
            <div class="headerNumerocontenedor">
              <div class="logo"><img src='https://infocarga.pro/images/logo_yara.png' height="100%" />
              </div>
              <div class="nrooContainer">
                <div class="titlenroContainer">NUMERO CONTENEDOR</div>
                <div className="nroDescriptionContainer">
                  {nroContenedor && nroContenedor.split('').map((char, index, array) => (
                    <div key={index} className={index === array.length - 1 ? 'div2' : 'div1'}>
                      {char}
                    </div>
                  ))}
                </div>
              </div>
              <div class="patioPlanta">
                <div class="patio">
                  <div class="titlePatio">
                    PATIO
                  </div>
                  <div class="Fecha">
                    <div>DIA</div>
                    <div>MES</div>
                    <div style={{ borderRight: 'none' }}>AÑO</div>
                  </div>
                  <div class="FechaResult">
                    <div>{diaPatio}</div>
                    <div>{mesPatio}</div>
                    <div style={{ borderRight: 'none' }}>{anioPatio}</div>
                  </div>
                </div>
                <div class="planta">
                  <div class="titlePlanta">
                    PLANTA
                  </div>
                  <div class="Fecha">
                    <div>DIA</div>
                    <div>MES</div>
                    <div style={{ borderRight: 'none' }}>AÑO</div>
                  </div>
                  <div class="FechaResult">
                    <div>{diaPlanta}</div>
                    <div>{mesPlanta}</div>
                    <div style={{ borderRight: 'none' }}>{anioPlanta}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="headerTipoContenedor">
              <div class="nroIdChequeo">Nro.{allData[0]?.id_chequeo}</div>
              <div class="descripcionPatiosNaviera">
                <div class="patioOrigen">
                  <div class="titlePatio">PATIO ORIGEN</div>
                  <div class="contentPatio">{allData[0]?.patio}</div>
                </div>
                <div class="Naviera">
                  <div class="titleNaviera">NAVIERA</div>
                  <div class="contentNaviera">{allData[0]?.naviera}</div>
                </div>
              </div>
              <div class="horaFecha">
                <div class="horaContent1">
                  <div class="hora">
                    <div class="horaTitle">HORA</div>
                    <div class="horaDesc" >{horaPatio}</div>
                  </div>
                  <div class="tipoRegistro">
                    REGISTRO EN PATIO
                  </div>
                </div>
                <div class="horaContent2">
                  <div class="hora">
                    <div class="horaTitle">HORA</div>
                    <div class="horaDesc" style={{ borderRight: 'none' }}>{horaPlanta}</div>
                  </div>
                  <div class="tipoRegistro">
                    REGISTRO EN PLANTA
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="answers">
            <div class="respuestasOpciones">
              <div class="spceBlank"></div>
              <div class="opciones">
                <div class="op1">
                  <div style={{ borderLeft: '1px solid black' }}>SI</div>
                  <div>NO</div>
                  <div>NA</div>
                </div>
                <div class="op2">
                  <div>SI</div>
                  <div>NO</div>
                  <div>NA</div>
                </div>
              </div>
            </div>

            <div class="interiorContenenedorTitle" >
              <p class="title">1. INTERIOR DEL CONTENEDOR</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  1.1 Presenta sucio de barro, lodo, tierra, otros elementos(Caso registre SI, sedebe
                  notificar a Operaciones Producto Terminado para confirmar
                  devolucion o recibir OK de ingreso)
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(0)}
                {renderRespuestas2(0)}
              </div>

            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  1.2 Presenta filtracion de luz
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(1)}
                {renderRespuestas2(1)}
              </div>
            </div>
            <div class="interiorContenenedorTitle">
              <p class="title">2. PUERTAS</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.1 Número del contenedor es diferente en uno o más de los 6 lados
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(2)}
                {renderRespuestas2(2)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.2 Adhesivo o pegante nuevo en uniones de las láminas
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(3)}
                {renderRespuestas2(3)}
              </div>
            </div>

            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.3 Marcas o quemaduras recientes de soldadura
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(4)}
                {renderRespuestas2(4)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.4 Pintura nueva en partes o parches
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(5)}
                {renderRespuestas2(5)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.5 Ondulaciones internas y externas desiguales en tamaño y altura
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(6)}
                {renderRespuestas2(6)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.6 Vigas y travesaños con sonido metálico disparejo
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(7)}
                {renderRespuestas2(7)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.7 Lámina inferior de protección de entrada diferente a 50 cm largo
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(8)}
                {renderRespuestas2(8)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.8 Canal superior e inferior interno sin tapas
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(9)}
                {renderRespuestas2(9)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.9 Areas aledañas a remaches o bisagras con muestra de golpes, pintura nueva o forcejeo
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(10)}
                {renderRespuestas2(10)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.10 Olores a pinturas, soldadura, madera quemada, pegante, materiales de relleno,
                  grasa, etc
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(11)}
                {renderRespuestas2(11)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  2.11 La pestaña o tope central puertas con marcas de dobles, golpes o pintura nueva
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(12)}
                {renderRespuestas2(12)}
              </div>
            </div>
            <div class="interiorContenenedorTitle">
              <p class="title">3. PISO</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.1 Está nivelado
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(13)}
                {renderRespuestas2(13)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.2 Por encima del nivel de las vigas inferiores
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(14)}
                {renderRespuestas2(14)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.3 Reparaciones Nuevas
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(15)}
                {renderRespuestas2(15)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.4 Reparaciones nuevas no acordes a estándares
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(16)}
                {renderRespuestas2(16)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.5 Piso exterior con vigas diferentes a formas de I, L, T
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(17)}
                {renderRespuestas2(17)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  3.6 Piso exterior: Chapas, esquineras, barandillas laterales, vigas cruzadas, túnel,
                  muestran cambios o diferencias
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(18)}
                {renderRespuestas2(18)}
              </div>
            </div>
            <div class="interiorContenenedorTitle">
              <p class="title">4. TECHO</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.1 No se observan los soportes (vigas superiores) del contenedor
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(19)}
                {renderRespuestas2(19)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.2 Orificios de ventilación no están entre 50 A 60 cm de la viga esquinera y 5 cm del
                  travesaño lateral superior
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(20)}
                {renderRespuestas2(20)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.3 Techo desnivelado
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(21)}
                {renderRespuestas2(21)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.4 Marcas o quemaduras recientes de soldadura en el techo
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(22)}
                {renderRespuestas2(22)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.5 Pintura nueva en partes o parches en el techo
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(23)}
                {renderRespuestas2(23)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  4.6 Al medir la altura entre piso y techo, y comparar con el estándar no corresponde
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(24)}
                {renderRespuestas2(24)}
              </div>
            </div>
            <div class="interiorContenenedorTitle">
              <p class="title">5. COSTADOS</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  5.1 Adhesivo o pegante nuevo en uniones de láminas
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(25)}
                {renderRespuestas2(25)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  5.2 Marcas o quemaduras recientes de soldadura
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(26)}
                {renderRespuestas2(26)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  5.3 Pintura nueva en partes o parches
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(27)}
                {renderRespuestas2(27)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  5.4 Sonido metálico en ondulaciones y láminas
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(28)}
                {renderRespuestas2(28)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  5.5 Al medir la longitud y comparar con el estándar no corresponde
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(29)}
                {renderRespuestas2(29)}
              </div>
            </div>
            <div class="interiorContenenedorTitle">
              <p class="title">6. TORNILLOS</p>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  6.1 Deben ser con cabeza redonda (Si son hexagonales: Deben tener punto de soldadura en
                  su parte interna)
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(30)}
                {renderRespuestas2(30)}
              </div>
            </div>
            <div class="interiorContent">
              <div class="contents">
                <div class="content1">
                  6.2 No se debe denotar que los tornillos son más nuevos del contenedor y sin
                  sobresalientes
                </div>
              </div>
              <div class="opciones">
                {renderRespuestas1(31)}
                {renderRespuestas2(31)}
              </div>
            </div>



          </div>
          <div class="footer">
            <div class="footer1">
              <div class="primerParte">
                <div class="footerContentSellos">
                  <div class="sellosCarat">
                    Al firmar usted certifica que he realizado la verificacion de cada registro, sea en
                    caso de ingreso o salida del contenedor, que la informacion aquí registrada es
                    correcta y de
                    haberse encontrado una no conformidad ha sido especificada en la casilla de
                    observaciones.
                  </div>
                  <div class="selloBtllaH">
                    <div class="titleSello">SELLO BOTELLA HEMBRA</div>
                    <div class="descrSello">{allData[0]?.sellobotella}</div>
                  </div>
                </div>
                <div class="terminal">
                  <div class="vertical1">
                    TERMINAL SALE A CONDUCTOR
                  </div>
                  <div class="firmayNombre">
                    <div class="firma">
                      <div class="firm">FIRMA</div>
                      <div class="blank"></div>
                    </div>
                    <div class="nombre">
                      <div class="firm">
                        PRIMER NOMBRE Y APELLIDO
                      </div>
                      <div class="blank">
                        {allData[0]?.nombre_conductor1?.toUpperCase()}
                      </div>
                    </div>
                    <div class="cedula">
                      <div class="firm">CEDULA</div>
                      <div class="blank">
                        {allData[0]?.doc_conductor1}
                      </div>
                    </div>
                  </div>
                  <div class="sellos">
                    <div class="titleSello">
                      SELLO BOTELLA MACHO
                    </div>
                    <div class="contentSello"></div>
                    <div class="titleOpLogis">
                      SELLO ETIQUETA OP. LOGIS.
                    </div>
                    <div class="contentOpLogis"></div>
                  </div>
                </div>
              </div>
              <div class="secondParte">
                <div class="vertical2">EMBARCADOR</div>
                <div class="seconmdContent">
                  <div class="row1">
                    <div class="apto">
                      <div class="aptoTitle">
                        APTO
                      </div>
                      <div class="blankApto">

                      </div>
                    </div>
                    <div class="precinto">
                      <div class="precintoTitle">
                        PRECINTO SALIDA A PLANTA
                      </div>
                      <div class="blankPrecinto">
                        {allData[0]?.precinto1?.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <div class="row2">
                    <div class="firma">
                      <div class="firm">FIRMA</div>
                      <div class="blank"></div>
                    </div>
                    <div class="nombre">
                      <div class="firm">
                        PRIMER NOMBRE Y APELLIDO
                      </div>
                      <div class="blank">
                        {allData[0]?.embarcador?.toUpperCase()}
                      </div>
                    </div>
                    <div class="cedula">
                      <div class="firm">CEDULA</div>
                      <div class="blank"></div>
                    </div>
                  </div>
                  <div class="3row"></div>
                  <div class="4row"></div>
                </div>
              </div>
            </div>
            <div class="footer2">
              <div class="primerParte">

                <div class="terminal">
                  <div class="vertical1">
                    VIGILANTE EN PUERTA
                  </div>
                  <div class="firmayNombre">
                    <div class="firma">
                      <div class="firm">FIRMA</div>
                      <div class="blank"></div>
                    </div>
                    <div class="nombre">
                      <div class="firm">
                        PRIMER NOMBRE Y APELLIDO
                      </div>
                      <div class="blank">
                        {allData[0]?.nombre_conductor1?.toUpperCase()}
                      </div>
                    </div>
                    <div class="cedula">
                      <div class="firm">CEDULA</div>
                      <div class="blank">
                        {allData[0]?.doc_conductor1}
                      </div>
                    </div>
                    <div class="placas">
                      <div class="placaVehiculo">
                        <div class="title">PLACA VEHICULO</div>
                        <div class="blank">
                          {allData[0]?.placa1?.toUpperCase()}
                        </div>
                      </div>
                      <div class="placaPlancha">
                        <div class="title">PLACA REMOLQUE</div>
                        <div class="blank">
                          {allData[0]?.placa2?.toUpperCase()}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="sellos">
                    <div class="titleSello">
                      SELLO ETIQUETA DERECHA
                    </div>
                    <div class="contentSello">{allData[0]?.etiqueta1?.toUpperCase()}</div>
                    <div class="titleOpLogis">
                      SELLO ETIQUETA IZQUIERDA
                    </div>
                    <div class="contentOtroSello">{allData[0]?.etiqueta2?.toUpperCase()}</div>
                    <div class="otrosellos">
                      OTROS SELLOS
                    </div>
                    <div class="contentOtroSello2"></div>
                  </div>
                </div>
              </div>
              <div class="secondParte">
                <div class="vertical2">VIGILANTE EN PUERTA</div>
                <div class="seconmdContent">

                  <div class="row2">
                    <div class="firma">
                      <div class="firm">FIRMA</div>
                      <div class="blank"></div>
                    </div>
                    <div class="nombre">
                      <div class="firm">
                        PRIMER NOMBRE Y APELLIDO
                      </div>
                      <div class="blank">
                        {allData[0]?.embarcador?.toUpperCase()}
                      </div>
                    </div>
                    <div class="cedula">
                      <div class="firm">CEDULA</div>
                      <div class="blank"></div>
                    </div>
                  </div>
                  <div class="row1">
                    <div class="apto">
                      <div class="aptoTitle">
                        APTO
                      </div>
                      <div class="blankApto">

                      </div>
                    </div>
                    <div class="precinto">
                      <div class="precintoTitle">
                        PRECINTO ENTRADA A PATIO PLANTA
                      </div>
                      <div class="blankPrecinto">
                        {allData[0]?.precinto2?.toUpperCase()}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="salidasEntradas">
              <div class="salidas">
                <div class="salidasTitle">
                  SALIDAS
                </div>
              </div>
              <div class="entradas">
                <div class="entradasTitle">
                  ENTRADAS
                </div>
              </div>
            </div>
            <div class="observaciones">
              <div class="observacionesTitle">OBSERVACIONES</div>
              <div class="observacionesSalidas">{allData[0]?.observaciones1?.toUpperCase()}</div>
              <div class="observacioneEntradas">{allData[0]?.observaciones2?.toUpperCase()}</div>
            </div>




          </div>
        </div>
      </>
    ) : (
      <div className="loading">No hay ningún formato disponible para este contrato</div>
    )
  );
};

export default PrintComponent;
