import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import TokenServiceRefresh from "./tokenServiceRefresh";

const AuthenticatedApp = ({ children }) => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(true); // Estado inicial: activo
    const [lastActionTime, setLastActionTime] = useState(new Date()); // Hora de la última acción

    const intervalForTests = 1; // 1 minuto para pruebas (en minutos)
    const intervalForProduction = 55; // 55 minutos en producción (en minutos)
    //TODO: Pruebas  y producción separadas.
    const intervalInMinutes = process.env.NODE_ENV === "production" ? intervalForProduction : intervalForTests;

    // Convertir minutos a milisegundos para setInterval
    const interval = intervalForProduction * 60 * 1000;

    // Función para manejar la actividad del usuario
    const handleUserActivity = useCallback(() => {
        setIsActive(true); // Restablecer la bandera de inactividad
        setLastActionTime(new Date()); // Actualizar la hora de la última acción
    }, []);

    useEffect(() => {
        // Registrar detectores de eventos para la actividad del usuario
        window.addEventListener("mousedown", handleUserActivity);
        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("keypress", handleUserActivity);
        window.addEventListener("scroll", handleUserActivity);
        window.addEventListener("touchstart", handleUserActivity);

        // Establecer el intervalo de verificación de actividad
        const activityCheckInterval = setInterval(() => {
            const currentTime = new Date();
            const elapsedTimeSinceAction = (currentTime - lastActionTime) / (1000 * 60); // Convertir milisegundos a minutos

            setIsActive(elapsedTimeSinceAction < intervalForProduction); // Cambiar el estado isActive dependiendo del tiempo de inactividad
        }, interval);

        // Al desmontar el componente, limpiar el intervalo
        return () => clearInterval(activityCheckInterval);
    }, [intervalForProduction, lastActionTime, handleUserActivity]);

    useEffect(() => {
        // Verificar si se necesita refrescar el token cuando el usuario está activo y ha pasado el intervalo
        const timeoutId = setTimeout(() => {
            TokenServiceRefresh.refreshTokenIfNeeded(isActive);
        }, interval); // 1 minuto en milisegundos

        return () => clearTimeout(timeoutId); // Limpiar el temporizador al desmontar el componente
    }, [isActive]);

    useEffect(() => {
        // Si el usuario está inactivo, y esta en otra ruta distinta a la incial de login detener la actualización y cerrar la sesión
        if (!isActive && location.pathname !== "/") {
            localStorage.clear(); // Borrar datos de localStorage
            console.log("Cerrando sesión debido a la inactividad.");
            window.location.href = "/"; // Redirigir al inicio de sesión
        }
    }, [isActive]);

    useEffect(() => {
        // Verificar si la ubicación actual es la ruta principal ("/")
        if (location.pathname !== "/") {
            const intervalId = setInterval(() => {
                // Calcular el tiempo restante hasta la próxima actualización del token
                const expirationTime = TokenServiceRefresh.expirationTime;
                const currentTime = new Date().getTime();
                const remainingTimeInSeconds = Math.max(0, (expirationTime - currentTime) / 1000);

                // Mostrar el tiempo restante en la consola
                // console.log("Tiempo restante para actualizar el token:", remainingTimeInSeconds, "segundos");

                // Verificar si se necesita refrescar el token cuando el tiempo restante llega a cero o es menor que cero
                if (remainingTimeInSeconds <= 0) {
                    console.log("llamando la validacion del refreshToken si es necesario y si hay actividad");
                    TokenServiceRefresh.refreshTokenIfNeeded();
                }
            }, 1000); // Llama a la función cada segundo

            // Limpiar el temporizador al desmontar el componente para evitar fugas de memoria
            return () => clearInterval(intervalId);
        }
    }, [location.pathname]);



    return <>{children}</>;
};

export default AuthenticatedApp;
