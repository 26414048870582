import React, { useEffect, useRef, useState } from 'react'
import '../../Pages/Css/solicitudes.css';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import TooltipComponent from '../../componentes/radix/Tooltip';
import SwitchButton from '../../componentes/SwitchButton';
import Swal from 'sweetalert2';
import { Spinner } from 'reactstrap';
import axios from '../../utils/axiosConfig';

const FormUpdateContrato = ({ onClose, contrato, info }) => {

  const refs = {
    fechaHoraCita: useRef(null),
    fechaHoraDescargue: useRef(null),
    horaTransitoStart: useRef(null),
    horaTransitoEnd: useRef(null),
  };

  const [dates, setDates] = useState({
    fechaHoraCita: info[0].Hora,
    fechaHoraDescargue: info[0].HoraCita,
  });

  const [times, setTimes] = useState({
    horaTransitoStart: info[0].HoraInicioR,
    horaTransitoEnd: info[0].HoraFinR,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [clientesConsulta, setClientesConsulta] = useState([]);
  const [clientesConsulta1, setClientesConsulta1] = useState([]);
  const [clientesConsulta2, setClientesConsulta2] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [tipoContratos, setTipoContratos] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [transpotadoras, setTranspotadoras] = useState([]);
  const [destinosAutorizados, setDestinosAutorizados] = useState([]);
  const [unidadCargas, setUnidadCarga] = useState([]);
  const [instaladores, setInstaladores] = useState([]);
  const [categoriasServicio, setCategoriasServicio] = useState([]);
  const [infoContrato, setInfoContrato] = useState([]);
  const [barras, setBarras] = useState([]);
  const [modalidadServ, setModalidadServ] = useState([]);
  const [ocultoServ, setOcultoServ] = useState([]);
  const [documento, setDocumento] = useState(info[0].Documento);
  const [notasDes, setNotasDes] = useState(info[0].NotaDesisntalaciones);
  const [selectedCliente, setSelectedCliente] = useState(info[0].FKICEmpresa);
  const [selectedClienteConsulta, setSelectedClienteConsulta] = useState(info[0].FKICEmpresaConsulta);
  const [selectedClienteConsulta1, setSelectedClienteConsulta1] = useState(info[0].FKICEmpresaConsulta2);
  const [selectedClienteConsulta2, setSelectedClienteConsulta2] = useState(info[0].FKICEmpresaConsulta3);
  const [selectedRuta, setSelectedRuta] = useState(info[0].FKICRutas);
  const [selectedTipoContrato, setSelectedTipoContrato] = useState(0);
  const [selectedServicio, setSelectedServicio] = useState(null);
  const [selectedTransportador, setSelectedTransportador] = useState(info[0].FKICTransportadora);
  const [selectedDestinoAutorizado, setSelectedDestinoAutorizado] = useState(info[0].FKCercaAutorizada);
  const [selectedTipoUnidadCarga, setSelectedTipoUnidadCarga] = useState(info[0].FKLokTipoUnidadCarga);
  const [selectedInstalador, setSelectedInstalador] = useState(info[0].FKLokInstalador);
  const [selectedDesInstalador, setSelectedDesinstalador] = useState(info[0].FKLokDesistaladores);
  const [selectedModServicio, setSelectedModServicio] = useState(info[0].FKLokModalidadServ);
  const [selectedBarras, setSelectedBarras] = useState(info[0].FKLokBarsSLM);
  const [selectedOculto, setSelectedOculto] = useState(info[0].FKCelloTrack);
  const [selectedCatServicio, setSelectedCatServicio] = useState(info[0].FKLokCategoriaServ);
  const [nroSolicitud, setNroSolicitud] = useState(info[0].FKLokSolicitud);
  const [placaTruck, setPlacaTruck] = useState(info[0].PlacaTruck);
  const [marcaTruck, setMarcaTruck] = useState(info[0].OtrosDatosTruck);
  const [colorTruck, setColorTruck] = useState(info[0].ColorTruck);
  const [equivalenciaQQ, setEquivalenciaQQ] = useState(info[0].Equivalencia);
  const [referencia, setReferencia] = useState(info[0].Ref);
  const [placaRemolque, setPlacaRemolque] = useState(info[0].PlacaTrailer);
  const [nombreConductor, setNombreConductor] = useState(info[0].NombreConductor);
  const [nombreAcompanante, setNombreAcompanante] = useState(info[0].NombreEscolta);
  const [diConductor, setDiConductor] = useState(info[0].NitConductor);
  const [diAcompanante, setDiAcompanante] = useState(null);
  const [cellphoneAcompanante, setCellphoneAcompanante] = useState(info[0].MovilEscolta);
  const [cellphoneConductor, setCellphoneConductor] = useState(info[0].MovilConductor);
  const [nroContenedor, setNroContenedor] = useState(info[0].ContainerNum);
  const [digitoVerificacion, setDigitoVerificacion] = useState(info[0].DigitoVerificacion);
  const [contacto, setContacto] = useState(info[0].Contacto);
  const [fklokEstados, setFKLokEstados] = useState(info[0].FKLokEstados);
  const [fHoraCita, setFHoraCita] = useState(null);
  const [fHoraCitaFormated, setFHoraCitaFormated] = useState(null);
  const [fHoraDescargue, setFHoraDescargue] = useState(null);
  const [fHoraDescargueFormated, setFHoraDescargueFormated] = useState(null);
  const [horaTransitoStart, setHoraTransitoStart] = useState(info[0].HoraInicioR);
  const [horaTransitoEnd, setHoraTransitoEnd] = useState(info[0].HoraFinR);
  const [restriccion, setRestriccion] = useState(info[0].bitRestriccion);
  const [criticoShow, setShowCritico] = useState(info[0].bitMostrarCritico);
  const [contratoShow, setcontratoShow] = useState(info[0].Active);
  const [altShow, setAltShow] = useState(info[0].AlertasActivas);
  const [solicitante, setSolicitante] = useState(info[0].Solicitante);
  const [datosEntrega, setDatosEntrega] = useState(info[0].NotasDatosEntrega);
  const [causalCat, setCausalCat] = useState(info[0].NotasTI);
  const [notas, setNotas] = useState(info[0].Notas);
  const [valueCliente, setValueCliente] = useState('');
  const [valueRuta, setValueRuta] = useState('');
  const [valueTipoUCarga, setValueTipoUCarga] = useState('');
  const [valueTansportador, setValueTransportador] = useState('');
  const [valueInstalador, setValueInstalador] = useState('');
  const [valueDesInstalador, setValueDesInstalador] = useState('');
  const [valueModServ, setValueModServ] = useState('');
  const [valueBarras, setValueBarras] = useState('');
  const [valueOcultoServ, setValueOcultoServ] = useState('');
  const [valueCatServ, setValueCatServ] = useState('');
  const [valueClienteConsulta, setValueClienteConsulta] = useState('');
  const [valueClienteConsulta2, setValueClienteConsulta2] = useState('');
  const [valueClienteConsulta3, setValueClienteConsulta3] = useState('');
  const [valueDestAutorizado, setValueDestAutorizado] = useState('');
  const [valueTipoFactura, setValueTipoFactura] = useState('');
  const userSolicitud = localStorage.getItem('username');

  // Function to format ISO date string to datetime-local format
  function formatToDateTimeLocal(isoString) {
    // Create a Date object from the ISO string
    const dateObject = new Date(isoString);

    // Extract components of the date and time
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');

    // Format the date and time as required by datetime-local input (YYYY-MM-DDTHH:MM)
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function formatToDateTimeWithoutT(isoString) {
    // Crear un objeto Date a partir de la cadena ISO
    const dateObject = new Date(isoString);

    // Extraer los componentes de la fecha y la hora
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Los meses son base 0, por lo tanto sumamos 1
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    const seconds = String(dateObject.getSeconds()).padStart(2, '0'); // Incluimos los segundos

    // Formatear la fecha y hora con un espacio en lugar de 'T' (YYYY-MM-DD HH:MM:SS)
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // Función para extraer solo la hora y los minutos
  function extractTime(isoString) {
    const timePart = isoString.split('T')[1]; // Obtener la parte de la hora después de la 'T'
    return timePart.slice(0, 5); // Obtener solo los primeros 5 caracteres 'HH:MM'
  }


  // Function to handle checkbox changes
  const handleCheckboxChange = (newValue) => {
    setRestriccion(newValue);
    console.log(newValue);
  };

  const handleCheckboxChangeCritico = (newValue) => {
    setShowCritico(newValue);
    console.log(newValue);
  };

  const handleCheckboxChangeContrato = (newValue) => {
    setcontratoShow(newValue);
    console.log(newValue);
  };

  const handleCheckboxChangeAlt = (newValue) => {
    setAltShow(newValue);
    console.log(newValue);
  };

  const handleTimeChange1 = (date) => {
    if (date && date.length > 0) {
      const selectedDate = date[0]; // Obtenemos la fecha seleccionada (objeto Date)

      // Extraemos la hora y los minutos
      const hours = selectedDate.getHours().toString().padStart(2, '0');
      const minutes = selectedDate.getMinutes().toString().padStart(2, '0');

      const formattedTime = `${hours}:${minutes}`;

      console.log("Esta es la hora seleccionada:", formattedTime);



      setHoraTransitoStart(formattedTime); // Guardamos solo la hora formateada
    }
  };

  const handleTimeChange2 = (date) => {
    if (date && date.length > 0) {
      const selectedDate = date[0]; // Obtenemos la fecha seleccionada (objeto Date)

      // Extraemos la hora y los minutos
      const hours = selectedDate.getHours().toString().padStart(2, '0');
      const minutes = selectedDate.getMinutes().toString().padStart(2, '0');

      const formattedTime = `${hours}:${minutes}`;

      console.log("Esta es la hora seleccionada2:", formattedTime);

      setHoraTransitoEnd(formattedTime); // Guardamos solo la hora formateada
    }
  };



  const ListarClientes = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaempresas", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setClientes(res.data.data);
        setClientesConsulta(res.data.data);
        setClientesConsulta1(res.data.data);
        setClientesConsulta2(res.data.data);

        const buscarNombreCliente = async (id) => {
          if (id === null) {
            setValueCliente('-No asignado-');
          } else {
            const clienteValue = res.data.data.find((cliente) => cliente.IdEmpresa === id);
            if (clienteValue && clienteValue.NombreEmpresa) {
              setValueCliente(clienteValue.NombreEmpresa);
              setSelectedCliente(id);
            } else {
              setValueCliente('-No asignado-');
              setSelectedCliente(null);
            }
          }
        };

        const buscarClienteConsulta = async (id) => {
          if (id === null) {
            setValueClienteConsulta('-No asignado-');
          } else {
            const consultaValue = res.data.data.find((consta) => consta.IdEmpresa === id);
            if (consultaValue && consultaValue.NombreEmpresa) {
              setValueClienteConsulta(consultaValue.NombreEmpresa);
              setSelectedClienteConsulta(id);
            } else {
              setValueClienteConsulta('-No asignado-');
              setSelectedClienteConsulta(null);
            }
          }
        };

        const buscarClienteConsulta2 = async (id) => {
          if (id === null) {
            setValueClienteConsulta2('-No asignado-');
          } else {
            const consulta2Value = res.data.data.find((consta1) => consta1.IdEmpresa === id);
            if (consulta2Value && consulta2Value.NombreEmpresa) {
              setValueClienteConsulta2(consulta2Value.NombreEmpresa);
              setSelectedClienteConsulta1(id);
            } else {
              setValueClienteConsulta2('-No asignado-');
              setSelectedClienteConsulta1(null);
            }
          }
        };

        const buscarClienteConsulta3 = async (id) => {
          if (id === null) {
            setValueClienteConsulta3('-No asignado-');
          } else {
            const consulta3Value = res.data.data.find((consta2) => consta2.IdEmpresa === id);
            if (consulta3Value && consulta3Value.NombreEmpresa) {
              setValueClienteConsulta3(consulta3Value.NombreEmpresa);
              setSelectedClienteConsulta2(id);
            } else {
              setValueClienteConsulta3('-No asignado-');
              setSelectedClienteConsulta2(null);
            }
          }
        };
        buscarNombreCliente(info[0].FKICEmpresa);
        buscarClienteConsulta(info[0].FKICEmpresaConsulta);
        buscarClienteConsulta2(info[0].FKICEmpresaConsulta2);
        buscarClienteConsulta3(info[0].FKICEmpresaConsulta3);


      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarUnidadesCarga = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaUnidadCarga", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setUnidadCarga(res.data.data);
        const buscarTipoUnidadCarga = async (id) => {
          if (id === null) {
            setValueRuta('-No asignado-');
          } else {
            const uCarga = res.data.data.find((ruta) => ruta.TipoUnidadCargaID === id);
            if (uCarga && uCarga.DescripcionTipoCarga) {
              setValueTipoUCarga(uCarga.DescripcionTipoCarga);
              setSelectedTipoUnidadCarga(id);
            } else {
              setValueTipoUCarga('-No asignado-');
              setSelectedTipoUnidadCarga(null);
            }
          }
        };
        buscarTipoUnidadCarga(info[0].FKLokTipoUnidadCarga);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarTransportadoras = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getlistatransportadoras", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setTranspotadoras(res.data.data);

        const buscarTransportador = async (id) => {
          if (id === null) {
            setValueTransportador('-No asignado-');
          } else {
            const transValue = res.data.data.find((trans) => trans.IdTransportadora === id);
            if (transValue && transValue.NombreTranspo) {
              setValueTransportador(transValue.NombreTranspo);
              setSelectedTransportador(id);
            } else {
              setValueTransportador('-No asignado-');
              setSelectedTransportador(null);
            }
          }
        };
        buscarTransportador(info[0].FKICTransportadora);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarInstaladores = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaInstaladores", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setInstaladores(res.data.data);
        const buscarInstalador = async (id) => {
          console.log("este es el nombre del inbstalador: ", id);
          if (id === null) {
            setValueClienteConsulta3('-No asignado-');
          } else {
            const InstaladorValue = res.data.data.find((insta) => insta.CCInstalador === id);
            if (InstaladorValue && InstaladorValue.NombreInstalador) {
              setValueInstalador(InstaladorValue.NombreInstalador);
              setSelectedInstalador(id);
            } else {
              setValueInstalador('-No asignado-');
              setSelectedInstalador(null);
            }
          }
        };
        const buscarDesInstalador = async (id) => {
          console.log("este es el nombre del desinbstalador: ", id);
          if (id === null) {
            setValueDesInstalador('-No asignado-');
          } else {
            const desIntaladorValue = res.data.data.find((insta) => insta.CCInstalador === id);
            if (desIntaladorValue && desIntaladorValue.NombreInstalador) {
              setValueDesInstalador(desIntaladorValue.NombreInstalador);
              setSelectedDesinstalador(id);
            } else {
              setValueDesInstalador('-No asignado-');
              setSelectedDesinstalador(null);
            }
          }
        };


        buscarDesInstalador(info[0].FKLokDesistaladores);
        buscarInstalador(info[0].FKLokInstalador);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarCategoriaServicios = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getcategoriasservicios", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        console.log("categorias serv: ", res.data.data);
        setCategoriasServicio(res.data.data);
        const buscarCatServicio = async (id) => {
          if (id === null) {
            setValueCatServ('-No asignado-');
          } else {
            const categoryValue = res.data.data.find((catServ) => catServ.IdCategoriaServ === id);
            if (categoryValue && categoryValue.CategoriaServ) {
              setValueCatServ(categoryValue.CategoriaServ);
              setSelectedCatServicio(id);
            } else {
              setValueCatServ('-No asignado-');
              setSelectedCatServicio(null);
            }
          }
        };
        buscarCatServicio(info[0].FKLokCategoriaServ);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarRutas = async () => {
    try {
      let dataClient = {
        empresa: selectedCliente
      }
      const res = await axios.post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaRutasNegociadas", JSON.stringify(dataClient), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setRutas(res.data.data);
        console.log("Lista de rutas: ", res.data.data);

        const buscarRutaSolicitud = async (id) => {
          if (id === null) {
            setValueRuta('-No asignado-');
          } else {
            const rutaValue = rutas.find((ruta) => ruta.FKICRuta === id);
            if (rutaValue && rutaValue.DescripcionRuta) {
              setValueRuta(rutaValue.DescripcionRuta);
              setSelectedRuta(id);
            } else {
              setValueRuta('-No asignado-');
              setSelectedRuta(null);
            }
          }
        };
        buscarRutaSolicitud(info[0].FKICRutas);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarDestinosAutorizados = async () => {
    try {
      let dataClient = {
        empresa: selectedCliente
      }
      const res = await axios.post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaGeocercasEmpresa", JSON.stringify(dataClient), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setDestinosAutorizados(res.data.data);
        const buscarDestinoAutorizado = async (id) => {
          if (id === null) {
            setValueDestAutorizado('-No asignado-');
          } else {
            const destinoAut = res.data.data.find((dest) => dest.ID === id);
            if (destinoAut && destinoAut.Nombre) {
              setValueDestAutorizado(destinoAut.Nombre);
              setSelectedDestinoAutorizado(id);
            } else {
              setValueDestAutorizado('-No asignado-');
              setSelectedDestinoAutorizado(null);
            }
          }
        };
        buscarDestinoAutorizado(info[0].FKCercaAutorizada);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListartTiposContratos = async () => {
    try {
      let dataClient = {
        empresa: selectedCliente,
        ruta: selectedRuta,
      }
      const res = await axios.post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaNegociaciones", JSON.stringify(dataClient), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setTipoContratos(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListartTiposServicios = async () => {
    try {
      let dataClient = {
        empresa: selectedCliente,
        ruta: selectedRuta,
        externo: selectedTipoContrato
      }
      const res = await axios.post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaNegociacionesFinal", JSON.stringify(dataClient), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        setServicios(res.data.data);
        if (res.data.data.length === 1) {
          setSelectedServicio(res.data.data[0]?.IDNegociacion);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ListarBarras = async () => {
    const res = await axios.get(process.env.REACT_APP_SERVER + "/contratos/getbarras", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
      },
      withCredentials: true,

    });
    if (!res.data.success) {
      window.location = "/";
    } else {
      if (res.data.data !== undefined) {
        console.log("Barras: ", res.data.data);
        setBarras(res.data.data);
        setValueBarras(info[0].FKLokBarsSLM);
      }
    }
  }

  const ListarModServ = async () => {
    const res = await axios.get(process.env.REACT_APP_SERVER + "/contratos/getmodalidadservicio", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
      },
      withCredentials: true,

    });
    if (!res.data.success) {
      window.location = "/";
    } else {
      if (res.data.data !== undefined) {
        console.log("Modalidades de Servicio: ", res.data.data);
        setModalidadServ(res.data.data);
        const buscarModalidadServ = async (id) => {
          if (id === null) {
            setValueModServ('-No asignado-');
          } else {
            const modServ = res.data.data.find((modServ) => modServ.idModalidadServicio === id);
            if (modServ && modServ.ModalidadServicio) {
              setValueModServ(modServ.ModalidadServicio);
              setSelectedModServicio(id);
            } else {
              setValueModServ('-No asignado-');
              setSelectedModServicio(null);
            }
          }
        };
        buscarModalidadServ(info[0].FKLokModalidadServ);
      }
    }
  }

  const ListarOcultos = async () => {
    const res = await axios.get(process.env.REACT_APP_SERVER + "/contratos/getlistadispositivos", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
      },
      withCredentials: true,

    });
    if (!res.data.success) {
      window.location = "/";
    } else {
      if (res.data.data !== undefined) {
        console.log("Dispositivos Ocultos: ", res.data.data);
        setOcultoServ(res.data.data);
        const buscarOcutlo = async (id) => {
          if (id === null) {
            setValueOcultoServ('-No asignado-');
          } else {
            const oculto = res.data.data.find((oculto) => oculto.DeviceID === id);
            if (oculto && oculto.DeviceID) {
              setValueOcultoServ(oculto.DeviceID);
              setSelectedOculto(id);
            } else {
              setValueOcultoServ('-No asignado-');
              setSelectedOculto(null);
            }
          }
        };
        buscarOcutlo(info[0].FKCelloTrack);
      }
    }
  }

  const ObtenerInfoTruck = async (placa) => {
    try {
      let dataPlacaTruck = {
        placa: placa
      }
      const res = await axios.post(process.env.REACT_APP_SERVER + "/solicitudes/getobtenerVehiculo", JSON.stringify(dataPlacaTruck), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });
      if (!res.data.success) {
        window.location = "/";
      } else {
        if (res.data.data !== undefined) {
          setMarcaTruck(res.data.data[0].Marca);
          setColorTruck(res.data.data[0].Color);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChangeCliente = (event) => {
    setSelectedCliente(event.target.value);
  };

  const handleSelectChangeRuta = (event) => {
    setSelectedRuta(event.target.value);
  };

  const handleSelectChangeTipoContrato = (event) => {
    setSelectedTipoContrato(event.target.value);
  };

  const handleSelectChangeServicio = (event) => {
    setSelectedServicio(event.target.value);
  };

  const handleSelectChangeTransportador = (event) => {
    setSelectedTransportador(event.target.value);
  };

  const handleSelectChangeDestAutorizado = (event) => {
    setSelectedDestinoAutorizado(event.target.value);
  };

  const handleSelectChangeTipoUndCarga = (event) => {
    setSelectedTipoUnidadCarga(event.target.value);
  };

  const handleSelectChangeInstalador = (event) => {
    setSelectedInstalador(event.target.value);
  };

  const handleSelectChangeDesinstalador = (event) => {
    setSelectedDesinstalador(event.target.value);
  };

  const handleSelectChangeCatServicio = (event) => {
    setSelectedCatServicio(event.target.value);
  };
  const handleSelectChangeModServicio = (event) => {
    setSelectedModServicio(event.target.value);
  };
  const handleSelectChangeBarras = (event) => {
    setSelectedBarras(event.target.value);
  };
  const handleSelectChangeOCulto = (event) => {
    setSelectedOculto(event.target.value);
  };

  const handleSelectChangeClienteConsulta = (event) => {
    setSelectedClienteConsulta(event.target.value);
  };

  const handleSelectChangeClienteConsulta1 = (event) => {
    setSelectedClienteConsulta1(event.target.value);
  };

  const handleSelectChangeClienteConsulta2 = (event) => {
    setSelectedClienteConsulta2(event.target.value);
  };


  const handleDateTimeChangeHoraCita = (selectedDates) => {
    // `selectedDates` es un array de fechas
    if (selectedDates.length > 0) {
      const selectedDate = selectedDates[0]; // Obtenemos la primera fecha seleccionada

      // Convertimos la fecha a ISO string, luego formateamos para incluir segundos y remover 'T'
      const isoString = selectedDate.toISOString();

      // Extraemos la fecha y hora con segundos, luego las unimos sin la 'T'
      const [date, time] = isoString.split('T');
      const timeWithSeconds = time.slice(0, 8); // Incluimos solo hasta los segundos 'HH:MM:SS'

      const formattedDateTime = `${date} ${timeWithSeconds}`;

      console.log("Fecha y hora formateada:", formattedDateTime);

      setFHoraCita(formattedDateTime); // Guardamos en el estado el valor formateado
    }
  };

  const handleDateTimeChangeHoraDescargue = (selectedDates) => {
    // `selectedDates` es un array de fechas
    if (selectedDates.length > 0) {
      const selectedDate = selectedDates[0]; // Obtenemos la primera fecha seleccionada

      // Convertimos la fecha a ISO string, luego formateamos para incluir segundos y remover 'T'
      const isoString = selectedDate.toISOString();

      // Extraemos la fecha y hora con segundos, luego las unimos sin la 'T'
      const [date, time] = isoString.split('T');
      const timeWithSeconds = time.slice(0, 8); // Incluimos solo hasta los segundos 'HH:MM:SS'

      const formattedDateTime = `${date} ${timeWithSeconds}`;

      console.log("Fecha y hora formateada:", formattedDateTime);

      setFHoraDescargueFormated(formattedDateTime); // Guardamos en el estado el valor formateado
      setFHoraDescargue(formattedDateTime); // Guardamos en el estado el valor formateado
    }
  };


  const handleChangePlacaTruck = (event) => {
    const placaLength = event.target.value.length;
    if (placaLength === 6) {
      setPlacaTruck(event.target.value);
      ObtenerInfoTruck(event.target.value);
    }
  };

  const validateNroContent = (codigo) => {
    const nroContenedorLength = codigo.length;

    const fCalDig = (codigo) => {
      const refc = "0123456789A_BCDEFGHIJK_LMNOPQRSTU_VWXYZ";
      let nValor, nTotal = 0, nPow2 = 1;
      if (codigo.length !== 10) return ' ';
      for (let n = 0; n < 10; n++) {
        nValor = refc.indexOf(codigo.charAt(n));
        if (nValor < 0) return ' ';
        nTotal += nValor * nPow2;
        nPow2 *= 2;
      }
      nTotal = nTotal % 11;
      if (nTotal >= 10) nTotal = 0;
      return nTotal.toString();
    };

    const showError = (message) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          confirmButton.style.backgroundColor = '#ff0000';
          confirmButton.style.color = '#ffffff';
          confirmButton.style.border = 'none';
          confirmButton.style.padding = '10px 20px';
          confirmButton.style.fontSize = '16px';
          confirmButton.style.borderRadius = '5px';
          confirmButton.style.cursor = 'pointer';
        }
      });
    };

    if (nroContenedorLength === 0) {
      setDigitoVerificacion('');
    }

    if (nroContenedorLength === 10) {
      let firstFourAreStrings = true;
      let lastSixAreNumbers = true;

      for (let index = 0; index < 4; index++) {
        if (!isNaN(codigo[index])) {
          firstFourAreStrings = false;
          break;
        }
      }

      for (let index = 4; index < nroContenedorLength; index++) {
        if (isNaN(codigo[index])) {
          lastSixAreNumbers = false;
          break;
        }
      }

      if (!firstFourAreStrings || !lastSixAreNumbers) {
        showError("El número del contenedor debe tener el siguiente formato 'AAAA123456'");
      }

      if (lastSixAreNumbers && firstFourAreStrings) {
        const verificationDigit = fCalDig(codigo);
        setDigitoVerificacion(parseInt(verificationDigit));
      }
    }
  };

  const onSubmit = (dataSolicitud) => {
    axios
      .post(process.env.REACT_APP_SERVER + "/contratos/setupdatecontrato", JSON.stringify(dataSolicitud), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (!res.data.success) {
          window.location = "/";
        } else {
          onClose();
          Swal.fire({
            icon: "success",
            title: "Formulario actualizado con éxito",
            text: "Contrato cargada con éxito",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              confirmButton.style.backgroundColor = '#006699';
              confirmButton.style.color = '#ffffff';
              confirmButton.style.border = 'none';
              confirmButton.style.padding = '10px 20px';
              confirmButton.style.fontSize = '16px';
              confirmButton.style.borderRadius = '5px';
              confirmButton.style.cursor = 'pointer';
            }
          });
        }
      }).catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al actualizar el contrato",
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.style.backgroundColor = '#ff0000';
            confirmButton.style.color = '#ffffff';
            confirmButton.style.border = 'none';
            confirmButton.style.padding = '10px 20px';
            confirmButton.style.fontSize = '16px';
            confirmButton.style.borderRadius = '5px';
            confirmButton.style.cursor = 'pointer';
          }
        });
      });
  };

  const handleSubmit = () => {
    const showErrorAlert = (message) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          confirmButton.style.backgroundColor = '#ff0000';
          confirmButton.style.color = '#ffffff';
          confirmButton.style.border = 'none';
          confirmButton.style.padding = '10px 20px';
          confirmButton.style.fontSize = '16px';
          confirmButton.style.borderRadius = '5px';
          confirmButton.style.cursor = 'pointer';
        }
      });
    };

    if (!selectedCliente || !selectedRuta) {
      showErrorAlert("Debe llenar los campos obligatorios");
      return;
    }

    if (selectedCatServicio != 2 && !causalCat) {
      showErrorAlert("Debes poner una causal de categoria por defecto");
      return;
    }

    const data = {
      FKICEmpresa: selectedCliente,
      FKICEmpresaConsulta: selectedClienteConsulta || null,
      FKICEmpresaConsulta2: selectedClienteConsulta1 || null,
      FKICEmpresaConsulta3: selectedClienteConsulta2 || null,
      FKICRutas: selectedRuta,
      Ref: referencia,
      PlacaTruck: placaTruck || '',
      ColorTruck: colorTruck || '',
      PlacaTrailer: placaRemolque || '',
      NombreConductor: nombreConductor || null,
      FKLokSolicitud: nroSolicitud,
      NitConductor: diConductor || null,
      MovilConductor: cellphoneConductor || null,
      ContainerNum: nroContenedor || null,
      DigitoVerificacion: digitoVerificacion || null,
      FKLokTipoUnidadCarga: selectedTipoUnidadCarga || null,
      Notas: notas || null,
      NombreEscolta: nombreAcompanante || null,
      MovilEscolta: diAcompanante || null,
      NotasTI: causalCat || null,
      FKLokCategoriaServ: selectedCatServicio,
      OtrosDatosTruck: marcaTruck || '',
      FKICTransportadora: selectedTransportador || null,
      contractID: contrato,
      FKLokInstalador: selectedInstalador,
      FechaHoraCita: dates.fechaHoraCita || null,
      FechaHoraCitaDescargue: dates.fechaHoraDescargue || null,
      NotasDatosEntrega: notas,
      FechaHoraDescargue: dates.fechaHoraDescargue || null,
      bitRestriccion: restriccion || false,
      HoraInicioR: times.horaTransitoStart || null,
      HoraFinR: times.horaTransitoEnd || null,
      Solicitante: solicitante,
      Contacto: contacto,
      LightBit: true,
      critico: criticoShow,
      AlertasBit: altShow,
      FKCelloTrack: selectedOculto,
      FKLokModalidadServ: selectedModServicio,
      FKTipoDocumento: null,
      FKCercaAutorizada: selectedDestinoAutorizado,
      Documento: documento,
      Equivalencia: equivalenciaQQ,
      FKLokBarsSLM: selectedBarras,
      Active: contratoShow,
      FKLokDesistaladores: selectedDesInstalador,
      NotaDesisntalaciones: notasDes
    };

    console.log("Data antes de subir: ", data);
    onSubmit(data);
  };

  useEffect(() => {
    // Carga inicial de datos comunes
    const cargarDatosIniciales = async () => {
      try {
        // Realiza todas las solicitudes iniciales
        await Promise.all([
          ListarClientes(),
          ListarTransportadoras(),
          ListarUnidadesCarga(),
          ListarInstaladores(),
          ListarCategoriaServicios(),
          ListarBarras(),
          ListarModServ(),
          ListarOcultos(),
        ]);

        // Después de que todas las solicitudes se completen, formatea las fechas
        if (info && info.length > 0) {
          // const formattedDate = formatToDateTimeLocal(info[0].FechaHoraCita);
          // const formattedDateDescargue = formatToDateTimeLocal(info[0].FechaHoraDescargue);
          // const formattedWhitoutTDate = formatToDateTimeWithoutT(info[0].FechaHoraCita);
          // const formattedWhitoutTDateDescargue = formatToDateTimeWithoutT(info[0].FechaHoraDescargue);
          // setFHoraDescargue(formattedDateDescargue);
          // setFHoraCita(formattedDate);
          // setFHoraCitaFormated(formattedWhitoutTDate);
          // setFHoraDescargueFormated(formattedWhitoutTDateDescargue);
          console.log("Info desde el modal abierto: ", info);
        }

      } catch (error) {
        console.error("Error al cargar datos iniciales: ", error);
      } finally {
        // Finalmente, asegúrate de cambiar isLoading a false
        setIsLoading(false);
      }
    };

    cargarDatosIniciales();
  }, [info]);

  useEffect(() => {
    if (fklokEstados === 4 && selectedCatServicio === 2) {
      setValueTipoFactura("Por Facturar");
    }
    else if (fklokEstados === 4 && selectedCatServicio !== 2) {
      setValueTipoFactura("Cortesia o Prueba(No facturable)");
    }
    else if (fklokEstados === 5) {
      setValueTipoFactura("Facturado");
    }
  }, [fklokEstados, selectedCatServicio]);


  useEffect(() => {
    if (selectedCliente) {
      ListarDestinosAutorizados();
      ListarRutas();
    }
  }, [selectedCliente, selectedRuta]);

  useEffect(() => {
    if (selectedCliente && selectedRuta && selectedTipoContrato) {
      ListartTiposServicios();
    }
  }, [selectedCliente, selectedRuta, selectedTipoContrato]);

  // useEffect(() => {
  //   function extractTime(dateString) {
  //     return dateString.substring(11, 16);
  //   }
  //   function formatDateTime(dateString) {
  //     // Removemos el "T" y ".000Z" para obtener el formato deseado
  //     return dateString.replace('T', ' ').replace('.000Z', '');
  //   }

  //   if (info[0].HoraInicioR !== null) {
  //     setHoraTransitoStart(extractTime(info[0].HoraInicioR));
  //     setTimes((prevState) => ({
  //       ...prevState,
  //       horaTransitoStart: extractTime(info[0].HoraInicioR),
  //     }));
  //     console.log("Hora Inicio: ", extractTime(info[0].HoraInicioR))
  //   } else {
  //     setHoraTransitoStart(null);
  //     setTimes((prevState) => ({
  //       ...prevState,
  //       horaTransitoStart: '',
  //     }));
  //   }
  //   if (info[0].HoraFinR !== null) {
  //     setHoraTransitoEnd(extractTime(info[0].HoraFinR));
  //     setTimes((prevState) => ({
  //       ...prevState,
  //       horaTransitoEnd: extractTime(info[0].HoraFinR),
  //     }));
  //     console.log("Hora Fin: ", extractTime(info[0].HoraFinR))
  //   } else {
  //     setHoraTransitoEnd(null);
  //     setTimes((prevState) => ({
  //       ...prevState,
  //       horaTransitoEnd: '',
  //     }));
  //   }

  //   if (info[0].FechaHoraCita !== null) {
  //     setFHoraCita(formatDateTime(info[0].FechaHoraCita));
  //     setDates((prevState) => ({
  //       ...prevState,
  //       fechaHoraCita: formatDateTime(info[0].FechaHoraCita),
  //     }));
  //     console.log("Hora Cita: ", formatDateTime(info[0].FechaHoraCita));
  //   }

  //   if (info[0].FechaHoraDescargue !== null) {
  //     setFHoraDescargue(formatDateTime(info[0].FechaHoraDescargue));
  //     setDates((prevState) => ({
  //       ...prevState,
  //       fechaHoraDescargue: formatDateTime(info[0].FechaHoraDescargue),
  //     }));
  //     console.log("Hora descargue: ", formatDateTime(info[0].FechaHoraDescargue));
  //   }

  // }, [info]);


  // Efecto para configurar las fechas y horas
  useEffect(() => {
    if (info && info.length > 0) {
      // Formatear las fechas
      const formattedCita = info[0]?.FechaHoraCita ? formatToDateTimeWithoutT(info[0].FechaHoraCita) : '';
      const formattedDescargue = info[0]?.FechaHoraDescargue ? formatToDateTimeWithoutT(info[0].FechaHoraDescargue) : '';

      // Extraer las horas
      const formattedHoraInicio = info[0]?.HoraInicioR ? extractTime(info[0].HoraInicioR) : '';
      const formattedHoraFin = info[0]?.HoraFinR ? extractTime(info[0].HoraFinR) : '';


      // Asignar los valores formateados al estado
      setDates({
        fechaHoraCita: formattedCita,
        fechaHoraDescargue: formattedDescargue,
      });

      setTimes({
        horaTransitoStart: formattedHoraInicio,
        horaTransitoEnd: formattedHoraFin,
      });
    }
  }, [info]); // Se ejecuta cuando `info` cambia

  // Efecto para manejar la inicialización de los datepickers y timepickers cuando la carga finaliza
  useEffect(() => {
    if (!isLoading && info && info.length > 0) {
      // Configuración de jQuery datetimepicker
      const now = new Date();
      const currentDateTime = now.toISOString().slice(0, 16).replace('T', ' ');
      const currentTime = now.toTimeString().slice(0, 5);

      $.datetimepicker.setLocale('en');

      // Configurar cada datetimepicker y timepicker
      $(refs.fechaHoraCita.current).datetimepicker({
        format: 'Y-m-d H:i',
        step: 30,
        value: dates.fechaHoraCita || currentDateTime,
        timepicker: true,
        datepicker: true,
        onChangeDateTime: (dp, $input) => {
          setDates((prevState) => ({
            ...prevState,
            fechaHoraCita: $input.val(),
          }));
        }
      });

      $(refs.fechaHoraDescargue.current).datetimepicker({
        format: 'Y-m-d H:i',
        step: 30,
        value: dates.fechaHoraDescargue || currentDateTime,
        timepicker: true,
        datepicker: true,
        onChangeDateTime: (dp, $input) => {
          setDates((prevState) => ({
            ...prevState,
            fechaHoraDescargue: $input.val(),
          }));
        }
      });

      $(refs.horaTransitoStart.current).datetimepicker({
        datepicker: false,
        format: 'H:i',
        value: times.horaTransitoStart || currentTime,
        step: 15,
        onChangeDateTime: (dp, $input) => {
          setTimes((prevState) => ({
            ...prevState,
            horaTransitoStart: $input.val(),
          }));
        }
      });

      $(refs.horaTransitoEnd.current).datetimepicker({
        datepicker: false,
        format: 'H:i',
        value: times.horaTransitoEnd || currentTime,
        step: 15,
        onChangeDateTime: (dp, $input) => {
          setTimes((prevState) => ({
            ...prevState,
            horaTransitoEnd: $input.val(),
          }));
        }
      });

      // Cleanup para destruir los datetimepickers
      return () => {
        $(refs.fechaHoraCita.current).datetimepicker('destroy');
        $(refs.fechaHoraDescargue.current).datetimepicker('destroy');
        $(refs.horaTransitoStart.current).datetimepicker('destroy');
        $(refs.horaTransitoEnd.current).datetimepicker('destroy');
      };
    }
  }, [isLoading]); // Se ejecuta cuando `isLoading` cambia


  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner style={{ width: '3rem', height: '3rem' }} />{' '}
        <span>Cargando...</span>
      </div>
    );
  }


  return (
    <div >

      <MDBRow style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: '75vh', overflowY: 'auto' }}>
        {/* <MDBRow style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: 'auto', marginBottom: 24 }}>
          <MDBCol md={12}>

          </MDBCol>
        </MDBRow> */}
        <MDBCol md={6}>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="solicitud">Contrato</label>
                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7} >
              <input className="Input" id="name" defaultValue={contrato} disabled />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cliente">Cliente</label>
                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                {/* <TooltipComponent message={'Cliente que hará la solicitud'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeCliente} >
                <option>{valueCliente}</option>
                {clientes.map((cliente) => (
                  <option key={cliente.IdEmpresa} value={cliente.IdEmpresa}>
                    {cliente.NombreEmpresa}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="solicitud">Solicitud</label>
                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7} >
              <input className="Input" id="name" defaultValue={nroSolicitud} disabled />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="referencia">Referencia</label>
                {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}
                <TooltipComponent message={'Acá va la referencia de la solicitud'} />
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="referencia" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="ruta">Ruta</label>
                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                {/* <TooltipComponent message={'Selecciona la ruta para la solicitud'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select
                className="InputSelect"
                onChange={handleSelectChangeRuta}
                value={selectedRuta || ''}
              >
                <option value="">{valueRuta}</option> {/* This will show the initial label */}
                {rutas.map((rta) => (
                  <option key={rta.FKICRuta} value={rta.FKICRuta}>
                    {rta.DescripcionRuta}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="placaTruck">Placa Truck</label>
                {/* <TooltipComponent message={'Acá va la placa del vehículo'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="placaTruck" placeholder='ABC123' onChange={(e) => setPlacaTruck(e.target.value)} onKeyUp={handleChangePlacaTruck} value={placaTruck} />

            </MDBCol>
          </MDBRow>


          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="marca">Otro datos Vehiculo</label>
                {/* <TooltipComponent message={'Acá va la marca del vehículo'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="marca" value={marcaTruck} onChange={(e) => setMarcaTruck(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="color">Color</label>
                {/* <TooltipComponent message={'Describe el color del vehículo para identificarlo'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="color" value={colorTruck} onChange={(e) => setColorTruck(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="color">Placa Remolque</label>
                {/* <TooltipComponent message={'Describe el color del vehículo para identificarlo'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="placaRemolque" value={placaRemolque} onChange={(e) => setPlacaRemolque(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cedulaConductor">Conductor</label>
                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="nombreConductor" type="text" value={nombreConductor} onChange={(e) => setNombreConductor(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cedulaConductor">Cédula Conductor</label>
                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="cedulaConductor" type="number" value={diConductor} onChange={(e) => setDiConductor(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cellphonesConductor">Móvil(es) Conductor</label>
                {/* <TooltipComponent message={'Digita el(los) número(s) de contacto del conductor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="cellphonesConductor" value={cellphoneConductor} onChange={(e) => setCellphoneConductor(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="nombreAcompañante">Nombre Acompañante</label>
                {/* <TooltipComponent message={'Acá va el nombre del acompañante'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="nombreAcompañante" value={nombreAcompanante} onChange={(e) => setNombreAcompanante(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cellphonesAcompañante">Móvil(es) Acompañante</label>
                {/* <TooltipComponent message={'Digita el(los) número(s) de contacto del acompañante'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="cellphonesAcompañante" value={cellphoneAcompanante} onChange={(e) => setCellphoneAcompanante(e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>

            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="destinoAutorizado">Transportador</label>
                {/* <TooltipComponent message={'Elige la transportadora'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeTransportador}>
                <option> {valueTansportador}</option>
                {transpotadoras.map((transport) => (
                  <option key={transport.IdTransportadora} value={transport.IdTransportadora}>
                    {transport.NombreTranspo}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="nroContenedor">Contenedor Número</label>
                {/* <TooltipComponent message={'Número del contendor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, width: '250px' }}>
                <input className="Input" maxlength="10" value={nroContenedor} onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setNroContenedor(value);
                  validateNroContent(value);
                }} style={{ textTransform: 'uppercase' }} />
                <input className="Input" id="guiaContenedorNro" value={digitoVerificacion} disabled />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cedulaConductor">Documento</label>
                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <textarea className="TextArea1" id="nombreConductor" type="text" value={documento} onChange={(e) => setDocumento(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="cedulaConductor">Equivalencia QQ</label>
                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="nombreConductor" type="text" value={equivalenciaQQ} onChange={(e) => setEquivalenciaQQ(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="tipoUnidadCarga">Tipo Unidad de Carga</label>
                {/* <TooltipComponent message={'El tipo de carga varía de acuerdo a'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeTipoUndCarga}>
                <option>{valueTipoUCarga}</option>
                {unidadCargas.map((unidadCarga) => (
                  <option key={unidadCarga.TipoUnidadCargaID} value={unidadCarga.TipoUnidadCargaID}>
                    {unidadCarga.DescripcionTipoCarga}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="clienteConsultas">Cliente de Consultas</label>
                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeClienteConsulta} value={selectedClienteConsulta}>
                <option>{'-No asignado-'}</option>
                {clientesConsulta.map((consulta) => (
                  <option key={consulta.IdEmpresa} value={consulta.IdEmpresa}>
                    {consulta.NombreEmpresa}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="clienteConsultas2">Cliente de Consultas 2</label>
                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeClienteConsulta1} value={selectedClienteConsulta1}>
                <option>{'-No asignado-'}</option>
                {clientesConsulta1.map((ciudad) => (
                  <option key={ciudad.IdEmpresa} value={ciudad.IdEmpresa}>
                    {ciudad.NombreEmpresa}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="clienteConsultas3">Cliente de Consultas 3</label>
                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeClienteConsulta2} value={selectedClienteConsulta2}>
                <option>{'-No asignado-'}</option>
                {clientesConsulta2.map((ciudad) => (
                  <option key={ciudad.IdEmpresa} value={ciudad.IdEmpresa}>
                    {ciudad.NombreEmpresa}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="destinoAutorizado">Destino Autorizado</label>
                {/* <TooltipComponent message={'Un destino autorizado es donde se pueda descargar'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeDestAutorizado}>
                <option>{valueDestAutorizado}</option>
                {destinosAutorizados.map((destino) => (
                  <option key={destino.ID} value={destino.ID}>
                    {destino.Nombre}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="contacto">Contacto</label>
                {/* <TooltipComponent message={'Contacto de cliente de consulta'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <input className="Input" id="contacto" value={contacto} onChange={(e) => setContacto(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="tipoUnidadCarga">Instalador del servicio</label>
                {/* <TooltipComponent message={'La emepresa encargada de la instalacion'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeInstalador}>
                <option>{valueInstalador}</option>
                {instaladores.map((insta) => (
                  <option key={insta.CCInstalador} value={insta.CCInstalador}>
                    {insta.NombreInstalador}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className="InputTooltipWrapper">
                <label className="Label" htmlFor="tipoUnidadCarga">Desinstalador del servicio</label>
                {/* <TooltipComponent message={'La emepresa encargada de la instalacion'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <select className="InputSelect" onChange={handleSelectChangeDesinstalador}>
                <option>{valueDesInstalador}</option>
                {instaladores.map((insta) => (
                  <option key={insta.CCInstalador} value={insta.CCInstalador}>
                    {insta.NombreInstalador}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className='InputTooltipWrapper'>
                <label className="Label" htmlFor="fechaHoraCita">Fecha/Hora Cita</label>
                {/* <TooltipComponent message={'Escoge una fecha y hora para tu solicitud'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <div className="InputTooltipWrapper" >
                <input className="Input" id="fechaHoraCita" ref={refs.fechaHoraCita} type='text' value={dates.fechaHoraCita} onChange={(e) => setDates({ ...dates, fechaHoraCita: e.target.value })} />

              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className='InputTooltipWrapper'>
                <label className="Label" htmlFor="fechaHoraDescargue">Fecha/Hora Descargue</label>
                {/* <TooltipComponent message={'Escoge una fecha y hora para el descargue'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <div className="InputTooltipWrapper">
                <input className="Input" id="fechaHoraDescargue" type='text' ref={refs.fechaHoraDescargue} value={dates.fechaHoraDescargue} onChange={(e) => setDates({ ...dates, fechaHoraDescargue: e.target.value })} />
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className='rowInfo'>
            <MDBCol md={5}>
              <div className='InputTooltipWrapper'>
                <label className="Label" htmlFor="horaTransito">Hora Tránsito</label>
                {/* <TooltipComponent message={'Escoge la hora de tránsito y selecciona el uso horario, revisa si tiene restricciones y actívalas siendo el caso'} /> */}
              </div>
            </MDBCol>
            <MDBCol md={7}>
              <div className="InputTooltipWrapper horasYFechas">
                <input
                  className="Input"
                  id="horaTransito1"
                  ref={refs.horaTransitoStart}
                  type="text" // Cambiado a 'text' para usar con datetimepicker
                  value={times.horaTransitoStart}
                  onChange={(e) => setTimes({ ...times, horaTransitoStart: e.target.value })}
                  autoComplete='off'
                />
                <input
                  className="Input"
                  id="horaTransito2"
                  ref={refs.horaTransitoEnd}
                  type="text" // Cambiado a 'text' para usar con datetimepicker
                  value={times.horaTransitoEnd}
                  onChange={(e) => setTimes({ ...times, horaTransitoEnd: e.target.value })}
                  autoComplete='off'
                />
              </div>
            </MDBCol>
          </MDBRow>
          <fieldset id='checkbox' style={{ width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'start', marginBottom: '5px', marginTop: '5px' }}>
            <div className="checkboxWrapper" >
              {/* <CheckboxComponent checked={restriccion} onChange={handleCheckboxChange}/> */}
              <SwitchButton checked={restriccion} onChange={handleCheckboxChange} type={'Restricción'} />
              <TooltipComponent message={'Con esto activas las restricciones en el horario para la ruta'} />
            </div>
          </fieldset>


        </MDBCol>
        <MDBCol md={6}>
          <fieldset className='Fieldset column'>
            <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }} className='headerInfoContrato'>

              <div ><strong>{info[0]?.FKLokDeviceID}</strong></div>
              {fklokEstados === 4 || fklokEstados === 5 && (
                <div><strong>Estado: </strong> {valueTipoFactura}</div>
              )}
              <div ><strong>Usuario creador: </strong> {info[0]?.UserCreacion}</div>
              <div ><strong>Usuario Instalador: </strong> {info[0]?.UserInstalacion}</div>
              {info[0].UserDesinstalacion !== null && (
                <div ><strong>Usuario Desinstalador: </strong> {info[0]?.UserDesinstalacion}</div>
              )}
            </div>
          </fieldset>

          <fieldset className="Fieldset column">
            <div className='contToltip'>
              <label className="Label" htmlFor="solicitante">Solicitante</label>
              {/* <TooltipComponent message={'Nombre del solicitante o persona encargada'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <input className="Input" id="solicitante" value={solicitante} onChange={(e) => setSolicitante(e.target.value)} />
            </div>
          </fieldset>
          <fieldset className="Fieldset column">
            <div className='contToltip'>
              <label className="Label" htmlFor="datosEntrega">Datos de entrega</label>
              {/* <TooltipComponent message={'Adjunta todos los datos de la entrega en un breve resumen'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <textarea className="TextArea" id="datosEntrega" value={datosEntrega} onChange={(e) => setDatosEntrega(e.target.value)} />
            </div>
          </fieldset>
          <fieldset className="Fieldset column" id="observacionesServicio">
            <div className='contToltip'>
              <label className="Label" htmlFor="observacionesServicio">Observaciones del servicio</label>
              {/* <TooltipComponent message={'Cliente para consultas'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <textarea className="TextArea" id='observacionesText' value={notas} onChange={(e) => setNotas(e.target.value)} />
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Mod. Servicio</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <select className="InputSelect" id='catServicio' onChange={handleSelectChangeModServicio} value={selectedModServicio}>
                <option>{valueModServ}</option>
                {modalidadServ.map((modServ) => (
                  <option key={modServ.idModalidadServicio} value={modServ.idModalidadServicio}>
                    {modServ.ModalidadServicio}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Catg. Servicio</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <select className="InputSelect" id='catServicio' onChange={handleSelectChangeCatServicio} value={selectedCatServicio}>
                {categoriasServicio.map((catServicio) => (
                  <option key={catServicio.IdCategoriaServ} value={catServicio.IdCategoriaServ}>
                    {catServicio.CategoriaServ}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset className="Fieldset column">
            <div className='contToltip'>
              <label className="Label" htmlFor="causalCategoria">Causal por categoría/Manifiesto</label>
              {/* <TooltipComponent message={'Describe el porqué de la elección del tipo de servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <textarea className="TextArea" id="causalCategoria" value={causalCat} onChange={(e) => setCausalCat(e.target.value)} />
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Barra ID</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <select className="InputSelect" id='catServicio' onChange={handleSelectChangeBarras} value={selectedBarras}>
                <option>{valueBarras}</option>
                {barras.map((barras) => (
                  <option key={barras.BarsID} value={barras.BarsID}>
                    {barras.BarsID}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Oculto&nbsp;&nbsp;</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <select className="InputSelect" id='catServicio' onChange={handleSelectChangeOCulto} value={selectedOculto}>
                <option>{valueOcultoServ}</option>
                {ocultoServ.map((octServ) => (
                  <option key={octServ.DeviceID} value={octServ.DeviceID}>
                    {octServ.DeviceID}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>

          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Ver critico</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <SwitchButton checked={criticoShow} onChange={handleCheckboxChangeCritico} type={''} />
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Contrato</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <SwitchButton checked={contratoShow} onChange={handleCheckboxChangeContrato} type={''} />
            </div>
          </fieldset>
          <fieldset className="Fieldset" id='CatServicioFather'>
            <div className='contToltip'>
              <label className="Label" htmlFor="categoriaServicio">Alt</label>
              {/* <TooltipComponent message={'Categoría del servicio'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <SwitchButton checked={altShow} onChange={handleCheckboxChangeAlt} type={''} />
            </div>
          </fieldset>
          <fieldset className="Fieldset column" id="observacionesServicio">
            <div className='contToltip'>
              <label className="Label" htmlFor="observacionesServicio">Notas desinstalación</label>
              {/* <TooltipComponent message={'Cliente para consultas'} /> */}
            </div>
            <div className="InputTooltipWrapper">
              <textarea className="TextArea" id='observacionesText' value={notasDes} onChange={(e) => setNotasDes(e.target.value)} />
            </div>
          </fieldset>
        </MDBCol>

      </MDBRow>
      <div className="ButtonWrapperEdit">

        <div className='buttons'>
          <button className="Button blue" onClick={(e) => { e.preventDefault(); handleSubmit() }}>Grabar</button>


          <button className="Button blue">Enviar Correo</button>
          <button className="Button blue">Enviar a WS</button>
          <button className="Button blue">Liberar solicitud</button>
          <button className="Button blue">Busquedas</button>
          <button className="Button blue">Agregar comandos</button>
        </div>

      </div>
    </div >
  )
}

export default FormUpdateContrato
