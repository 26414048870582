import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
var miStorage = window.localStorage;
const Modal = ({ isOpen, onClose, contratoVar, children }) => {
  const [idseleccionado, setIdseleccionado] = useState(-1);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [options2, setOptions2] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [options3, setOptions3] = useState([]);
  const [selectedOption3, setSelectedOption3] = useState("");
  const [options4, setOptions4] = useState([]);
  const [selectedOption4, setSelectedOption4] = useState("");
  const [options5, setOptions5] = useState([]);
  const [selectedOption5, setSelectedOption5] = useState("");
  const [options6, setOptions6] = useState([]);
  const [selectedOption6, setSelectedOption6] = useState("");
  const [options7, setOptions7] = useState([]);
  const [selectedOption7, setSelectedOption7] = useState("");
  const [options8, setOptions8] = useState([]);
  const [selectedOption8, setSelectedOption8] = useState("");

  useEffect(() => {
    if (isOpen) {
      console.log("entrooooooooo");

      document.getElementById("contratoDian").value = contratoVar;

      const getArriboEmbarque = axios.get(process.env.REACT_APP_SERVER + '/dian/getarriboembarque', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getConsignatarios = axios.get(process.env.REACT_APP_SERVER + '/dian/getconsignatarios', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getDeclarantes = axios.get(process.env.REACT_APP_SERVER + '/dian/getdeclarantes', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getDestinatarios = axios.get(process.env.REACT_APP_SERVER + '/dian/getdestinatarios', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getSeccionales = axios.get(process.env.REACT_APP_SERVER + '/dian/getseccionales', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getTiposDocAduanero = axios.get(process.env.REACT_APP_SERVER + '/dian/gettiposdocaduanero', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      const getDepositosZonasFranca = axios.get(process.env.REACT_APP_SERVER + '/dian/getdepositoszonasfranca', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      });

      Promise.all([
        getArriboEmbarque,
        getConsignatarios,
        getDeclarantes,
        getDestinatarios,
        getSeccionales,
        getTiposDocAduanero,
        getDepositosZonasFranca
      ]).then((responses) => {
        Limpiarformulario();
        setOptions(responses[0].data.data);
        setOptions2(responses[1].data.data);
        setOptions3(responses[2].data.data);
        setOptions4(responses[3].data.data);
        setOptions5(responses[4].data.data);
        setOptions6(responses[4].data.data);
        setOptions7(responses[5].data.data);
        setOptions8(responses[6].data.data);
        const data = {
          "contrato": document.getElementById("contratoDian").value
        };
        const json = JSON.stringify(data);
        axios.post(process.env.REACT_APP_SERVER + '/dian/getcontrato', json, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
          withCredentials: true
        })
          .then(response => {
            if (response.data.success == true) {
              if (response.data.data.length != 0) {
                const objeto = response.data.data[0];
                setIdseleccionado(objeto.ID);
                document.getElementById("tipocontenedorDian").value = objeto.TipoContenedor;
                document.getElementById("numdocaduaneroDian").value = objeto.NumDocAduanero;
                document.getElementById("direccionDian").value = objeto.DireccionDestinoFisica;
                setSelectedOption(objeto.Origen);
                setSelectedOption2(objeto.NitConsignatorio);
                setSelectedOption3(objeto.NitDeclarante);
                setSelectedOption4(objeto.NitDestinatario);
                setSelectedOption5(objeto.AduanaPartida);
                setSelectedOption6(objeto.AduanaDestino);
                setSelectedOption7(objeto.TipoDocAduanero);
                setSelectedOption8(objeto.Destino);
              }
            }
          }).catch(error => {
            console.error(error);
          });


      }).catch((error) => {
        console.log(error);
      });
    }
  }, [isOpen, contratoVar]);

  const Limpiarformulario = () => {
    console.log("entro a limpiar");
    setIdseleccionado(-1);
    document.getElementById("tipocontenedorDian").value = "20";
    document.getElementById("numdocaduaneroDian").value = "";
    document.getElementById("direccionDian").value = "";
    setSelectedOption("-1");
    setSelectedOption2("-1");
    setSelectedOption3("-1");
    setSelectedOption4("-1");
    setSelectedOption5("-1");
    setSelectedOption6("-1");
    setSelectedOption7("-1");
    setSelectedOption8("-1");
  };

  const CrearContratoDian = () => {
    // Obtenemos los valores de los campos del formulario
    const contrato = document.getElementById("contratoDian").value;
    const tipocontenedor = document.getElementById("tipocontenedorDian").value;
    const declarante = document.getElementById("declaranteDian").value;
    const destinatario = document.getElementById("destinatarioDian").value;
    const consignatario = document.getElementById("consignatarioDian").value;
    const tipodocaduanero = document.getElementById("tipodocaduaneroDian").value;
    const numdocaduanero = document.getElementById("numdocaduaneroDian").value;
    const origen = document.getElementById("origenDian").value;
    const aduanapartida = document.getElementById("aduanapartidaDian").value;
    const aduanadestino = document.getElementById("aduanadestinoDian").value;
    const destino = document.getElementById("destinoDian").value;
    const direccion = document.getElementById("direccionDian").value;
    // Creamos un objeto con los datos del formulario
    const data = {
      "ID": idseleccionado,
      "contrato": contrato,
      "tipocontenedor": tipocontenedor,
      "declarante": declarante,
      "destinatario": destinatario,
      "consignatario": consignatario,
      "tipodocaduanero": tipodocaduanero,
      "numdocaduanero": numdocaduanero,
      "origen": origen,
      "aduanapartida": aduanapartida,
      "aduanadestino": aduanadestino,
      "destino": destino,
      "direccion": direccion
    };
    const json = JSON.stringify(data);
    axios.post(`${process.env.REACT_APP_SERVER}/dian/savecontrato`,
      json, { headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${localStorage.getItem('tkn')}` }, withCredentials: true, })
      .then((response) => {
        // Si la petición fue exitosa, cerramos el modal y recargamos la página
        onClose();
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!isOpen) return null;
  return (
    <div>
      <div className="row">
        <div className="mb-3">
          <label className="form-label" htmlFor="contrato">Contrato</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname2" className="input-group-text"
            ><i className="bx bx-file-blank"></i></span>
            <input
              type="text"
              className="form-control"
              id="contratoDian"
              readOnly={true}
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Tipo Contenedor</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="tipocontenedorDian">
              <option value="20">20</option>
              <option value="40">40</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Declarante</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="declaranteDian" value={selectedOption3}
              onChange={(e) => setSelectedOption3(e.target.value)}>
              <option key={-1} value="0">Selecciona una opcion.</option>
              {options3.map((option, index) => (
                <option key={index} value={option.Nit}>{option.RazonSocial}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Destinatario</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="destinatarioDian" value={selectedOption4}
              onChange={(e) => setSelectedOption4(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options4.map((option, index) => (
                <option key={index} value={option.Nit}>{option.RazonSocial}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Consignatario</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="consignatarioDian" value={selectedOption2}
              onChange={(e) => setSelectedOption2(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options2.map((option, index) => (
                <option key={index} value={option.Nit}>{option.RazonSocial}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Tipo Documento aduanero</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="tipodocaduaneroDian" value={selectedOption7}
              onChange={(e) => setSelectedOption7(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options7.map((option, index) => (
                <option key={index} value={option.Codigo}>{option.Descripcion}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Numero documento aduanero</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <input
              type="text"
              className="form-control"
              id="numdocaduaneroDian"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Origen</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="origenDian" value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options.map((option, index) => (
                <option key={index} value={option.Codigo}>{option.Nombre}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Aduana partida</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="aduanapartidaDian" value={selectedOption5}
              onChange={(e) => setSelectedOption5(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options5.map((option, index) => (
                <option key={index} value={option.Codigo}>{option.Nombre}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Aduana Destino</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="aduanadestinoDian" value={selectedOption6}
              onChange={(e) => setSelectedOption6(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options6.map((option, index) => (
                <option key={index} value={option.Codigo}>{option.Nombre}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Destino</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <select className="form-select" id="destinoDian" value={selectedOption8}
              onChange={(e) => setSelectedOption8(e.target.value)}>
              <option key={-1} value="-1">Selecciona una opcion.</option>
              {options8.map((option, index) => (
                <option key={index} value={option.Codigo}>{option.Nombre}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="georuta">Direccion fisica destino</label>
          <div className="input-group input-group-merge">
            <span id="basic-icon-default-fullname" className="input-group-text"
            ><i className="bx bx-trip"></i></span>
            <input
              type="text"
              className="form-control"
              id="direccionDian"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-secondary close" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" className="btn btn-primary" onClick={CrearContratoDian}>Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
