import React, { createRef } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "./Css/themes/base/all.css";
import "../App.css";
import "./Css/trafico.css";
import Barra from "../Modulos/Barra";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import { FaExpand } from "@react-icons/all-files/fa/FaExpand";
import { FaCompress } from "@react-icons/all-files/fa/FaCompress";
import Gallery from "../Modulos/Gallery";
import ReporteServicio from "../Modulos/Reporteservicio";
import ReporteDian from "../Modulos/ReporteDian";
import ReporteContrato from "../Modulos/ReporteContrato";
import VideoGallery from "../componentes/videoGallery";
import Swal from "sweetalert2";
import TraficoResponsive from "../components/responsive/TraficoResponsive";
import PrintComponent from "../components/printComponent/PrintComponent";
import { createRoot } from "react-dom/client";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormUpdateContrato from "../components/formsSolicitudes/formUpdateContrato";
import BaseModalCreateSolcitud from "../components/modals/baseModal";
import axios from './../utils/axiosConfig';
import RSeguimientoServicio from "../components/modals/modalRSeguimientoServicio";
import FormReporteServicio from "./../components/formsSolicitudes/formReporteServicio";
import FormUpdateContratoProyecto from "../components/formsSolicitudes/formUpdateContratoProyectos";
import { formatMinutesToHoursAndMinutes, formattedGlobalDate } from "../utils/formattedDates";
import ModalReporteConsolidadoAjustado from "../components/modals/modalReporteConsolidadoAjustado";
import FormStaticsMapsRecorrido from "../components/formsSolicitudes/formStaticsMapsRecorrido";
import ModalReporteServicio from "../components/modals/modalReporteServicio";

var table;
var miStorage = window.localStorage;
const showEmpresa = localStorage.getItem("sw-empresa") === "true";
const showUbicacionGps = localStorage.getItem("sw-ubicaciongps") === "true";
const showDispositivo = localStorage.getItem("sw-dispositivo") === "true";
const showBateria = localStorage.getItem("sw-bateria") === "true";
const showRuta = localStorage.getItem("sw-ruta") === "true";
const showEstado = localStorage.getItem("sw-estado") === "true";
const showUbicacionTrafico =
  localStorage.getItem("sw-ubicaciontrafico") === "true";
const showTiempo = localStorage.getItem("sw-tiempo") === "true";
const showMoving = localStorage.getItem("sw-moving") === "true";
const showLocked = localStorage.getItem("sw-locked") === "true";
const showBack = localStorage.getItem("sw-back") === "true";
const showDesvio = localStorage.getItem("sw-desvio") === "true";
const showSeguro = localStorage.getItem("sw-seguro") === "true";
const showPlaca = localStorage.getItem("sw-placa") === "true";
const showValitronics = localStorage.getItem("sw-valitronics") === "true";


const trueValues = [
  showEmpresa,
  showUbicacionGps,
  showDispositivo,
  showBateria,
  showRuta,
  showEstado,
  showUbicacionTrafico,
  showTiempo,
  showMoving,
  showLocked,
  showBack,
  showDesvio,
  showSeguro,
  showPlaca,
  showValitronics
];

const columnasfijadas = trueValues.filter((value) => value === true).length + 1;

const videos2 = [
  {
    src: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
    subHtml: `<h4>'Peck Pocketed' by Kevin Herron | Disney Favorite</h4>`,
  },
  {
    src: "https://www.youtube.com/watch?v=ttLu7ygaN6I",
    subHtml: `<h4>Forest Path - Unreal Engine 5 Cinematic by Sharkyy</h4>`,
  },
  {
    src: "https://www.youtube.com/watch?v=C3vyugaBhSs",
    subHtml: `<h4>UE5 | In The Heart Of The Forest by Anastasia Gorban</h4>`,
  },
];

class Trafico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listContratos: [],
      listRutas: [],
      listEventos: [],
      listComprobantes: [],
      contratoseleccionado: -1,
      contratoDian: -1,
      rutaseleccionada: -1,
      eventoseleccionado: -1,
      isShow: false,
      infocontrato: { ContractID: "ND" },
      infocontrato2: { ContractID: "ND" },
      infocontratoAjustado: { ContractID: "ND" },
      modalAbierto: false,
      modalAbierto2: false,
      sortBy: null,
      sortDirection: "asc",
      filteredContratos: [],
      isFullscreen: false,
      fondoseleccionado: "#e6eef7",
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false,
      playing: false,
      modalOpen: false,
      onHide: false,
      collapsableResponsive: false,
      listControlesColores: [],
      isDialogOpenEditSolicitud: false,
      isDialogOpenEditContratoProject: false,
      isDialogOpenRSeguimientoServicio: false,
      isDialogOpenRServicioAjustado: false,
      isDialogOpenModalReporteServicio: false,
      infoUpdateContrato: [],
      project: localStorage.getItem('project'),
      critico: false,
      countContrats: -1,
      ultPunto: -1,
      contractR: -1,
      infocontratoContainer:'',
      // speedMoving:null,
      // timeDetention:'',
      // toltipDetention:'',
    };

    this.ListarContratos();
    this.ListarRutas();
    this.ListarEventos();
    this.listarcontrolesColores();

    this.abrirModal = this.abrirModal.bind(this);
    this.cerrarModal = this.cerrarModal.bind(this);
    this.abrirModal2 = this.abrirModal2.bind(this);
    this.cerrarModal2 = this.cerrarModal2.bind(this);


    /* manejo de this componentes hijos*/
    this.OpenGalleryPhoto = this.OpenGalleryPhoto.bind(this);
    this.OpenGalleryVideo = this.OpenGalleryVideo.bind(this);
    this.OpenUploadPhoto = this.OpenUploadPhoto.bind(this);
    this.OpenUploadVideo = this.OpenUploadVideo.bind(this);
    this.OpenReporteServicio = this.OpenReporteServicio.bind(this);
    this.OpenComprobante = this.OpenComprobante.bind(this);
    this.EditContrato = this.EditContrato.bind(this);
    this.ActualizarContratoDevice = this.ActualizarContratoDevice.bind(this);
    this.onCloseEditContract = this.onCloseEditContract.bind(this);
    this.onCloseReporteServicio = this.onCloseReporteServicio.bind(this);
    this.onCloseReporteServicioAjustado = this.onCloseReporteServicioAjustado.bind(this);
    this.onCloseModalReporteServicio = this.onCloseModalReporteServicio.bind(this);
  }

  abrirModal(data) {
    console.log(data.ContractID);
    this.setState({ modalAbierto: true, contratoDian: data.ContractID });
  }

  cerrarModal() {
    this.setState({ modalAbierto: false });
  }

  abrirModal2(data) {
    console.log("$$", data);
    console.log(data.ContractID);
    this.setState({ modalAbierto2: true, infocontrato2: data });
  }

  cerrarModal2() {
    this.setState({ modalAbierto2: false });
  }

  componentDidUpdate() {
    $("#search").keyup();
  }
  componentDidMount() {
    // Borrar todos los estados de expansión de los contratos en localStorage al montar el componente
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('_expanded')) {
        localStorage.setItem(key, JSON.stringify(false)); // Cambiar el estado de cada contrato a false (colapsado)
      }
    });

    // Leer el estado global de expansión desde localStorage
    const globalExpansionState = JSON.parse(localStorage.getItem('global_expansion_state'));

    if (globalExpansionState !== null) {
      // Si existe un estado global de expansión, aplicarlo a todos los contratos
      this.collapsable(globalExpansionState);
    }

    var busquedaGuardada = localStorage.getItem("search");
    $("#search").val(busquedaGuardada);
    $("#success-alert").hide();
    $("#danger-alert").hide();

    $("#dialog").dialog({
      autoOpen: false,
      show: {
        effect: "blind",
        duration: 1000,
      },
      hide: {
        effect: "explode",
        duration: 500,
      },
    });

    const swEmpresa = localStorage.getItem("sw-empresa");
    document.getElementById("Sw-01").checked = swEmpresa === "true";
    const swUbicacionGps = localStorage.getItem("sw-ubicaciongps");
    document.getElementById("Sw-02").checked = swUbicacionGps === "true";
    const swDispositivo = localStorage.getItem("sw-dispositivo");
    document.getElementById("Sw-03").checked = swDispositivo === "true";
    const swRuta = localStorage.getItem("sw-ruta");
    document.getElementById("Sw-04").checked = swRuta === "true";
    const swUbicacionTrafico = localStorage.getItem("sw-ubicaciontrafico");
    document.getElementById("Sw-05").checked = swUbicacionTrafico === "true";
    const swTiempo = localStorage.getItem("sw-tiempo");
    document.getElementById("Sw-06").checked = swTiempo === "true";
    const swPlaca = localStorage.getItem("sw-placa");
    document.getElementById("Sw-07").checked = swPlaca === "true";
    const swBateria = localStorage.getItem("sw-bateria");
    document.getElementById("Sw-08").checked = swBateria === "true";
    const swEstado = localStorage.getItem("sw-estado");
    document.getElementById("Sw-09").checked = swEstado === "true";
    const swMoving = localStorage.getItem("sw-moving");
    document.getElementById("Sw-11").checked = swMoving === "true";
    const swLocked = localStorage.getItem("sw-locked");
    document.getElementById("Sw-12").checked = swLocked === "true";
    const swBack = localStorage.getItem("sw-back");
    document.getElementById("Sw-13").checked = swBack === "true";
    const swDesvio = localStorage.getItem("sw-desvio");
    document.getElementById("Sw-14").checked = swDesvio === "true";
    const swSeguro = localStorage.getItem("sw-seguro");
    document.getElementById("Sw-15").checked = swSeguro === "true";
    const swValitronics = localStorage.getItem("sw-valitronics");
    document.getElementById("Sw-16").checked = swValitronics === "true";
    var self = this;
    this.setupDataReload();



  }

  setupDataReload() {
    const timereload = parseInt(localStorage.getItem("time_reload")) || 60000;
    this.timeoutId = setTimeout(this.recargarDatosPeriodicamente.bind(this), timereload);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }


  recargarDatos() {
    if (this.isReloading) return;

    this.isReloading = true;

    Promise.all([this.listarcontrolesColores(), this.ListarContratos()])
      // .then(() => {
      //   // Aquí puedes manejar cualquier cosa que necesites después de que ambas promesas se resuelvan
      //   console.log('Datos recargados correctamente');
      // })
      // .catch((error) => {
      //   console.error("Error al recargar datos:", error);
      // })
      .finally(() => {
        this.isReloading = false; // Resetea el estado una vez que termina la recarga
      });
  }


  recargarDatosPeriodicamente() {
    const timereload = parseInt(localStorage.getItem("time_reload")) || 60000; // Valor por defecto de 60 segundos
    console.warn("Refresh trafic routes");
    this.recargarDatos();
    this.timeoutId = setTimeout(this.recargarDatosPeriodicamente.bind(this), timereload);
  }

  handleSearchChange = (event) => {
    const { listContratos } = this.state;
    const value = event.target.value.toLowerCase();
    localStorage.setItem("search", value);
    if (value === "") {
      this.setState({
        filteredContratos: listContratos,
      });
    } else {
      // Filtra los contratos basados en el valor de búsqueda
      var filteredContratos = [];
      const lowerValue = value.toLowerCase();

      for (const contrato of listContratos) {
        const lowerContractID = contrato.ContractID
          ? contrato.ContractID.toLowerCase()
          : "";
        const lowerNombreEmpresa = contrato.NombreEmpresa
          ? contrato.NombreEmpresa.toLowerCase()
          : "";
        const lowerCiudad = contrato.Ciudad
          ? contrato.Ciudad.toLowerCase()
          : "";
        const lowerFKLokDeviceID = contrato.FKLokDeviceID
          ? contrato.FKLokDeviceID.toLowerCase()
          : "";
        const lowerPlacaTruck = contrato.PlacaTruck
          ? contrato.PlacaTruck.toLowerCase()
          : "";
        const lowerDescripcionRuta = contrato.DescripcionRuta
          ? contrato.DescripcionRuta.toLowerCase()
          : "";
        const lowerLastReportUbica = contrato.LastReportUbica
          ? contrato.LastReportUbica.toLowerCase()
          : "";
        const lowerContainerNum = contrato.ContainerNum
          ? contrato.ContainerNum.toLowerCase()
          : "";
        const lowerRef = contrato.Ref ? contrato.Ref.toLowerCase() : "";

        if (
          lowerContractID.includes(lowerValue) ||
          lowerNombreEmpresa.includes(lowerValue) ||
          lowerCiudad.includes(lowerValue) ||
          lowerFKLokDeviceID.includes(lowerValue) ||
          lowerPlacaTruck.includes(lowerValue) ||
          lowerDescripcionRuta.includes(lowerValue) ||
          lowerLastReportUbica.includes(lowerValue) ||
          lowerContainerNum.includes(lowerValue) ||
          lowerRef.includes(lowerValue)
        ) {
          filteredContratos.push(contrato);
        }
      }
      // Actualiza el estado con los contratos filtrados y el valor de búsqueda
      this.setState({
        filteredContratos: filteredContratos,
      });
    }
  };

  toggleFullscreen = () => {
    if (!this.state.isFullscreen) {
      // Activar el modo de pantalla completa
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Desactivar el modo de pantalla completa
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    // Actualizar el estado
    this.setState({ isFullscreen: !this.state.isFullscreen });
  };

  // METODO PARA EDITAR TRAYECTO EN EL CONTRATO
  EditarRuta() {
    let data = {
      Trayecto: this.state.rutaseleccionada,
      Contrato: this.state.contratoseleccionado,
    };
    const json = JSON.stringify(data);
    if (this.state.rutaseleccionada != -1) {
      axios
        .post(
          process.env.REACT_APP_SERVER + "/operaciones/updatecontratotrayecto",
          json,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.hasOwnProperty("success")) {
            $("#success-alert")
              .fadeTo(2000, 500)
              .slideUp(500, function () {
                $("#success-alert").slideUp(2000);
              });
            $("#ModalAsignacion .close").click();

            //history.go(0);
          } else {
            $("#danger-alert")
              .fadeTo(2000, 500)
              .slideUp(500, function () {
                $("#danger-alert").slideUp(2000);
              });
          }
        });
    } else {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    }
  }

  // METODO PARA LISTAR CONTRATOS ACTIVOS
  async ListarContratos() {
    const routeCritica = 'operaciones/getcontratostraficocritico';
    const routeNormal = 'operaciones/getcontratostrafico'
    const routeSelected = this.state.critico ? routeCritica : routeNormal;
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/${routeSelected}`, {
        withCredentials: true, // Si necesitas credenciales
      });

      if (res.data.success === false) {
        window.location = "/";
      } else {
        const contratosConEstadoExpandido = res.data.data.map((contrato) => ({
          ...contrato,
          expanded: JSON.parse(localStorage.getItem(`contrato_${contrato.ContractID}_expanded`)) || false,
        }));
        const countContratos = contratosConEstadoExpandido.length;
        console.log("countContratos :", countContratos);

        this.setState({ listContratos: contratosConEstadoExpandido, filteredContratos: contratosConEstadoExpandido, countContrats: countContratos });
      }
    } catch (error) {
      console.error("Error al listar contratos:", error);
    }
  }


  //MANEJO DE CONTRATO Y REPORTE AJUSTADO
  async handleUltPunto(newValue) {
    this.setState({
      ultPunto: newValue
    });
  }

  async handleUltRecorrido(newValue) {
    this.setState({
      contractR: newValue
    });
  }

  handleSubmitAndOpenReporteAjustado = () => {
    const { infocontrato } = this.state;

    // Mostrar en consola la información de los servicios
    // console.log('Contrato último punto:', ultPunto);
    // console.log('Contrato recorrido:', contractR);
    console.log('INFOCONTRATO:', infocontrato);

    // Hacer una copia del infocontrato para evitar mutaciones
    const infocontratoCopy = { ...infocontrato };

    // Después de actualizar el estado, abrir el modal
    this.setState({
      isDialogOpenRServicioAjustado: false,
    });
    this.OpenModalReporteServicio(infocontratoCopy);
  };


  ListarComprobantes(contrato) {
    let send = {
      contrato: contrato,
    };
    console.log(send);
    const json = JSON.stringify(send);
    axios
      .post(process.env.REACT_APP_SERVER + "/operaciones/getcomprobantevalitronics", json, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listComprobantes: res.data.data });
        }
      });
  }

  // METODO PARA LISTAR TRAYECTOS
  ListarRutas() {
    axios
      .get(process.env.REACT_APP_SERVER + "/operaciones/gettrayectos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          this.setState({ listRutas: res.data.data });
        }
      });
  }

  // ABRIR MODAL DE EDICION DE TRAYECTO-CONTRATO
  EditContrato(data) {
    this.setState({ contratoseleccionado: data.ContractID });
    if (data.trayecto != 0) {
      this.setState({ rutaseleccionada: data.trayecto });
    }
    //$( "#dialog" ).dialog( "open" );
  }

  showEditContract(data) {
    if (this.state.project == 1) {
      this.obtenerYEstablecerDatos(data.ContractID);
    } else {
      this.obtenerYEstablecerDatosProyecto(data.ContractID);
    }
  }

  onCloseEditContract() {
    if (this.state.project == 1) {
      this.setState({
        isDialogOpenEditSolicitud: false
      });
    } else {
      this.setState({
        isDialogOpenEditContratoProject: false
      });
    }
  }

  onCloseReporteServicio() {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
  }
  onCloseModalReporteServicio() {
    this.setState({
      isDialogOpenModalReporteServicio: false,
      ultPunto: -1,
      contractR: -1
    })
  }
  onCloseReporteServicioAjustado() {
    this.setState({
      isDialogOpenRServicioAjustado: false
    });
  }

  editarContrato() {
    this.EditarRuta();
  }

  handleChange(ruta) {
    this.setState({ rutaseleccionada: ruta });
  }

  ChangeFondo(color) {
    this.setState({ fondoseleccionado: color });
  }

  OpenReporteServicio(data) {
    this.setState({ infocontrato: data });
  }

  OpenModalReporteServicio(data) {
    console.log("DAta desde el reporteModal", data);
    this.setState(
      {
        infocontratoAjustado: data,
        isDialogOpenModalReporteServicio: true
      }
    );
  }

  OpenReporteServicioAjustado(data) {
    console.log("Data para el modal Reporte ajustado :", data);
    this.setState({ infocontrato: data, isDialogOpenRServicioAjustado: true });
  }

  OpenListCheck(data) {
    this.setState({ infocontrato: data });
  }


  //LISTAR INFO CONTRATO UPDATE
  obtenerYEstablecerDatos = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontrato",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };

  obtenerYEstablecerDatosProyecto = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontratoproyecto",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };



  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarEventos() {
    axios
      .get(process.env.REACT_APP_SERVER + "/operaciones/geteventos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          this.setState({ listEventos: res.data.data });
          this.setState({ eventoseleccionado: res.data.data[0].ID_Evento });
        }
      });
  }

  // METODO PARA ACTUALIZAR EVENTO SELECCIONADO
  EditarEvento(evento) {
    this.setState({ eventoseleccionado: evento });
  }

  // ABRIR NODAL PARA SUBIR FOTOS
  OpenUploadPhoto(data) {
    $("#fotos").val("");
    this.setState({
      contratoseleccionado: data.ContractID,
      username: data.username,
      placa: data.PlacaTruck,
      device: data.FKLokDeviceID,
    });
    $("#latitud").val("0.00");
    $("#longitud").val("0.00");
    $("#fechafoto").val(data.fecha);
  }

  //ABRIR MODAL PARA SUBIR VIDEOS
  OpenUploadVideo(data) {
    $("#videos").val("");
    this.setState({
      contratoseleccionado: data.ContractID,
      username: data.username,
      placa: data.PlacaTruck,
      device: data.FKLokDeviceID,
    });
    $("#latitud").val("0.00");
    $("#longitud").val("0.00");
    $("#fechaVideo").val(data.fecha);
  }

  // METODO PARA SUBIR FOTOS
  UploadPhoto() {
    console.log("Subiendo Foto...");
    var pattern = /\d+\.\d{2,}$/;
    if (
      !pattern.test($("#latitud").val()) ||
      !pattern.test($("#longitud").val())
    ) {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    } else {
      try {
        const fecha = new Date($("#fechafoto").val());
        if (isNaN(fecha.getTime())) {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        } else {
          const year = fecha.getFullYear();
          const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const dia = ("0" + fecha.getDate()).slice(-2);
          const hora = ("0" + fecha.getHours()).slice(-2);
          const minuto = ("0" + fecha.getMinutes()).slice(-2);
          const segundos = "25";
          var fechacompleta =
            year +
            "_" +
            mes +
            "_" +
            dia +
            "_" +
            hora +
            "_" +
            minuto +
            "_" +
            segundos;

          const files = $("#fotos").prop("files");
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            const newName =
              fechacompleta +
              "_" +
              i +
              "_" +
              this.state.device +
              "_" +
              this.state.placa +
              "_" +
              this.state.eventoseleccionado +
              "_" +
              Math.floor(Math.random() * 1000) +
              "_" +
              this.state.username +
              "_" +
              $("#latitud").val() +
              "_" +
              $("#longitud").val();
            if (files[i].type.startsWith("image/")) {
              formData.append("files", files[i], newName + ".jpg");
            } else if (files[i].type.startsWith("video/")) {
              formData.append("files", files[i], newName + ".mp4");
            }
          }
          let timerInterval;
          Swal.fire({
            title: "Subiendo Foto...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });

          fetch(process.env.REACT_APP_SERVER + "/upload", {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("tkn")}`,
              // No se incluye 'Content-Type': 'multipart/form-data' porque fetch lo maneja automáticamente
            },
            body: formData
          })
            .then(response => response.json())
            .then(data => {
              Swal.close();
              if (data.hasOwnProperty("success")) {
                $("#success-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#success-alert").slideUp(2000);
                  });
                $("#ModalFoto .close").click();
              } else {
                Swal.close();
                $("#danger-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                  });
              }
            })
            .catch(error => {
              Swal.close();
              console.error("Error subiendo foto:", error);
            });
        }
      } catch (err) {
        Swal.close();
        $("#danger-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
      }
    }
  }


  // METODO PARA SUBIR VIDEOS
  UploadVideo() {
    console.log("subiendo video...");
    var pattern = /\d+\.\d{2,}$/;
    if (
      !pattern.test($("#latitud").val()) ||
      !pattern.test($("#longitud").val())
    ) {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    } else {
      try {
        const fecha = new Date($("#fechaVideo").val());
        if (isNaN(fecha.getTime())) {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        } else {
          const year = fecha.getFullYear();
          const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const dia = ("0" + fecha.getDate()).slice(-2);
          const hora = ("0" + fecha.getHours()).slice(-2);
          const minuto = ("0" + fecha.getMinutes()).slice(-2);
          const segundos = "25";
          var fechacompleta =
            year +
            "_" +
            mes +
            "_" +
            dia +
            "_" +
            hora +
            "_" +
            minuto +
            "_" +
            segundos;

          const files = $("#videos").prop("files");
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            const newName =
              fechacompleta +
              "_" +
              i +
              "_" +
              this.state.device +
              "_" +
              this.state.placa +
              "_" +
              this.state.eventoseleccionado +
              "_" +
              Math.floor(Math.random() * 1000) +
              "_" +
              this.state.username +
              "_" +
              $("#latitud").val() +
              "_" +
              $("#longitud").val();
            if (files[i].type.startsWith("image/")) {
              formData.append("files", files[i], newName + ".jpg");
            } else if (files[i].type.startsWith("video/")) {
              formData.append("files", files[i], newName + ".mp4");
            }

            //formData.append('files', files[i]);
          }
          let timerInterval;
          Swal.fire({
            title: "Subiendo Video...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });

          fetch(process.env.REACT_APP_SERVER + "/uploadvideo", formData, {
            method: 'POST',
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
          })
            .then((response) => {
              Swal.close();
              if (response.data.hasOwnProperty("success")) {
                $("#success-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#success-alert").slideUp(2000);
                  });
                $("#ModalVideo .close").click();

                //history.go(0);
              } else {
                Swal.close();
                $("#danger-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                  });
              }
            })
            .catch((error) => {
              Swal.close();
              console.error(error);
            });
        }
      } catch (err) {
        Swal.close();
        $("#danger-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
      }
    }
  }

  showModalOpenResporteServicio = (data) => {
    this.setState({
      contratoseleccionado: data.ContractID,
      isDialogOpenRSeguimientoServicio: true,
    });
  }

  /* Metodos para reporte consolidado ajustado 🎩*/



  /* Metodo para mostrar el contenido del dropdown */

  handleAllStatesDropdowns = () => {
    this.setState({
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false
    })

  }

  toggleDropdownMultimedia = () => {
    this.setState(prevState => ({
      dropdownOpenMultimedia: !prevState.dropdownOpenMultimedia
    }));
  };

  toggleDropdownReportes = () => {
    this.setState(prevState => ({
      dropdownOpenReportes: !prevState.dropdownOpenReportes
    }));
  }
  toggleDropdownOpciones = () => {
    this.setState(prevState => ({
      dropdownOpenOpciones: !prevState.dropdownOpenOpciones
    }));
  }
  toggleDropdownValitronics = () => {
    this.setState(prevState => ({
      dropdownOpenValitronics: !prevState.dropdownOpenValitronics
    }));
  }

  OpenGalleryPhoto(data) {
    this.setState({
      contratoseleccionado: data.ContractID,
      infocontratoContainer: data.ContainerNum
    });
  }

  OpenComprobante(data) {
    this.setState({ contratoseleccionado: data.ContractID });
    this.ListarComprobantes(data.ContractID);
  }

  OpenGalleryVideo(data) {


    this.setState({ contratoseleccionado: data.ContractID });
  }

  ActualizarContratoDevice(data) {
    let send = {
      Device: data.FKLokDeviceID,
      Contrato: data.ContractID,
    };
    const json = JSON.stringify(send);
    axios
      .post(
        process.env.REACT_APP_SERVER + "/operaciones/setlastcontractdevice",
        json,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          $("#success-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#success-alert").slideUp(2000);
            });
        } else {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        }
      });
  }

  handleCheckboxChange(sw, valor) {
    console.log(sw + " - " + valor);
    localStorage.setItem(sw, valor);
  }

  sortData = (field) => {
    const { sortDirection } = this.state;
    const sortedData = [...this.state.filteredContratos].sort((a, b) => {
      if (
        field === "NombreEmpresa" ||
        field === "Ciudad" ||
        field === "FKLokDeviceID" ||
        field === "DescripcionRuta" ||
        field === "LastReportUbica" ||
        field === "PlacaTruck" ||
        field === "IconMoving" ||
        field === "IconLocked" ||
        field === "IconBack" ||
        field === "IconDesvio" ||
        field === "IconSeguro" ||
        field === "IconValitronics"
      ) {
        // Ordenar cadenas alfabéticamente (campo 'empresa')
        return (a[field] || "").localeCompare(b[field] || "");
      } else {
        return (a[field] || 0) - (b[field] || 0);
      }
    });

    // Cambia la dirección de orden si es necesario
    if (sortDirection === "desc") {
      sortedData.reverse();
    }

    this.setState({
      filteredContratos: sortedData,
      sortBy: field,
      sortDirection: sortDirection === "asc" ? "desc" : "asc",
    });
  };

  renderSortIcon(column) {
    const { sortBy, sortDirection } = this.state;

    if (sortBy === column) {
      if (sortDirection === "asc") {
        return <span>&uarr;</span>; // Icono de flecha hacia arriba
      } else {
        return <span>&darr;</span>; // Icono de flecha hacia abajo
      }
    }

    return null; // No mostrar ningún ícono si no está ordenado por esta columna
  }

  handleChangueCritico = async (newValue) => {
    console.log("Cambio de estados contratos criticos", newValue)
    this.setState({
      critico: newValue,
    }, async () => {
      await this.ListarContratos();
    });
  }

  eliminar() {

    var elementos = document.getElementsByClassName("object-box");
    var elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });
  };

  // Función para abrir el modal
  openModal = () => {
    this.setState({ modalOpen: true });
    console.log("Modal abierto");

  };

  // Función para cerrar el modal
  closeModal = () => {
    this.setState({ modalOpen: false });
    this.eliminar();
    this.setState(prevState => ({ ...prevState }));
  };

  //METODO HANDLE PRINT 



  // Función para pausar los videos cuando se cierra el modal
  handleModalClose = () => {
    // Aquí puedes pausar los videos
    console.log('Modal cerrado, pausar los videos aquí');
    this.setState({ onHide: true });

  };

  //LISTAR CONTRATOS CONTROLES DE TRAFICO 
  listarcontrolesColores = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/contratos/getcontrolestrafico`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });

      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("listControlesColores: ", res.data.data);
        this.setState({ listControlesColores: res.data.data });
      }
    } catch (error) {
      console.error("Error al listar los controles de colores:", error);
    }
  };


  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <Barra />

              <div className="content-wrapper">
                <div className="container-fluid flex-grow-1 container-p-y">
                  <MDBRow>
                    <MDBCol md="4">
                      <label
                        className="fw-bold mb-4 label_trafico"
                        style={{ fontSize: "22px", marginRight: "10px" }}
                      >
                        {" "}
                        Control de tráfico {this.state.critico ? 'crítico' : ''} ({this.state.countContrats})
                      </label>
                      <FaEye
                        onClick={() => this.collapsable(true)}
                        style={{ marginRight: "5px" }}
                      />
                      <FaEyeSlash onClick={() => this.collapsable(false)} />
                    </MDBCol>
                    <MDBCol md={2} className='countersColorsTrafico'>
                      <div className='red'>{this.state.listControlesColores[0]?.Rojo}</div>
                      {/* <div className='blue'></div> */}
                      <div className='yellow'>{this.state.listControlesColores[0]?.Amarillo}</div>
                      {/* <div className='green'></div> */}
                      <div className='trans'>{this.state.listControlesColores[0]?.Blanco}</div>
                    </MDBCol>

                    <MDBCol md="1">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={this.toggleFullscreen}
                      >
                        {this.state.isFullscreen ? (
                          <FaCompress />
                        ) : (
                          <FaExpand />
                        )}
                      </button>
                    </MDBCol>

                    <MDBCol md="3">
                      <div className="input-group col-md-12">
                        <span className="input-group-text" id="b1">
                          <FaSearch />
                        </span>
                        <input
                          id="search"
                          type="text"
                          className="form-control"
                          placeholder="Filtrar"
                          aria-describedby="b1"
                          onKeyUp={this.handleSearchChange}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="2" className="d-flex flex-row align-items-center" style={{ height: '38.94px' }}>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEnd"
                        aria-controls="offcanvasEnd"
                      >
                        <FaFilter />
                      </button>
                      {/* <div className="ms-2" style={{ width: '160px' }}>
                        <p className="mb-0">Tráfico crítico</p>
                        <SwitchButton checked={this.state.critico} type={''} onChange={this.handleChangueCritico} />
                      </div> */}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>

                  </MDBRow>

                  <div className="card cardContainerTrafico">
                    <div
                      className="table-responsive text-nowrap"
                      style={{
                        height: `calc(100vh - 180px)`,
                        overflowY: "auto",
                        overflowX: "auto",
                        // display: "flex",
                        // flexDirection: "column",
                      }}
                    >
                      <table className="table table-hover" id="tabla">
                        <thead className="FondoHeaderTabla2">
                          <tr>
                            {showEmpresa && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("NombreEmpresa")}
                              >
                                EMPRESA {this.renderSortIcon("NombreEmpresa")}
                              </th>
                            )}
                            {showDispositivo && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("FKLokDeviceID")}
                              >
                                DISPOSITIVO{" "}
                                {this.renderSortIcon("FKLokDeviceID")}
                              </th>
                            )}
                            {showBateria && (
                              <th
                                style={{ color: "#ffffff", width: "70px" }}
                                onClick={() => this.sortData("bateria")}
                              >
                                BATT {this.renderSortIcon("bateria")}
                              </th>
                            )}
                            {showPlaca && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("PlacaTruck")}
                              >
                                PLACA {this.renderSortIcon("PlacaTruck")}
                              </th>
                            )}
                            {showRuta && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("DescripcionRuta")}
                              >
                                RUTA {this.renderSortIcon("DescripcionRuta")}
                              </th>
                            )}
                            {showEstado && (
                              <th style={{ color: "#ffffff" }}>ESTADO</th>
                            )}
                            {showUbicacionGps && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("Ciudad")}
                              >
                                UBIC. GPS {this.renderSortIcon("Ciudad")}
                              </th>
                            )}
                            {showUbicacionTrafico && (
                              <th
                                style={{ color: "#ffffff" }}
                                onClick={() => this.sortData("LastReportUbica")}
                              >
                                UBIC. TRAFICO{" "}
                                {this.renderSortIcon("LastReportUbica")}
                              </th>
                            )}
                            {showMoving && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconMoving")}
                              >
                                {this.renderSortIcon("IconMoving")}
                              </th>
                            )}
                            {showLocked && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconLocked")}
                              >
                                {this.renderSortIcon("IconLocked")}
                              </th>
                            )}
                            {showBack && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconBack")}
                              >
                                {this.renderSortIcon("IconBack")}
                              </th>
                            )}
                            {showDesvio && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconDesvio")}
                              >
                                {this.renderSortIcon("IconDesvio")}
                              </th>
                            )}
                            {showSeguro && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconSeguro")}
                              >
                                {this.renderSortIcon("IconSeguro")}
                              </th>
                            )}
                            {showValitronics && (
                              <th
                                style={{ color: "#ffffff", width: "20px" }}
                                onClick={() => this.sortData("IconValitronics")}
                              >
                                {this.renderSortIcon("IconValitronics")}
                              </th>
                            )}
                            {showTiempo && (
                              <th
                                style={{ color: "#ffffff", width: "100px" }}
                                onClick={() => this.sortData("tiempoUnix")}
                              >
                                TIEMPO {this.renderSortIcon("tiempoUnix")}
                              </th>
                            )}
                            <th
                              style={{ color: "#ffffff", width: "40px" }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0" id="tabladata">
                          {this.loadFillData(this.state.listContratos)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card cardContainerTrafico2">
                    <TraficoResponsive
                      showEmpresa={showEmpresa}
                      showDispositivo={showDispositivo}
                      showBateria={showBateria}
                      showPlaca={showPlaca}
                      showRuta={showRuta}
                      showEstado={showEstado}
                      showUbicacionGps={showUbicacionGps}
                      showUbicacionTrafico={showUbicacionTrafico}
                      showMoving={showMoving}
                      showLocked={showLocked}
                      showBack={showBack}
                      showDesvio={showDesvio}
                      showSeguro={showSeguro}
                      showValitronics={showValitronics}
                      showTiempo={showTiempo}
                      contratosList={this.state.listContratos}
                      renderempresa={this.renderSortIcon("NombreEmpresa")}
                      renderTiempo={this.renderSortIcon("tiempoUnix")}
                      renderdispositivo={this.renderSortIcon("FKLokDeviceID")}
                      renderbatt={this.renderSortIcon("bateria")}
                      renderplaca={this.renderSortIcon("PlacaTruck")}
                      renderruta={this.renderSortIcon("DescripcionRuta")}
                      rendergps={this.renderSortIcon("Ciudad")}
                      renderubitrafico={this.renderSortIcon("LastReportUbica")}
                      rendericonLocked={this.renderSortIcon("IconLocked")}
                      rendericonback={this.renderSortIcon("IconBack")}
                      rendericondesvio={this.renderSortIcon("IconBack")}
                      rendericonseguro={this.renderSortIcon("IconSeguro")}
                      rendericonvalitronics={this.renderSortIcon("IconValitronics")}
                      renderDropdown={() => this.handleAllStatesDropdowns()}
                      OpenReporteServicio={this.OpenReporteServicio}
                      abrirModal2={this.abrirModal2}
                      abrirModal={this.abrirModal}
                      abrirModalMonitoreo={this.showModalOpenResporteServicio}
                      toggleDropdownReportes={() => this.toggleDropdownReportes()}
                      toggleDropdownMultimedia={() => this.toggleDropdownMultimedia()}
                      toggleDropdownValitronics={() => this.toggleDropdownValitronics()}
                      toggleDropdownOpciones={() => this.toggleDropdownOpciones()}
                      dropdownOpenReportes={this.state.dropdownOpenReportes}
                      dropdownOpenMultimedia={this.state.dropdownOpenMultimedia}
                      dropdownOpenValitronics={this.state.dropdownOpenValitronics}
                      dropdownOpenOpciones={this.state.dropdownOpenOpciones}
                      EditTrayecto={this.EditContrato}
                      EditContrato={this.showEditContract}
                      ActualizarContratoDevice={this.ActualizarContratoDevice}
                      OpenComprobante={this.OpenComprobante}
                      OpenUploadPhoto={this.OpenUploadPhoto}
                      OpenUploadVideo={this.OpenUploadVideo}
                      OpenGalleryPhoto={this.OpenGalleryPhoto}
                      OpenGalleryVideo={this.OpenGalleryVideo}
                      collapsableResponsive={this.state.collapsableResponsive}
                      filteredContratos={this.state.filteredContratos}
                      project={this.state.project}
                    />
                  </div>
                </div>
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasEnd"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Filtro controles tráfico
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="row">

              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-16"
                    data-bs-dismiss="offcanvas"
                    onChange={(e) =>
                      this.handleChangueCritico(!this.state.critico)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Tráfico {this.state.critico ? 'crítico' : 'normal'}
                  </label>
                </div>
              </div>
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Filtros grilla
              </h5>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-01"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-empresa", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Empresa
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-02"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-ubicaciongps",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ubicación GPS
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-03"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-dispositivo",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Dispositivo
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-04"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-ruta", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ruta
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-05"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-ubicaciontrafico",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ubicación Trafico
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-06"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-tiempo", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Tiempo
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-07"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-placa", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Placa
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-08"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-bateria", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Bateria
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-09"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-estado", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Estado
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-11"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-moving", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Movimiento
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-12"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-locked", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Apertura
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-13"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-back", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Back
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-14"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-desvio", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Desvio
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-15"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-seguro", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Asegurado
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-16"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-valitronics", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Valitronics
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Fondo </label>
                  <select
                    className="form-select"
                    defaultValue={this.state.fondoseleccionado}
                    onChange={(e) => this.ChangeFondo(e.target.value)}
                  >
                    <option value="#e6eef7">Azul</option>
                    <option value="#F5F5D1">Amarillo</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Modal edicion de contrato 🎩*/}
        <BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditSolicitud}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={<FormUpdateContrato contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} onClose={this.onCloseEditContract} />}
        />
        {/* Modal edicion de contrato 🎩*/}

        {/* Modal ediciotn contrato proyecto */}
        <BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditContratoProject}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={<FormUpdateContratoProyecto contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} onClose={this.onCloseEditContract} />}
        />
        {/* Modal ediciotn contrato proyecto */}

        {/* Modal seguimiento contrato */}
        <RSeguimientoServicio
          isOpen={this.state.isDialogOpenRSeguimientoServicio}
          title={'Monitoreo de tráfico'}
          onClose={this.onCloseReporteServicio}
          childComponent={<FormReporteServicio contrato={this.state.contratoseleccionado} />}
        />
        {/* Modal seguimiento contrato */}

        {/* Modal Reporte consolidado ajustado */}
        <ModalReporteConsolidadoAjustado
          isOpen={this.state.isDialogOpenRServicioAjustado}
          title={'Reporte consolidado ajustado'}
          onClose={this.onCloseReporteServicioAjustado}
          childComponent={<FormStaticsMapsRecorrido
            infoContrato={this.state.infocontrato}
            handleUltPunto={(value) => this.handleUltPunto(value)}
            handleUltRecorrido={(value) => this.handleUltRecorrido(value)}
            onSubmit={this.handleSubmitAndOpenReporteAjustado}
          />}
        />
        {/* Modal Reporte consolidado ajustado */}
        {/* Modal Reporte servicio */}
        <ModalReporteServicio
          isOpen={this.state.isDialogOpenModalReporteServicio}
          onClose={this.onCloseModalReporteServicio}
          childComponent={<ReporteServicio
            contrato={this.state.infocontratoAjustado}
            ultPunto={this.state.ultPunto}
            contractR={this.state.contractR}
          />}
        />
        {/* Modal Reporte servicio */}


        <div
          className="modal fade"
          id="ModalAsignacion"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Asignación de trayecto
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="georuta">
                      Trayecto
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-trip"></i>
                      </span>
                      <select
                        className="form-select"
                        id="georuta"
                        defaultValue={this.state.rutaseleccionada}
                        onChange={(e) => this.handleChange(e.target.value)}
                      >
                        <option key={-1} value="-1">
                          Selecciona un trayecto.
                        </option>
                        {this.state.listRutas.map((loc) => (
                          <option key={loc.IDTrayecto} value={loc.IDTrayecto}>
                            {loc.DescripcionTrayecto}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.editarContrato()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalFoto"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Subir fotos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="latitud">
                      Latitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="latitud"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="longitud">
                      Longitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="longitud"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fechafoto">
                      Hora/Fecha
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-calendar"></i>
                      </span>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="fechafoto"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="eventos">
                      Evento
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <select
                        className="form-select"
                        id="eventos"
                        name="location-input"
                        value={this.state.eventoseleccionado}
                        onChange={(e) => this.EditarEvento(e.target.value)}
                      >
                        {this.state.listEventos.map((loc) => (
                          <option key={loc.ID_Evento} value={loc.ID_Evento}>
                            {loc.Descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fotos">
                      Fotos
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-file"></i>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        id="fotos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.UploadPhoto()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalVideo"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Subir Videos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="latitud">
                      Latitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="latitud"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="longitud">
                      Longitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="longitud"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fechaVideo">
                      Hora/Fecha
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-calendar"></i>
                      </span>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="fechaVideo"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="eventos">
                      Evento
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <select
                        className="form-select"
                        id="eventos"
                        name="location-input"
                        value={this.state.eventoseleccionado}
                        onChange={(e) => this.EditarEvento(e.target.value)}
                      >
                        {this.state.listEventos.map((loc) => (
                          <option key={loc.ID_Evento} value={loc.ID_Evento}>
                            {loc.Descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="videos">
                      Videos
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-file"></i>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        id="videos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.UploadVideo()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xl">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  FOTOS CONTRATO
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.eliminar}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <Gallery contrato={this.state.contratoseleccionado} infoContrato={this.state.infocontratoContainer} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalVideoGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  VIDEOS
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { this.handleModalClose() }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="container text-center">
                  <div className="row">
                    <VideoGallery contrato={this.state.contratoseleccionado} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalReporteServicio"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteServicio contrato={this.state.infocontrato} />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Modal para lista de chequeo  🎩*/}
        <div
          className="modal fade"
          id="ModalListaCheck"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <PrintComponent data={this.state.infocontrato} />
                </div>
              </div>
            </form>
          </div>
        </div>


        <div
          className="modal fade"
          id="ModalComprobanteValitronics"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <table className="table table-hover">
                    <thead className="FondoHeaderTabla2">
                      <th style={{ color: "#ffffff" }}>Comprobante</th>
                      <th style={{ color: "#ffffff" }}>fecha</th>
                      <th style={{ color: "#ffffff" }}>Latitud</th>
                      <th style={{ color: "#ffffff" }}>Longitud</th>
                      <th style={{ color: "#ffffff" }}>Usuario</th>
                    </thead>
                    <tbody id="tablacomprobante">
                      {this.loadFillData2(this.state.listComprobantes)}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalReporteDian"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Contrato Dian
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteDian
                    isOpen={this.state.modalAbierto}
                    onClose={this.cerrarModal}
                    contratoVar={this.state.contratoDian}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalReporteSegimiento"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden={!this.state.modalAbierto2}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xl">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Contrato Seguimiento
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.cerrarModal2}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteContrato
                    isOpen={this.state.modalAbierto2}
                    onClose={this.cerrarModal2}
                    contratoVar={this.state.infocontrato2}
                    aleatorio={Math.floor(Math.random() * 1000) + 1}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la
          información.
        </div>

        <div id="dialog" title="Basic dialog"></div>
      </>
    );
  }

  toggleRow(contrato) {
    const updatedListContratos = this.state.filteredContratos.map((item) => {
      if (item.ContractID === contrato) {
        const newExpandedState = !item.expanded;
        // Guardar el nuevo estado expandido en localStorage
        localStorage.setItem(`contrato_${item.ContractID}_expanded`, JSON.stringify(newExpandedState));
        return {
          ...item,
          expanded: newExpandedState,
        };
      }
      return item;
    });

    this.setState({ filteredContratos: updatedListContratos });
  }

  collapsable(estado) {
    // Guardar el estado global de expansión en localStorage
    localStorage.setItem('global_expansion_state', JSON.stringify(estado));

    const updatedListContratos = this.state.filteredContratos.map((item) => {
      // Actualizar cada contrato con el estado global de expansión
      localStorage.setItem(`contrato_${item.ContractID}_expanded`, JSON.stringify(estado));
      return {
        ...item,
        expanded: estado,
      };
    });

    this.setState({ filteredContratos: updatedListContratos, collapsableResponsive: estado });
  }

  loadFillData2(array) {
    return this.state.listComprobantes.map((data) => {
      return (
        <tr>
          <td className="prueba" style={{ color: "#000" }}>
            <strong>{data.Comprobante}</strong>
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Fecha}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Latitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Longitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Usuario}
          </td>
        </tr>
      )
    });
  }
  loadFillData(array) {
    return this.state.filteredContratos.map((data) => {
      let tooltipDetention = ``;
      if (data.IconMoving.includes('stop')) {
        const dataMin = formatMinutesToHoursAndMinutes(data.tiempodetencion);
        tooltipDetention = `EL DISPOSITIVO ESTA DETENIDO \n DESDE HACE ${dataMin}`

      } else {
        tooltipDetention = `EL DISPOSITIVO VA \n A ${data.Speed} KM/H`

      }

      console.log("Fitered contrato: ", data);
      return (
        <React.Fragment key={data.ContractID}>
          <tr onClick={() => this.toggleRow(data.ContractID)}>
            {showEmpresa && (
              <td className="prueba" style={{ color: "#000" }}>
                <strong>{data.NombreEmpresa}</strong>
              </td>
            )}
            {showDispositivo && (
              <td style={{ color: "#000" }}>{data.FKLokDeviceID}</td>
            )}
            {showBateria && (
              <td>
                <img src={`${data.icon_bat}`} width="20" height="25" title={data.bateria} />
              </td>
            )}
            {showPlaca && <td style={{ color: "#000" }}>{data.PlacaTruck}</td>}
            {showRuta && (
              <td style={{ color: "#000" }}>{data.DescripcionRuta}</td>
            )}
            {showEstado && (
              <td style={{ color: "#000" }}>{data.TipoReporte}</td>
            )}
            {showUbicacionGps && (
              <td style={{ color: "#000" }}>{data.Ciudad}</td>
            )}
            {showUbicacionTrafico && (
              <td style={{ color: "#000" }}>{data.LastReportUbica}</td>
            )}
            {showMoving && (
              <td className="textCenterIcon">
                <img src={`${data.IconMoving}`} width="25" height="25" title={tooltipDetention} />
              </td>
            )}
            {showLocked && (
              <td>
                <img src={`${data.IconLocked}`} width="25" height="25" />
              </td>
            )}
            {showBack && (
              <td>
                <img src={`${data.IconBack}`} width="25" height="25" />
              </td>
            )}
            {showDesvio && (
              <td>
                <img src={`${data.IconDesvio}`} width="25" height="25" />
              </td>
            )}
            {showSeguro && (
              <td>
                <img src={`${data.IconSeguro}`} width="25" height="25" />
              </td>
            )}
            {showValitronics && (
              <td>
                <img src={`${data.IconValitronics}`} width="25" height="25" />
              </td>
            )}
            {showTiempo && <td>{data.Tiempo}</td>}
            <td>
              <div className="dropdown position-static">
                <button
                  type="button"
                  className="btn p-0 dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                  onClick={() => this.handleAllStatesDropdowns()}
                >
                  <i className="bx bx-dots-vertical position-static"></i>
                </button>
                <div className="dropdown-menu">
                  {data.FKLokTipoEquipo !== 8 && (
                    <span
                      onClick={() => this.abrirModal2(data)}
                      data-bs-toggle="modal"
                      data-bs-target="#ModalReporteSegimiento"
                      className="dropdown-item"
                    >
                      <i className="bx bxs-map me-1"></i> Tracking Tiempo Real
                    </span>
                  )}
                  <span
                    onClick={() => this.showModalOpenResporteServicio(data)}
                    className="dropdown-item"
                  >
                    <i className="bx bx-file me-1"></i> Monitoreo de tráfico
                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownReportes() }} className="dropdown-item"><i className="bx bxs-report"></i>Reportes <i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenReportes && (
                      <div className="containerSpaceMenu">

                        <span
                          onClick={() => this.OpenReporteServicio(data)}
                          data-bs-toggle="modal"
                          data-bs-target="#ModalReporteServicio"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Reporte consolidado
                        </span>
                        <span
                          onClick={() => this.OpenReporteServicioAjustado(data)}
                          className="dropdown-item">
                          <i className="bx bx-file me-1"></i> Reporte consolidado ajustado
                        </span>
                        {data.FKLokTipoEquipo !== 8 && (
                          <span
                            onClick={() => this.abrirModal(data)}
                            data-bs-toggle="modal"
                            data-bs-target="#ModalReporteDian"
                            className="dropdown-item"
                          >
                            <i className="bx bx-file me-1"></i> Reporte Dian
                          </span>

                        )}
                      </div>
                    )}
                  </span>

                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownMultimedia() }} className="dropbtn dropdown-item"><i className="bx bx-film"></i>Multimedia <i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenMultimedia && (
                      <div className="containerSpaceMenu">
                        <span onClick={() => this.OpenUploadPhoto(data)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                          <i className="bx bx-upload me-1"></i> Subir Fotos
                        </span>
                        <span onClick={() => this.OpenUploadVideo(data)} data-bs-toggle="modal" data-bs-target="#ModalVideo" className="dropdown-item">
                          <i className="bx bx-upload me-1"></i> Subir Videos
                        </span>
                        <span onClick={() => this.OpenGalleryPhoto(data)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                          <i className="bx bx-camera me-1"></i> Ver Fotos
                        </span>
                        <span onClick={() => this.OpenGalleryVideo(data)} data-bs-toggle="modal" data-bs-target="#ModalVideoGaleria" className="dropdown-item">
                          <i className="bx bx-video me-1"></i> Ver Videos
                        </span>
                      </div>
                    )}

                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownValitronics() }} className="dropdown-item"><i className="bx bx-check-circle"></i>Valitronics<i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenValitronics && (
                      <div className="containerSpaceMenu">
                        <span
                          onClick={() => this.OpenComprobante(data)}
                          data-bs-toggle="modal" data-bs-target="#ModalComprobanteValitronics"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Historial de validaciones
                        </span>
                        <span
                          onClick={() => this.OpenListCheck(data)}
                          data-bs-toggle="modal"
                          data-bs-target="#ModalListaCheck"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Check List Contenedor
                        </span>
                      </div>
                    )}
                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownOpciones() }} className="dropdown-item"><i className="bx bxs-cog"></i>Opciones<i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenOpciones && (
                      <div className="containerSpaceMenu">

                        <span
                          onClick={() => this.showEditContract(data)}
                          className="dropdown-item"
                        >
                          <i className="bx bx-edit-alt me-1"></i> Editar Contrato
                        </span>

                        <span
                          onClick={() => this.EditContrato(data)}
                          data-bs-toggle="modal"
                          data-bs-target="#ModalAsignacion"
                          className="dropdown-item"
                        >
                          <i className="bx bx-edit-alt me-1"></i> Editar Trayecto
                        </span>
                        {data.FKLokTipoEquipo !== 8 && (
                          <span
                            onClick={() => this.ActualizarContratoDevice(data)}
                            className="dropdown-item"
                          >
                            <i className="bx bx-file me-1"></i> Actualizar contrato
                          </span>
                        )}

                      </div>
                    )}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          {data.expanded && (
            <tr
              style={{
                backgroundColor: this.state.fondoseleccionado,
                borderBottom: "1px dashed #006699",
              }}
            >
              <td colSpan={`${columnasfijadas}`}>
                <div className="row">
                  <div className="col-md-2">
                    <b>Contrato:</b> {data.ContractID}
                    <br />
                    <b>Nivel riesgo:</b>
                  </div>
                  <div className="col-md-2">
                    <b>Ref:</b> {data.Ref}
                    <br />
                    <b>Container:</b> {data.ContainerNum}
                  </div>
                  <div className="col-md-3">
                    <b>Trayecto:</b> {data.DescripcionTrayecto}
                    <br />
                    <b>Ultimo Contacto:</b> {formattedGlobalDate(data.LoksysServerTime)}
                  </div>
                  <div className="col-md-5">
                    <b>Observaciones:</b>
                    <br />
                    {data.LastReportNota}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
    $(".dropdown-toggle").dropdown();
  }
}

export default Trafico;
