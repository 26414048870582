import React, { Component } from 'react';
// import { Polyline } from 'google-map-react';
// import axios from 'axios';
// import PolylineEncoder from 'polyline-encoded';
var miStorage = window.localStorage;
class StaticMapRecorrido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recorrido: [],
      mapKey: 0,
      loading: true,
      imageUrl: null,
    
    };

  }

  componentDidMount() {
    // console.log("este es el contendio Validatronixc ***", this.props.recorridoValitronics);
    this.setState({ recorrido: this.props.recorrido });
    console.log("Recorrido", this.props.recorrido);
    console.log("Ultimo punto registrado: ", this.props.ultRecorrido);
    // if (this.props.recorridoValitronics !== null) {
    //   this.setState({ recorrido: this.props.recorridoValitronics });
    // }

  }

  componentDidUpdate(prevProps) {
    if (this.props.recorrido !== prevProps.recorrido) {
      this.setState((prevState) => ({
        recorrido: this.props.recorrido,
        loading: true,
        mapKey: prevState.mapKey + 1,
        imageUrl: ""
      }), () => {
        try {
          this.updateImage();
        } catch (err) {
          console.log(err);
        }

      });
    }
    console.log(this.state.recorrido);
  }

  simplifyPath = (path, tolerance) => {
    const simplifiedPath = [path[0]];

    for (let i = 1; i < path.length - 1; i++) {
      const prevPoint = simplifiedPath[simplifiedPath.length - 1];
      const currentPoint = path[i];
      const distance = this.calculateDistance(prevPoint, currentPoint);

      if (distance > tolerance) {
        simplifiedPath.push(currentPoint);
      }
    }

    simplifiedPath.push(path[path.length - 1]);
    return simplifiedPath;
  };

  calculateDistance = (point1, point2) => {
    const x1 = point1.latitude;
    const y1 = point1.longitude;
    const x2 = point2.latitude;
    const y2 = point2.longitude;
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  updateImage = () => {
    try {
      /*const filteredPath = this.props.recorrido.filter(punto => punto.latitude !== 0 && punto.longitude !== 0);
      const path = filteredPath.map((punto) => ({lat: punto.latitude, lng: punto.longitude}));
      const encodedPolyline = PolylineEncoder.encode(path, 4);
      console.log("3 ->"+encodedPolyline.length);
      const encodedPolyline2 = PolylineEncoder.encode(path, 5);
      console.log("5 ->" +encodedPolyline2.length);
      const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=330x330&path=enc:${encodedPolyline}&style=feature:all|element:labels|visibility:on&style=feature:all|element:geometry|saturation:0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;*/

      console.log(this.state.recorrido);
      const filteredPath = this.props.recorrido.filter(punto => punto.latitude !== 0 && punto.longitude !== 0);
      const tolerance = 0.01;
      const simplifiedPath = this.simplifyPath(filteredPath, tolerance);
      console.log(simplifiedPath);
      const polyline = simplifiedPath.map(point => `${point.latitude},${point.longitude}`).join('|');
      const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=330x330&path=${polyline}&style=feature:all|element:labels|visibility:on&style=feature:all|element:geometry|saturation:0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;


      this.setState({ imageUrl: imageUrl, loading: true });
    } catch (err) {
      console.log(err);
    }

  }

  render() {
    return (
      <div style={{ height: '330px', width: '100%' }} className='imgUltimoRecorrido'>
        {this.state.loading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', zindex: 1000 }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <img
          onLoad={() => this.setState({ loading: false })}
          onError={() => this.setState({ loading: false })}
          src={this.state.imageUrl}
        />
      </div>
    );
  }
}



export default StaticMapRecorrido;
