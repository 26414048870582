import React, { useEffect } from 'react';
import "../../App.css";
import "../../Pages/Css/trafico.css";

const HistoricoResponsive = (props) => {
    const busquedaValue = localStorage.getItem('searchHistorico');
    useEffect(() => {
        console.log("Lista de contratos historico", props.listContratos);
    }, [props]);
    return (
        <div className='containerCardsHistoricos'>
            {busquedaValue ? (
                props.listContratos && props.listContratos
                    .filter(contrato =>
                        contrato.ContractID.toString().toLowerCase().includes(busquedaValue.toLowerCase()) ||
                        contrato.NombreEmpresa.toLowerCase().includes(busquedaValue.toLowerCase())
                    )
                    .map((contrato, index) => (
                        <div key={index} className='card containerContratoHistoricoInitial'>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                <div className="dropdown position-static">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                        <i className="bx bx-dots-vertical position-static"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                        <span onClick={() => props.OpenUploadPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                                            <i className="bx bx-upload me-1"></i> Subir Fotos
                                        </span>
                                        <span onClick={() => props.OpenGalleryPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                                            <i className="bx bx-camera me-1"></i> Ver Fotos
                                        </span>
                                        <span onClick={() => props.OpenReporteServicio(contrato)} data-bs-toggle="modal" data-bs-target="#ModalReporteServicio" className="dropdown-item">
                                            <i className="bx bx-file me-1"></i> Ver Reporte
                                        </span>
                                        <span onClick={() => props.abrirModal2(contrato)} data-bs-toggle="modal" data-bs-target="#ModalReporteSegimiento" className="dropdown-item">
                                            <i className="bx bx-file me-1"></i> Ver Reporte Seguimiento
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <p><span>Contrato: </span>{contrato.ContractID}</p>
                            <p><span>Dispositivo: </span> {contrato.FKLokDeviceID}</p>
                            <p><span>Ruta: </span> {contrato.DescripcionRuta}</p>
                            <p><span>Trayecto: </span></p>

                        </div>
                    ))
            ) : (
                props.listContratos && props.listContratos.map((contrato, index) => (
                    <div key={index} className='card containerContratoHistoricoInitial'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                            <div className="dropdown position-static">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                    <i className="bx bx-dots-vertical position-static"></i>
                                </button>
                                <div className="dropdown-menu">
                                    <span onClick={() => props.OpenUploadPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                                        <i className="bx bx-upload me-1"></i> Subir Fotos
                                    </span>
                                    <span onClick={() => props.OpenGalleryPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                                        <i className="bx bx-camera me-1"></i> Ver Fotos
                                    </span>
                                    <span onClick={() => props.OpenReporteServicio(contrato)} data-bs-toggle="modal" data-bs-target="#ModalReporteServicio" className="dropdown-item">
                                        <i className="bx bx-file me-1"></i> Ver Reporte
                                    </span>
                                    <span onClick={() => props.abrirModal2(contrato)} data-bs-toggle="modal" data-bs-target="#ModalReporteSegimiento" className="dropdown-item">
                                        <i className="bx bx-file me-1"></i> Ver Reporte Seguimiento
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p><span>Contrato: </span>{contrato.ContractID}</p>
                        <p><span>Dispositivo: </span> {contrato.FKLokDeviceID}</p>
                        <p><span>Ruta: </span> {contrato.DescripcionRuta}</p>
                        <p><span>Trayecto: </span></p>

                    </div>
                ))
            )}
        </div>
    )
}

export default HistoricoResponsive
