import axios from "./utils/axiosConfig";
class TokenServiceRefresh {
    static token = localStorage.getItem("tkn") || null;
    static refreshInterval = null;
    static refreshIntervalTime = 58 * 60 * 1000; // 58 minutos en milisegundos
    static expirationTime = localStorage.getItem("tokenExpiration") || null;

    // Método para iniciar el intervalo de actualización del token al iniciar sesión
    static startTokenRefreshIntervalOnLogin() {
        const loginTime = new Date().getTime(); // Obtener el tiempo actual al iniciar sesión

        // Comparar el tiempo actual con el tiempo de inicio de sesión
        const elapsedTime = new Date().getTime() - loginTime;

        // Calcular el tiempo restante hasta que se alcance el intervalo de actualización del token (55 minutos)
        const timeUntilRefresh = TokenServiceRefresh.refreshIntervalTime - elapsedTime;

        // Si el tiempo restante es positivo, iniciar el intervalo con el tiempo restante
        if (timeUntilRefresh > 0) {
            setTimeout(() => {
                TokenServiceRefresh.refreshTokenIfNeeded();
                // Después de la primera actualización, iniciar el intervalo regularmente
                TokenServiceRefresh.startTokenRefreshInterval();
            }, timeUntilRefresh);
        } else {
            // Si el tiempo restante es negativo o cero, iniciar el intervalo inmediatamente
            TokenServiceRefresh.startTokenRefreshInterval();
        }
    }

    // Método para iniciar el intervalo de actualización del token
    static startTokenRefreshInterval() {
        if (!TokenServiceRefresh.refreshInterval) {
            TokenServiceRefresh.refreshInterval = setInterval(() => {
                TokenServiceRefresh.refreshTokenIfNeeded();
            }, TokenServiceRefresh.refreshIntervalTime);
        }
    }

    // Método para detener el intervalo de actualización del token
    static stopTokenRefreshInterval() {
        clearInterval(TokenServiceRefresh.refreshInterval);
        TokenServiceRefresh.refreshInterval = null;
        console.log("Refresh interval stopped.");
    }

    // Método para verificar y refrescar el token si es necesario
    static refreshTokenIfNeeded() {
        // console.log("llamando al comienzo del refreshTokenIfNeeded");
        const lastActivityTime = new Date(localStorage.getItem("lastActivityTimeTokenCreate"));
        // console.log(lastActivityTime)
        const currentTime = new Date();
        // console.log(currentTime)
        // console.log("Resultado de diferencia: ", (lastActivityTime - currentTime));
        // console.log("Intervalo global: ", TokenServiceRefresh.refreshIntervalTime);

        if (lastActivityTime && (lastActivityTime - currentTime <= TokenServiceRefresh.refreshIntervalTime)) {
            // console.log("Si cumple con la validacion");
            TokenServiceRefresh.refreshToken();
        }
    }

    // Método para actualizar el token
    static refreshToken() {
        console.log("Calling refreshToken...");
        // console.log("Token:", TokenServiceRefresh.token);
        // console.log("Expiration Time:", TokenServiceRefresh.expirationTime);
        axios
            .get(process.env.REACT_APP_SERVER + "/actualizartoken", {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${TokenServiceRefresh.token}` },
                withCredentials: true
            }).then((res) => {
                localStorage.setItem('tkn', res.data.nuevotoken);
                TokenServiceRefresh.updateExpirationTime();
                console.log("Token actualizado.");
                localStorage.setItem('lastActivityTimeTokenCreate', new Date());
            }).catch(error => {
                console.error("Error en la solicitud de actualización del token:", error);
            });
    }

    // Método para actualizar el tiempo de expiración del token
    static updateExpirationTime() {
        const expiration = new Date().getTime() + TokenServiceRefresh.refreshIntervalTime;
        localStorage.setItem('tokenExpiration', expiration);
        TokenServiceRefresh.expirationTime = expiration;
        console.log("Expiration time updated.");
    }
}

export default TokenServiceRefresh;
