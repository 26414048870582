import React, { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const CausalModal = ({ isOpen, onClose, title, childComponent }) => {
    const backdropStyle = 'static';

    const handleClose = () => {
        onClose(); // Llamar a la función onClose para indicar que se ha cerrado el modal
    };

    useEffect(() => {
        console.log("Valor de visibilidad del modal : ", isOpen);
    }, [isOpen]);

    return (
        <Modal
            id='ModalCrearSolicitud'
            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            size='sm'
            zIndex={9999}
            centered

        >
            <ModalHeader toggle={onClose}></ModalHeader>
            <ModalBody>
                {childComponent}
            </ModalBody>
        </Modal >
    );
}

export default CausalModal
