// axiosConfig.js
import axios from 'axios';

// Crear una instancia de Axios sin baseURL
const axiosInstance = axios.create();

// Interceptor para añadir parámetros y encabezados a cada solicitud
axiosInstance.interceptors.request.use((config) => {
    // Añadir timestamp para evitar caché
    config.params = {
        ...config.params, // Mantener cualquier otro parámetro existente
        _t: new Date().getTime(), // Añadir timestamp
    };

    // Añadir encabezados para deshabilitar caché
    config.headers = {
        ...config.headers, // Mantener cualquier otro encabezado existente
        'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
        'Pragma': 'no-cache', // HTTP 1.0.
        'Expires': '0', // Proxies.
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('tkn')}`, // Añadir el token de autorización
    };

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Exportar la instancia configurada
export default axiosInstance;
