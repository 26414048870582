import React, { useEffect } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';

const FormStaticsMapsRecorrido = ({ ultPunto, contractR, handleUltPunto, handleUltRecorrido, handleContrato, onSubmit }) => {

  return (
    <>
      <Container className="d-flex justify-content-center px-0"> {/* Centra el contenido horizontalmente */}
        <Row className="w-100"> {/* Ajusta el ancho de la fila */}
          <Col className="d-flex flex-column align-items-center" style={{ paddingRight: '40px' }}> {/* Centra los elementos verticalmente */}
            <FormGroup className="w-100"> {/* Asegura ancho completo dentro de la columna */}
              <Label for="input1">Contrato inicial</Label>
              <Input
                type="text"
                id="input1"
                className="w-100"
                placeholder="SERV-123456789"
                style={{ textTransform: 'uppercase' }}
                value={ultPunto}
                onChange={(e) => handleContrato(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="w-100"> {/* Asegura ancho completo dentro de la columna */}
              <Label for="input1">Contrato último punto</Label>
              <Input
                type="text"
                id="input1"
                className="w-100"
                placeholder="SERV-123456789"
                style={{ textTransform: 'uppercase' }}
                value={ultPunto}
                onChange={(e) => handleUltPunto(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="w-100 mt-3"> {/* Añade margen superior para separación */}
              <Label for="input2">Contrato recorrido</Label>
              <Input
                type="text"
                id="input2"
                className="w-100"
                placeholder="SERV-123456789"
                style={{ textTransform: 'uppercase' }}
                value={contractR}
                onChange={(e) => handleUltRecorrido(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </Container>

      <Container className="mt-4"> {/* Contenedor para el botón */}
        <Row>
          <Col className="d-flex justify-content-center"> {/* Centra el botón horizontalmente */}
            <Button onClick={onSubmit}> {/* Botón que dispara la función de submit */}
              Cargar informe
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FormStaticsMapsRecorrido;
