import React from "react";
import SettingsModal from "../components/modals/settings";
import ReporteContrato from "../Modulos/ReporteContrato";
import TokenServiceRefresh from '../tokenServiceRefresh';
import axios from "../utils/axiosConfig";

const server = localStorage.getItem("server");
const userName = localStorage.getItem("username");
var miStorage = window.localStorage

class Barra extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.state = {
      openModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ openModal: true });
  }

  closeModal() {
    this.setState({ openModal: false });
  }
  // METODO PARA DESLOGUEARSE DEL SISTEMA
  logout() {
    axios
      .get(process.env.REACT_APP_SERVER + "/logout", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((res) => {
        localStorage.removeItem("tkn");
        localStorage.removeItem("username");
        localStorage.removeItem("companyId");
        localStorage.removeItem("server");
        localStorage.removeItem('isRefreshIntervalActive');
        localStorage.removeItem("username");
        localStorage.removeItem("companyId");
        localStorage.removeItem("server");
        localStorage.removeItem("project");
        window.location = "/";
      });
  }
  render() {
    return (
      <>
        <nav
          className="layout-navbar container-fluid navbar navbar-expand-xl  align-items-center bg-navbar-theme bg-transparent"
          id="layout-navbar"
        >
          <a href="/trafico" className="app-brand-link">
            <img alt="" className="style21" src="./boxes.png" height="50" />
            <span className="LabelLogo">INFOCARGA</span>
          </a>
          <div
            className="navbar-nav-right d-flex align-items-center"
            id="navbar-collapse"
          >
            <div className="LabelBarra">
              <label>
                Sistema de Información de Seguridad para la Cadena Logística
              </label>
            </div>
            <ul className="navbar-nav flex-row align-items-center ms-auto" >
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  className="nav-link dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                >
                  <div className="avatar avatar-online" id="avtarIcon">
                    <img
                      src="../assets/img/avatars/1.png"
                      alt=""
                      className="w-px-40 h-auto rounded-circle"
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online">
                            <img
                              src="../assets/img/avatars/1.png"
                              alt=""
                              className="w-px-40 h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-semibold d-block">
                            {userName ? userName : "John Doe"}
                          </span>
                          <small className="text-muted">Admin</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="bx bx-user me-2"></i>
                      <span className="align-middle">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <span
                      onClick={this.openModal}
                      data-bs-target="#ModalSettings"
                      data-bs-toggle="modal"
                    >
                      <a className="dropdown-item" href="#">
                        <i className="bx bx-cog me-2"></i>
                        <span
                          className="align-middle"
                          data-bs-target="#ModalSettings"
                        >
                          Settings
                        </span>
                      </a>
                    </span>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <span className="d-flex align-items-center align-middle">
                        <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                        <span className="flex-grow-1 align-middle">
                          Billing
                        </span>
                        <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                          4
                        </span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={() => this.logout()}>
                      <i className="bx bx-power-off me-2"></i>
                      <span className="align-middle">Log Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className="modal fade"
          id="settingsModal"
          data-bs-backdrop="static"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <p>Set</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <SettingsModal
                  isOpen={this.state.openModal}
                  onClose={this.closeModal}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalSettings"
          data-bs-backdrop="static"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content" id="ModalSettings">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Base de datos: {server === "Prod" ? "Producción" : "Pruebas"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <SettingsModal
                    isOpen={this.state.openModal}
                    onClose={this.closeModal}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Barra;
