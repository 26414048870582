import React, { useEffect, useState, useRef } from "react";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import $ from 'jquery';
import '../Pages/Css/trafico.css'
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import { FaRoute } from "@react-icons/all-files/fa/FaRoute";
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { motion } from 'framer-motion';
import axios from "../utils/axiosConfig";
var miStorage = window.localStorage;
const Modal = ({ isOpen, onClose, contratoVar, aleatorio, children }) => {
  let directionsService;
  const google = window.google;
  const [listaMensajes, setListaMensajes] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [contratoseleccionado, setContratoseleccionado] = useState(null);
  const [markers, setMarkers] = useState([]);
  const markersRef = useRef([]);
  const polylineRef = useRef([]);
  const infoWindowRef = useRef(null);
  const mapRef = useRef(null);
  const intervalRef = useRef(null);
  const [switchValue, setSwitchValue] = useState(false);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);

  const isIntervalActive = () => {
    return intervalRef.current !== null;
  };

  useEffect(() => {
    if (isOpen) {
      console.log("Contrato reporte abierto: ", contratoVar);
      $("#success-alert").hide();
      $("#danger-alert").hide();
      setListaMensajes([]);
      setInfoWindows([]);
      setSelectedMarkerIndex(null);
      setMarkers([]);
      RenderMap();
      setContratoseleccionado(contratoVar);
      ListReports(contratoVar, 0, 1);

      if (contratoVar.trayecto !== 0) {
        GetPolyline();
      }

      // Solo activa el seguimiento si el switch está activado
      if (switchValue) {
        handleVerSeguimientoClick();
      }
    } else {
      clearInterval(intervalRef.current); // Limpia el intervalo al cerrar el modal
      intervalRef.current = null;
    }

    return () => {
      // Limpieza al desmontar el componente
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [isOpen, switchValue]);


  const RenderMap = () => {
    const coords = { lat: 10.992716649650994, lng: -74.80259436365039 };
    try {
      const el = document.getElementById('map');
      if (el) {
        mapRef.current = new google.maps.Map(el, {
          zoom: 16,
          center: coords,
        });
        return mapRef.current;
      } else {
        return null;
      }
    } catch (error) {
      window.history.go(0);
    }
  };

  const GetPolyline = () => {
    try {
      let data = { "id": contratoVar.trayecto };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/getpolylinetrayecto', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success) {
            let rutacompleta = response.data.data[0].Polyline.split("||||");
            rutacompleta.forEach((ruta) => {
              const decodedPath = google.maps.geometry.encoding.decodePath(ruta);
              const polyline = new google.maps.Polyline({
                path: decodedPath,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 0.4,
                strokeWeight: 6,
              });
              polyline.setMap(mapRef.current);
            });
          }
        }).catch(console.error);
    } catch (error) {
      console.error(error);
    }
  };

  const ListReports = (contrato, all, op) => {
    const imagen = contrato.Locked === 0 ? 'Apertura' : 'Cierre';
    document.getElementById("contenedorestadodiv").style.background = contrato.Locked === 0 ? '#D21F1F' : '#058315';
    document.getElementById("contenedorestado").innerHTML = imagen;

    try {
      let data = {
        "fechainicio": contrato.fecha,
        "fechafin": contrato.fechafin,
        "device": contrato.FKLokDeviceID,
        "utcMinutos": 5,
        "allreport": all,
        "tipo": contrato.Active,
        "tipoequipo": contrato.FKLokTipoEquipo,
      };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportesdevice', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success) {
            const prevMarkersList = markersRef.current;
            document.getElementById("contenedortiempo").innerHTML = getDateDifferenceText(formatFecha(response.data.data[0].PositionTime));
            if (contrato.trayecto !== 0) {
              cumplimientoRuta(contrato, response.data.data[0]);
            }
            setMarkers(prevMarkersList);
            setListaMensajes(response.data.data);
            rutaenMapa(response.data.data, op);
          }
        }).catch(console.error);
    } catch (error) {
      console.error(error);
    }
  };

  function cumplimientoRuta(contrato, mensaje) {
    directionsService = new google.maps.DirectionsService();
    calcAddress(directionsService, contrato, mensaje);
  }

  function calcAddress(directionsService, contrato, mensaje) {

    directionsService.route(
      {
        origin: {
          query: contrato.Origen,
        },
        destination: {
          query: mensaje.latitude + "," + mensaje.longitude,
        },
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {

        if (status === 'OK') {
          const ruta = response.routes[0].legs;
          var distancia = 0;
          for (var i = 0; i < ruta.length; i++) {
            distancia += ruta[i].distance.value;
          }
          document.getElementById("informacioneventos").innerHTML = "<b>Distancia ruta:</b> " + (contrato.DistanciaCompleta / 1000).toFixed() + "Km - " + "<b>Distancia faltante:</b> " + ((contrato.DistanciaCompleta - distancia) / 1000).toFixed() + "Km";
          var distanciaporcentaje = (distancia / contrato.DistanciaCompleta) * 100;
          document.getElementById("contenedordistancia").innerHTML = distanciaporcentaje.toFixed() + "%";
        } else {
        }
      }
    );
  }

  const rutaenMapa = (lista, op) => {

    const promises = lista.filter(objeto => objeto.latitude !== 0 && objeto.longitude !== 0).map((objeto) => {
      return new Promise((resolve) => {
        resolve({ lat: objeto.latitude, lng: objeto.longitude, id: objeto.ID, html: objeto.HTML, ubicacion: objeto.Ciudad, icon: objeto.IconLocked });
      });

    });

    Promise.all(promises)
      .then((flightPlanCoordinates) => {
        const markersList = markersRef.current;
        markersList.forEach(({ marker }) => {
          marker.setMap(null);
          /*if(polylineRef.current){
              polylineRef.current.setMap(null);
          }*/
        });

        /*const flightPath = new google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: "#2BA914",
            strokeOpacity: 1.0,
            strokeWeight: 3,
        });
        flightPath.setMap(mapRef.current);
        polylineRef.current = flightPath;*/


        // Calcula y muestra las flechas de dirección entre todos los marcadores
        for (let i = 0; i < flightPlanCoordinates.length - 1; i++) {
          const directionArrow = new google.maps.Polyline({
            path: [flightPlanCoordinates[i + 1], flightPlanCoordinates[i]],
            geodesic: true,
            strokeColor: "#88A0CF",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                  scale: 1,
                  strokeColor: "#082357"
                },
                offset: "50%",
              },
            ],
            map: mapRef.current,
          });
        }

        // Limpiar la lista de marcadores anteriores
        markersRef.current = [];

        const markerslist = flightPlanCoordinates.map((coordinate, index) => {
          var iconurl = './truckclose.png';
          if (coordinate.icon.includes("opened")) {
            iconurl = './truckopen.png';
          }
          const marker = new google.maps.Marker({
            position: coordinate,
            map: mapRef.current,
            /*label: {
                text:(index + 1).toString(),
                fontSize:'9px'
            },*/
            id: coordinate.id,
            ubicacion: coordinate.ubicacion,
            icon: {
              url: iconurl,
              scaledSize: new google.maps.Size(30, 30)
            },
          });

          const markerInfoWindow = new google.maps.InfoWindow({
            content: coordinate.html,
          });

          marker.addListener("click", () => {
            if (infoWindowRef.current) {
              infoWindowRef.current.close();
            }
            setSelectedMarker(marker);
            markerInfoWindow.open(mapRef.current, marker);
            infoWindowRef.current = markerInfoWindow;

          });

          return { marker, markerInfoWindow };

        });

        markersRef.current = markerslist;
        // Ajusta los límites del mapa para mostrar todos los marcadores

        if (op == 1) {
          const bounds = new google.maps.LatLngBounds();
          markerslist.forEach(({ marker }) => bounds.extend(marker.getPosition()));
          mapRef.current.fitBounds(bounds);
        } else {
          const switchElement = document.getElementById('autocentrado');
          if (switchElement.checked) {
            const firstMarker = markerslist[0].marker;
            const firstMarkerPosition = firstMarker.getPosition();
            mapRef.current.setCenter(firstMarkerPosition);
          }

        }
        setMarkers(markerslist);
        setInfoWindows(markerslist.map(({ markerInfoWindow }) => markerInfoWindow));
      })
      .catch((error) => {
        console.error(error);

      });
  }

  const handleMarkerClick = (markerId) => {
    markers.forEach(({ marker, markerInfoWindow }) => {
      markerInfoWindow.close();
      if (marker.id === markerId) {
        markerInfoWindow.open(mapRef.current, marker);
        infoWindowRef.current = markerInfoWindow;
        setSelectedMarkerIndex(markerId);
        setSelectedMarker(marker);
        mapRef.current.panTo(marker.getPosition()); // Centrar el mapa en el marcador
        mapRef.current.setZoom(16);
      }
    });

  };

  const handleTableRowClick = (markerIndex) => {
    console.log("entro -> " + markerIndex + " total=" + listaMensajes.length);
    const objeto = listaMensajes[markerIndex];
    handleMarkerClick(objeto.ID);
  };

  const handleSwitchChange = (event) => {
    const newValue = event.target.checked;
    setSwitchValue(newValue);

    if (newValue) {
      if (!isIntervalActive()) {
        handleVerSeguimientoClick();
      }
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleVerUltPuntoClick = () => {
    try {
      let obj = listaMensajes[0];
      window.open('https://www.google.com/maps/?q=' + obj.latitude + ',' + obj.longitude + '&z=14', '_blank');
    } catch (error) {
      console.error("Error al abrir el último punto en Google Maps:", error);
    }
  };

  const handleSetUltPunto = () => {
    try {
      let data = {
        "lat": selectedMarker.position.lat(),
        "lng": selectedMarker.position.lng(),
        "id": selectedMarker.id,
        "contrato": contratoseleccionado.ContractID
      };
      const json = JSON.stringify(data);
      axio.post(process.env.REACT_APP_SERVER + '/operaciones/setultimopunto', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success.rowsAffected.length === 1) {
            $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#success-alert").slideUp(2000);
            });
          } else {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
          }
        }).catch(error => {
          $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
        });
    } catch (error) {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    }
  };

  const handleTodoRecorridoClick = () => {
    try {
      ListReports(contratoseleccionado, 1, 1); // Llamar a ListReports con 'all' y 'op' como 1 para mostrar todos los reportes
    } catch (error) {
      console.error("Error al manejar el recorrido completo:", error);
    }
  };

  const handleSetReporteAutomatico = () => {
    try {
      let data = {
        "ubicacion": selectedMarker.ubicacion, // Se asume que 'selectedMarker' está definido y tiene 'ubicacion'
        "contrato": contratoseleccionado.ContractID // Se asume que 'contratoseleccionado' está definido y tiene 'ContractID'
      };

      const json = JSON.stringify(data); // Convertir los datos a JSON para enviar al servidor

      // Llamada a la API para establecer un reporte automático
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/setreporteautomatico', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          console.log(response);
          if (response.data.success === true) {
            $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#success-alert").slideUp(2000);
            });
          } else {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
          }
        }).catch(error => {
          $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
        });

    } catch (error) {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    }
  };


  const handleVerSeguimientoClick = () => {
    // Verifica si ya existe un intervalo configurado antes de establecer uno nuevo
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Limpia el intervalo existente
    }

    try {
      // Establece un nuevo intervalo para realizar la solicitud de seguimiento en tiempo real
      intervalRef.current = setInterval(() => {
        console.log("Intervalo activo para el contrato = " + contratoVar.ContractID);
        ListReports(contratoVar, 0, 0); // Llama a la función para obtener los reportes
      }, 1000 * 5); // Cada 5 segundos
    } catch (error) {
      console.error("Error al manejar el seguimiento:", error); // Manejo de errores
    }
  };


  const formatFecha = (fecha) => {
    const isoString = new Date(fecha).toISOString();
    const year = isoString.slice(0, 4);
    const month = isoString.slice(5, 7);
    const day = isoString.slice(8, 10);
    const hours = isoString.slice(11, 13);
    const minutes = isoString.slice(14, 16);
    const seconds = isoString.slice(17, 19);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const getDateDifferenceText = (startDate) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date();
    if (isNaN(startDateObj) || isNaN(endDateObj)) {
      return 'Invalid date format';
    }
    const timeDifference = endDateObj.getTime() - startDateObj.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const timeText = [];
    if (days > 0) timeText.push(`${days}d`);
    if (hours > 0) timeText.push(`${hours % 24}h`);
    if (minutes > 0) timeText.push(`${minutes % 60}m`);
    if (seconds > 0) timeText.push(`${seconds % 60}s`);
    return timeText.join(', ');
  };

  if (!isOpen) return null;
  return (
    <>
      <MDBRow style={{ marginBottom: '10px' }}>
        <MDBCol md='6'>
          <nav class="navbar navbar-expand-lg custom-navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#" style={{ color: '#fff' }}>MENU</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNavDropdown" style={{ display: 'flex' }}>
                <ul class="navbar-nav">
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Configuración
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li onClick={handleVerUltPuntoClick}><a class="dropdown-item">Ver ultimo punto</a></li>
                      <li onClick={handleSetUltPunto}><a class="dropdown-item">Setear punto</a></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Reportes
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li  ><a class="dropdown-item">Visualizar ultimos (50)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (100)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (200)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (500)</a></li>
                      <li onClick={handleTodoRecorridoClick} ><a class="dropdown-item">Visualizar todos los reportes</a></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Trafico
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li onClick={handleSetReporteAutomatico} ><a class="dropdown-item">Generar reporte automatico</a></li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </MDBCol>

        <MDBCol md='6'>
          <MDBRow>
            <MDBCol md='4'>
              <div style={{ fontFamily: 'Courier New, sans-serif', fontSize: '16px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}><FaRoute size={18} className='icono rounded' /> % Completado<br /><span id="contenedordistancia" /></div>
            </MDBCol>
            <MDBCol md='4'>
              <div style={{ fontFamily: 'Courier New, sans-serif', fontSize: '16px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}><FaClock size={18} className='icono' /> Ult Reporte<br /><span id="contenedortiempo" /></div>
            </MDBCol>
            <MDBCol md='4'>
              <div id="contenedorestadodiv" style={{ fontFamily: 'Courier New, sans-serif', fontSize: '16px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}><FaLock size={18} className='icono' /> Ult Estado<br /><div id="contenedorestado"></div></div>
            </MDBCol>
          </MDBRow>
        </MDBCol>

      </MDBRow>
      <MDBRow>
        <MDBCol md='6'>
          <div id="map" style={{ border: '1px solid #c4c4c4' }} />

        </MDBCol>
        <MDBCol md='6'>
          <MDBRow>
            <MDBCol md='12'>
              <div style={{ overflow: 'hidden', width: '100%' }}>
                <motion.div
                  id="informacioneventos"
                  className="scrolling-text"
                  initial={{ x: '100%' }}
                  animate={{ x: '-100%' }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: 'linear',
                  }}
                >
                </motion.div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>

            <MDBCol md='6'>
              <div className="form-check form-switch custom-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="mySwitch" checked={switchValue} onChange={handleSwitchChange} />
                <label className="form-check-label" for="mySwitch">Seguimiento tiempo real</label>
              </div>
            </MDBCol>
            <MDBCol md='6'>
              <div className="form-check form-switch custom-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="autocentrado" />
                <label className="form-check-label" for="autocentrado">Autocentrado</label>
              </div>
            </MDBCol>
          </MDBRow>

          <div className="container containerReporteSeguimiento" id="contenedor" style={{ fontSize: '10px' }}>

            <div className="row py-1">
              <div className="col">
                <b>DISPOSITIVO:</b>
                <div id="dispositivo_seg">
                </div>
              </div>

              <div className="col">
                <b>CONTRATO:</b>
                <div id="contrato_seg">
                </div>
              </div>
            </div>
            <div className="row py-1">
              <div className="col">
                <b>CLIENTE:</b>
                <div id="cliente_seg">
                </div>
              </div>
              <div className="col">
                <b>RUTA:</b>
                <div id="ruta_seg">
                </div>
              </div>
            </div>

            <div className="row py-1 containerReporteContratoSeguimiento" style={{ maxHeight: "400px", overflowY: "auto" }}>
              <table className="tableReporteContratoSeguimiento">
                <thead>
                  <tr>
                    <th className="sticky-header">Fecha</th>
                    <th className="sticky-header">Lugar</th>
                    <th className="sticky-header" style={{ width: "32px" }}>Bat</th>
                    <th className="sticky-header" style={{ width: "32px" }}>Gps</th>
                    <th className="sticky-header" style={{ width: "62px" }}>Vel</th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                    <th className="sticky-header" style={{ width: "24px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {listaMensajes.map((objeto, index) => (
                    <tr key={objeto.ID} className={objeto.latitude === 0 ? 'background-class' : ''} onClick={() => handleTableRowClick(index)}>
                      <td>{formatFecha(objeto.PositionTime)}</td>
                      <td>{objeto.latitude === 0 ? <b>SIN GPS</b> : objeto.Ciudad}</td>
                      <td>{objeto.Battery}</td>
                      <td>{objeto.SatelliteCount}</td>
                      <td>{objeto.Speed}</td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconSatelite.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconMoving.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconLocked.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconDesvio.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconBack.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconAlejado.substring(1)}`} width="20" height="20" /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </MDBCol>

      </MDBRow>
      <div className="alert alert-success" id="success-alert">
        <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
      </div>

      <div className="alert alert-danger" id="danger-alert">
        <strong>Error! </strong> Se ha presentado un error, valide la información.
      </div>
    </>
  );
};

export default Modal;
