// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Trafico from './Pages/Trafico';
import Trayecto from './Pages/Trayecto';
import Login from './Pages/Login';
import Historico from './Pages/Historico';
import Reportes from './Pages/Reportes';
import Roles from './Pages/Roles';
import Usuarios from './Pages/Usuarios';
import ControlDevices from './Pages/ControlDevices';
import ControlDevice from './Pages/ControlDevice';
import AzureUpload from './Pages/AzureUpload';
import AuthenticatedApp from "./AuthenticatedApp";
import TokenServiceRefresh from "./tokenServiceRefresh";
import Solicitudes from "./Pages/Solicitudes";

function App() {
    // useEffect(() => {
    //     // Start refresh interval when App component mounts
    //     TokenServiceRefresh.startRefreshInterval();

    //     // Cleanup function to stop refresh interval on unmount
    //     return () => {
    //         TokenServiceRefresh.stopRefreshInterval();
    //     };
    // }, []);

    return (
        <Router>
            <AuthenticatedApp>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/trafico" element={<Trafico />} />
                    <Route path="/trayecto" element={<Trayecto />} />
                    <Route path="/historico" element={<Historico />} />
                    <Route path="/reportes" element={<Reportes />} />
                    <Route path="/roles" element={<Roles />} />
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/controldevices" element={<ControlDevices />} />
                    <Route path="/controldevice" element={<ControlDevice />} />
                    <Route path="/azureupload" element={<AzureUpload />} />
                    <Route path="/solicitudes" element={<Solicitudes />} />
                </Routes>
            </AuthenticatedApp>
        </Router>
    );
}

export default App;
