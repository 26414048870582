// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ToggleSwitch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    border-radius: 17px;
    background-color: #ccc;
    transition: background-color 0.3s;
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 2px;
}

.ToggleSwitch.active {
    background-color: #006699;
    /* Color verde para indicar activado */
}

.ToggleSwitchInner {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s;
}

.ToggleSwitch.active .ToggleSwitchInner {
    transform: translateX(10px);
    /* Posición del botón deslizante cuando está activo */
}

.ToggleSwitchHandle {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.ToggleSwitch.active .ToggleSwitchHandle {
    transform: translateX(26px);
    /* Movimiento del botón deslizante cuando está activo */
}`, "",{"version":3,"sources":["webpack://./src/Pages/Css/switchButoon.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,iCAAiC;IACjC,eAAe;IACf,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,qDAAqD;AACzD;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,wCAAwC;IACxC,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,uDAAuD;AAC3D","sourcesContent":["\n.ToggleSwitch {\n    position: relative;\n    display: inline-block;\n    width: 30px;\n    height: 20px;\n    border-radius: 17px;\n    background-color: #ccc;\n    transition: background-color 0.3s;\n    cursor: pointer;\n    border: none;\n    outline: none;\n    margin-top: 2px;\n}\n\n.ToggleSwitch.active {\n    background-color: #006699;\n    /* Color verde para indicar activado */\n}\n\n.ToggleSwitchInner {\n    position: absolute;\n    top: 3px;\n    left: 3px;\n    width: 13px;\n    height: 13px;\n    border-radius: 50%;\n    background-color: white;\n    transition: transform 0.3s;\n}\n\n.ToggleSwitch.active .ToggleSwitchInner {\n    transform: translateX(10px);\n    /* Posición del botón deslizante cuando está activo */\n}\n\n.ToggleSwitchHandle {\n    top: 3px;\n    left: 3px;\n    width: 28px;\n    height: 28px;\n    border-radius: 50%;\n    background-color: #fff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n    transition: transform 0.3s;\n}\n\n.ToggleSwitch.active .ToggleSwitchHandle {\n    transform: translateX(26px);\n    /* Movimiento del botón deslizante cuando está activo */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
