import React, { useEffect, useRef, useState } from 'react';
import ReporteTabla from '../Modulos/ReporteTabla'
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { FaExpand } from '@react-icons/all-files/fa/FaExpand';
import { FaCompress } from '@react-icons/all-files/fa/FaCompress';
import './Css/reportes.css'
import axios from '../utils/axiosConfig';

var miStorage = window.localStorage;
export default function Reportes() {
  const [contador, setContador] = useState(0);
  const [data, setData] = useState([]);
  const [idPowerBiSelected, setIdPowerBiSelected] = useState();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const cardBodyRef = useRef(null);

  function actualizarTabla() {
    setContador(contador + 1);
  }

  const handleRowClicked = (row) => {
    console.log("Fila seleccionada: ", row);
    setIdPowerBiSelected(row.Id_PowerBI);
    actualizarTabla();

  }
  const toggleFullscreen = () => {
    const element = cardBodyRef.current;

    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);
  };

  const columns = [
    {
      name: "Nombre Reporte",
      selector: "NombreReporte",
      sortable: true,
    },

  ];

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER + '/operaciones/getreportesbi', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          setData(res.data.data);
          console.log('Valor de Resportes', res.data.data);
        }
      }).catch(error => {
        console.log(error);
      });

  }, []);


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">


          <div className="layout-page">
            <Barra />
            <div className="content-wrapper">

              <div className="container-fluid flex-grow-1 container-p-y">
                <MDBRow>
                  <MDBCol md='8'>
                    <h4 className="fw-bold mb-4">Reportes</h4>
                  </MDBCol>
                </MDBRow>

                <div class="row">
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-12 mb-3">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title"><i className="bx bx-file"></i> Eventos</h5>
                            <p class="card-text">Reporte de eventos del proyecto</p>
                            <DataTableExtensions
                              {...tableData} export={false} print={false}
                            >
                              <DataTable
                                defaultSortField="id"
                                defaultSortAsc={false}
                                highlightOnHover
                                export={false}
                                onRowClicked={handleRowClicked}
                                customStyles={{
                                  rows: {
                                    cursor: 'pointer'
                                  }
                                }}
                              />

                            </DataTableExtensions>
                            {/* <button onClick={actualizarTabla} class="btn btn-secondary"><i class='bx bx-loader bx-spin-hover'></i> Cargar reportes</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9" >
                    {/* <div class="card" > */}
                      {/* <div class="card-body" > */}
                      {/* Generar boton para maximixar el grafico powerBI */}
                      <ReporteTabla onActualizarTabla={contador} idPowerBi={idPowerBiSelected} />
                      {/* </div> */}
                      {/* </div> */}
                    
                  </div>
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">
                    ©, Producto realizado por <a href="https://logiseguridad.com" target="_blank" className="footer-link fw-bolder"> Logiseguridad Ltda</a>
                  </div>

                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
}
