import React from 'react';
import $ from 'jquery';
import Barra from '../Modulos/Barra';
import './Css/trayecto.css';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { FaSort } from "@react-icons/all-files/fa/FaSort";
import { FaSave } from "@react-icons/all-files/fa/FaSave";
import { FaRoute } from "@react-icons/all-files/fa/FaRoute";
import { FaLocationArrow } from "@react-icons/all-files/fa/FaLocationArrow";
import {Form} from 'react-bootstrap';
import axios from '../utils/axiosConfig';

let map=null;
let vMarkerComprobar=null;
let vMarkerOrigen=null;
let vMarkerDestino=null;
var wayPointsArray=[];
let directionsService;
let directionsRenderer;
let puntosadicionales=[];
let listadowaypoints="";
let listadopoly="";
var poliArray=[];
const google = window.google;
var miStorage = window.localStorage;
class Trayecto extends React.Component {
  constructor(props) {
    super(props);
    //this.renderMap = this.renderMap.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.checkThird = this.checkThird.bind(this);
    this.checkThird2 = this.checkThird2.bind(this);
    this.checkThird4 = this.checkThird4.bind(this);
    this.state = {
        origin: null,
        destination: null,
        third: '',
        response: null,
        distancia:0,
        listRutas: [],
        rutaseleccionada:-1
    };
    this.asignarOrigen = this.asignarOrigen.bind(this);
    this.asignarOrigenEdit = this.asignarOrigenEdit.bind(this);
    this.asignarDestino = this.asignarDestino.bind(this);
    this.asignarPuntosAdicionales = this.asignarPuntosAdicionales.bind(this);
    this.agregarWaypoints = this.agregarWaypoints.bind(this);
    this.calcularDistancia = this.calcularDistancia.bind(this);
    this.ListarRutas();

  }



  componentDidMount() {
      this.renderMap();

  }

  componentDidUpdate(){

  }
  // METODO QUE RENDERIZA EL MAPA DE GOOGLE
  renderMap() {
    const coords = { lat: 10.992716649650994, lng: -74.80259436365039 };
    try{
      const el = document.getElementById('map');

      if (el) {
          map = new google.maps.Map(el, {
            zoom: 16,
            center: {
              lat: coords.lat,
              lng: coords.lng,
            },
          });

          vMarkerComprobar = new google.maps.Marker({
              position: map.getCenter(),
              map: map,
              icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
              draggable: true
          });

          var input = document.getElementById('pac-input');
          var searchBox = new google.maps.places.SearchBox(input);
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });

          map.addListener("rightclick", (mapsMouseEvent) => {
            // Close the current InfoWindow.
            console.log(mapsMouseEvent.latLng.toJSON())
          });

          searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }
            var bounds = new google.maps.LatLngBounds();

            places.some(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
              return true;
            });
          map.fitBounds(bounds);
        });

          return map;
        } else {
          return null;
        }
    }catch(error){
        window.history.go(0);
    }

  }

  // METODO PARA ASIGNAR EL MARKER DEL ORIGEN
  asignarOrigen(event){
     if(vMarkerOrigen == null){
         document.getElementById("submitO").style.backgroundColor= '#399925';
         document.getElementById("submitO").style.color= '#fff';
         vMarkerOrigen = new google.maps.Marker({
           position: map.getCenter(),
           map: map,
           icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
           draggable: true
         });
         vMarkerOrigen.addListener('dragend',function(event) {
            const d = new Trayecto();
            d.asignarPuntosAdicionales();
         });
     }else{
        alert("Ya el marker del origen se encuentra en el mapa.");
     }
  }

  // METODO PARA ACTUALIZAR EL MARKER DEL ORIGEN
  asignarOrigenEdit(latlng){
     if(vMarkerOrigen == null){
         document.getElementById("submitO").style.backgroundColor= '#399925';
         document.getElementById("submitO").style.color= '#fff';
         var loc = latlng.split(",")
         var lat = parseFloat(loc[0])
         var lng = parseFloat(loc[1])
         vMarkerOrigen = new google.maps.Marker({
           position: new google.maps.LatLng(lat,lng),
           map: map,
           icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
           draggable: true
         });
         vMarkerOrigen.addListener('dragend',function(event) {
            const d = new Trayecto();
            d.asignarPuntosAdicionales();
         });
     }else{
        alert("Ya el marker del origen se encuentra en el mapa.");
     }
  }

  // METODO PARA ACTUALIZAR EL MARKER DEL DESTINO
  asignarDestinoEdit(latlng){
     if(vMarkerDestino == null){
         document.getElementById("submitD").style.backgroundColor= '#CC1F1F';
         document.getElementById("submitD").style.color= '#fff';
         var loc = latlng.split(",")
         var lat = parseFloat(loc[0])
         var lng = parseFloat(loc[1])
         vMarkerDestino = new google.maps.Marker({
           position: new google.maps.LatLng(lat,lng),
           map: map,
           icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
           draggable: true
         });
     }else{
        alert("Ya el marker del destino se encuentra en el mapa.");
     }
  }

  // METODO PARA ASIGNAR EL MARKER DEL DESTINO
  asignarDestino(event){
     if(vMarkerDestino == null){
         document.getElementById("submitD").style.backgroundColor= '#CC1F1F';
         document.getElementById("submitD").style.color= '#fff';
         vMarkerDestino = new google.maps.Marker({
           position: map.getCenter(),
           map: map,
           icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
           draggable: true
         });
     }else{
        alert("Ya el marker del destino se encuentra en el mapa.");
     }
  }

  // METODO PARA AGREGAR WAYPOINTS A LA RUTA
  agregarWaypoints(event){
     document.getElementById("submitW").style.backgroundColor= '#D5D53E';
     document.getElementById("submitW").style.color= '#fff';
     var distancia=this.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),map.getCenter().lat(),map.getCenter().lng());
     var vMarker = new google.maps.Marker({
         position: map.getCenter(),
         map: map,
         icon: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
         draggable: true,
         id: wayPointsArray.length,
         distancia:distancia
     });
     wayPointsArray.push(vMarker);
     vMarker.addListener('dragend',function(event) {
        const d = new Trayecto();
        /*var distancia=d.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),vMarker.getPosition().lat(),vMarker.getPosition().lng());
        vMarker.set("distancia", distancia);*/
        d.asignarPuntosAdicionales();
     });

     vMarker.addListener('rightclick',function(event) {
        const d = new Trayecto();
        vMarker.setMap(null);
        wayPointsArray = wayPointsArray.filter(object => {
          return object.id !== vMarker.get("id");
        });
        d.asignarPuntosAdicionales();
     });
     this.asignarPuntosAdicionales();
  }

  // METODO PARA ACTUALIZAR WAYPOINTS EN LA RUTA
  agregarWaypointsEdit(latlng){
     document.getElementById("submitW").style.backgroundColor= '#D5D53E';
     document.getElementById("submitW").style.color= '#fff';
     var loc = latlng.split(",")
     var lat = parseFloat(loc[0])
     var lng = parseFloat(loc[1])
     var distancia=this.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),lat,lng);
     var vMarker = new google.maps.Marker({
         position: new google.maps.LatLng(lat,lng),
         map: map,
         icon: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
         draggable: true,
         id: wayPointsArray.length,
         distancia:distancia
     });
     wayPointsArray.push(vMarker);
     vMarker.addListener('dragend',function(event) {
        const d = new Trayecto();
        /*var distancia=d.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),vMarker.getPosition().lat(),vMarker.getPosition().lng());
        vMarker.set("distancia", distancia);*/
        d.asignarPuntosAdicionales();
     });

     vMarker.addListener('rightclick',function(event) {
        const d = new Trayecto();
        vMarker.setMap(null);
        wayPointsArray = wayPointsArray.filter(object => {
          return object.id !== vMarker.get("id");
        });
        d.asignarPuntosAdicionales();
     });
     this.asignarPuntosAdicionales();
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  // METODO PARA PINTAR TABLA DE WAYPOINTS
  async asignarPuntosAdicionales(){

      var tabla="";
      puntosadicionales=[];
      listadowaypoints="";
      for(var i=0;i< wayPointsArray.length;i++){
          puntosadicionales.push({
              location: wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng(),
              stopover: true
          });
          var distancia=this.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),wayPointsArray[i].getPosition().lat(),wayPointsArray[i].getPosition().lng());
          wayPointsArray[i].set("distancia", distancia);
          tabla+="<tr><td>"+(i+1)+"</td><td>"+wayPointsArray[i].getPosition().lat()+"</td><td>"+wayPointsArray[i].getPosition().lng()+"</td><td>"+wayPointsArray[i].distancia.toFixed(2)+"</td></tr>";

          if(i==0){
              listadowaypoints+=wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng()+","+wayPointsArray[i].distancia.toFixed(2);
          }else{
              if(i==wayPointsArray.length-1){
                  listadowaypoints+="|"+wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng()+","+wayPointsArray[i].distancia.toFixed(2);
              }else{
                  listadowaypoints+="|"+wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng()+","+wayPointsArray[i].distancia.toFixed(2);
              }

          }
          console.log(listadowaypoints);

      }
      document.getElementById("tablaway").innerHTML=tabla;
  }

  // METODO PARA OBTENER POLILINEA
  async asignarPolilinea(){
      listadopoly="";
      poliArray=[];
      var cont=0;
      if(wayPointsArray.length>0){
          for(var i=0;i< wayPointsArray.length;i++){
              var distancia=this.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),wayPointsArray[i].getPosition().lat(),wayPointsArray[i].getPosition().lng());
              wayPointsArray[i].set("distancia", distancia);
              var linea = "";
              if(i==0 && i==wayPointsArray.length-1){
                  listadopoly = await this.getPoly(vMarkerOrigen.getPosition().lat()+","+vMarkerOrigen.getPosition().lng(),wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng());
                  listadopoly +="||||"+ await this.getPoly(wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng(), vMarkerDestino.getPosition().lat()+","+vMarkerDestino.getPosition().lng());
                  cont++;
                  cont++;
                  poliArray.push(1);
                  poliArray.push(1);
              }else if(i==0 && i!=wayPointsArray.length-1){
                  listadopoly = await this.getPoly(vMarkerOrigen.getPosition().lat()+","+vMarkerOrigen.getPosition().lng(),wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng());
                  cont++;
                  poliArray.push(1);
              }else{
                  if(i==wayPointsArray.length-1){
                      listadopoly +="||||"+ await this.getPoly(wayPointsArray[i-1].getPosition().lat()+","+wayPointsArray[i-1].getPosition().lng(), wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng());
                      listadopoly +="||||"+ await this.getPoly(wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng(), vMarkerDestino.getPosition().lat()+","+vMarkerDestino.getPosition().lng());
                      cont++;
                      cont++;
                      poliArray.push(1);
                      poliArray.push(1);
                  }else{
                      listadopoly +="||||"+ await this.getPoly(wayPointsArray[i-1].getPosition().lat()+","+wayPointsArray[i-1].getPosition().lng(), wayPointsArray[i].getPosition().lat()+","+wayPointsArray[i].getPosition().lng());
                      cont++;
                      poliArray.push(1);
                  }
              }
              console.log(listadopoly);
          }
      }else{
          listadopoly = await this.getPoly(vMarkerOrigen.getPosition().lat()+","+vMarkerOrigen.getPosition().lng(),vMarkerDestino.getPosition().lat()+","+vMarkerDestino.getPosition().lng());
          cont++;
          poliArray.push(1);
      }
      console.log("contador: "+cont);
  }

  // METODO PARA CONVERTIR DE GRADOS A RADIANES
  gradosARadianes(grados){
      return grados * Math.PI / 180;
  }

  // METODO PARA CALCULAR DISTANCIA ENTRE DOS POSICIONES
  calcularDistancia(lat1, lng1, lat2, lng2){
      lat1 = this.gradosARadianes(lat1);
      lng1 = this.gradosARadianes(lng1);
      lat2 = this.gradosARadianes(lat2);
      lng2 = this.gradosARadianes(lng2);
      const RADIO_TIERRA_EN_KILOMETROS = 6371;
      let diferenciaEntreLongitudes = (lng2 - lng1);
      let diferenciaEntreLatitudes = (lat2 - lat1);
      let a = Math.pow(Math.sin(diferenciaEntreLatitudes / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(diferenciaEntreLongitudes / 2), 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return RADIO_TIERRA_EN_KILOMETROS * c;
  }

  // METODO PARA CALCULAR LA RUTA
  handleClick(event) {
    this.asignarPuntosAdicionales();
    if (directionsRenderer) {
      directionsRenderer.setMap(null);
    }
    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    //call the function that computes directions
    this.calcAddress(directionsService, directionsRenderer);
    //event.preventDefault();
  }

  // METODO PARA PINTAR LA RUTA EN EL MAPA
  calcAddress(directionsService, directionsRenderer) {

    directionsRenderer.setMap(map);

    directionsService.route(
      {
        origin: {
          query: vMarkerOrigen.getPosition().lat()+","+vMarkerOrigen.getPosition().lng(),
        },
        destination: {
          query: vMarkerDestino.getPosition().lat()+","+vMarkerDestino.getPosition().lng(),
        },
        waypoints: puntosadicionales,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        const ruta=response.routes[0].legs;
        var distancia=0;
        for(var i=0; i< ruta.length;i++){
            distancia+=ruta[i].distance.value;
        }
        if (status === 'OK') {
          this.setState({
            response: response,
            distancia: distancia
          });

          directionsRenderer.setDirections(response);
          this.asignarPolilinea();
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      }
    );
  }

  //METODO PARA COMPROBAR SI ESTA EN LA RUTA
  checkThird() {

    this.handleClick();
  }

  // METODO PARA COMPROBAR CORRECTO LLENADO DEL FORMULARIO
  checkThird2() {
    var descripciontrayecto= document.getElementById('descripcionruta').value;
    var nombreorigen=document.getElementById('origin').value;
    var nombredestino=document.getElementById('destination').value;
    var tolerancia=document.getElementById('tolerancia').value;

    if(descripciontrayecto == "" || nombreorigen == "" || nombredestino == ""  || tolerancia == "" || vMarkerOrigen == null || vMarkerDestino == null){
        alert("Faltan campos por llenar..");
    }else{
        if((wayPointsArray.length+1) == poliArray.length){
            this.GuardarRuta();
        }else{
            alert("Aun no se han cargado las polilineas, intente de nuevo..");
        }
    }

  }

  checkThird4() {

      let data =
      {
          "ID": this.state.rutaseleccionada,
          "latitud": vMarkerComprobar.getPosition().lat(),
          "longitud": vMarkerComprobar.getPosition().lng()
      }
      console.log(data);
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER+'/operaciones/getfind2', json, {
        headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${localStorage.getItem('tkn')}`},
        withCredentials: true})
      .then(res => {
          alert(res.data.data);
      })
  }

  //METODO PARA OBTENER POLILINEA DE ACUERDO A UN ORIGEN Y DESTINO
  async getPoly(origen, destino) {
      let data =
      {
          "origen": origen,
          "destino": destino
      }
      const json = JSON.stringify(data);
      const resp=await axios.post(process.env.REACT_APP_SERVER+'/operaciones/getpoly', json, {
        headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${localStorage.getItem('tkn')}`},
        withCredentials: true});
        if(resp.data.success){
            return resp.data.data;
        }else{
            window.history.go(0);
        }

  }

  //METODO PARA ORDENAR LOS WAYPOINTS DE ACUERDO A LA DISTANCIA DEL ORIGEN
  checkThird3() {
    console.log(wayPointsArray);
    wayPointsArray.sort(function (a, b) {
        if (a.distancia > b.distancia) {
          return 1;
        }
        if (a.distancia < b.distancia) {
          return -1;
        }
        // a must be equal to b
        return 0;
    });
    console.log(wayPointsArray);
    const d = new Trayecto();
    d.asignarPuntosAdicionales();
  }

  DibujarRuta(){
      this.handleClick();
  }

  //METODO PARA GUARDAR O ACTUALIZAR LOS TRAYECTOS
  GuardarRuta(){

    var distancia=this.calcularDistancia(vMarkerOrigen.getPosition().lat(),vMarkerOrigen.getPosition().lng(),vMarkerDestino.getPosition().lat(),vMarkerDestino.getPosition().lng());
    if(listadopoly == ""){
        alert("Antes de guardar, debe dibujar la ruta.");
    }else{

        if((listadopoly.split("||||").length == (listadowaypoints.split("|").length+1)) || listadowaypoints == ""){
            let data =
            {
                "ID": this.state.rutaseleccionada,
                "descripciontrayecto": document.getElementById('descripcionruta').value,
                "origen": vMarkerOrigen.getPosition().lat()+","+vMarkerOrigen.getPosition().lng(),
                "nombreorigen": document.getElementById('origin').value,
                "destino": vMarkerDestino.getPosition().lat()+","+vMarkerDestino.getPosition().lng(),
                "nombredestino": document.getElementById('destination').value,
                "waypoints": listadowaypoints,
                "tolerancia": document.getElementById('tolerancia').value,
                "distanciaorigen": distancia.toFixed(2),
                "poly": listadopoly,
                "distanciareal": this.state.distancia
            }
            const json = JSON.stringify(data);
            axios.post(process.env.REACT_APP_SERVER+'/operaciones/savetrayecto', json, {
              headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${localStorage.getItem('tkn')}`},
              withCredentials: true})
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    listadopoly="";
                    poliArray=[];
                    alert("La ruta fue guardada exitosamente");
                    window.history.go(0);
                }else{
                    alert("Se ha presentado un error");
                }
            })
        }else{
            alert("Se ha presentado un error, intente de nuevo");
        }

    }

  }

  // METODO QUE LISTA LOS TRAYECTOS
  ListarRutas(){
      axios.get(process.env.REACT_APP_SERVER+'/operaciones/gettrayectos', {
        headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${localStorage.getItem('tkn')}`},
        withCredentials: true})
      .then(res => {
        if(res.data.success === false){
            window.location="/";
        }else{
            this.setState({ listRutas: res.data.data});
          console.log(res.data.data);
        }
      })
  }

  // METODO QUE CARGA LA INFORMACION DE UN TRAYECTO EN EL FORMULARIO Y EL MAPA
  EditarRuta(ruta){
      document.getElementById("tablaway").innerHTML="";
      document.getElementById("submitO").style.backgroundColor= '#EDF2E2';
      document.getElementById("submitO").style.color= '#000';
      document.getElementById("submitD").style.backgroundColor= '#EDF2E2';
      document.getElementById("submitD").style.color= '#000';
      document.getElementById("submitW").style.backgroundColor= '#EDF2E2';
      document.getElementById("submitW").style.color= '#000';
      this.setState({ rutaseleccionada: ruta });
      if(ruta == -1){
          window.history.go(0);
      }else{
          if(vMarkerOrigen != null){
              vMarkerOrigen.setMap(null);
              vMarkerOrigen=null;
          }
          if(vMarkerDestino != null){
              vMarkerDestino.setMap(null);
              vMarkerDestino=null;
          }
          if(wayPointsArray.length > 0 ){
              for(var i=0; i< wayPointsArray.length; i++){
                  var m = wayPointsArray[i];
                  m.setMap(null);
              }
              wayPointsArray=[];
              poliArray = [];
          }

          if(directionsRenderer != null){
              directionsRenderer.setMap(null);
          }
          this.state.listRutas.map(function(loc, i){
              if(loc.IDTrayecto == ruta){
                 const d = new Trayecto();
                 document.getElementById('descripcionruta').value = loc.DescripcionTrayecto;
                 document.getElementById('origin').value = loc.NombreOrigen;
                 document.getElementById('destination').value = loc.NombreDestino;
                 document.getElementById('tolerancia').value = loc.Tolerancia;
                 d.asignarOrigenEdit(loc.Origen);
                 d.asignarDestinoEdit(loc.Destino);
                 if(loc.WayPoints != ""){
                     var wp = loc.WayPoints.split("|");
                     for(var i = 0; i< wp.length; i++){
                          var ob = wp[i].split(",");
                          d.agregarWaypointsEdit(ob[0]+","+ob[1]);
                     }
                 }
              }
          })

      }
  }

  render(){

    return (
      <>
      <Barra />
      <MDBRow className="contenido">
          <MDBCol md='5'>
              <section className="Trayecto">
                <div className="Titulo">Configuración de Georuta</div>
                <MDBRow>
                    <MDBCol md='4' className="Titulo3">Georuta:</MDBCol>
                    <MDBCol md='8'>
                        <select className="form-select" id="georuta" name="location-input" value={this.state.rutaseleccionada}  onChange={e => this.EditarRuta(e.target.value)}>
                            <option key={-1} value={-1}>
                              Nueva
                            </option>
                        {this.state.listRutas.map((loc) => (
                           <option key={loc.IDTrayecto} value={loc.IDTrayecto}>
                             {loc.DescripcionTrayecto}
                           </option>
                         ))}
                        </select>
                    </MDBCol>
                </MDBRow>

                <hr />

                <MDBRow>
                    <MDBRow className="mb-2">
                        <MDBCol md='12'>
                            <div className="Titulo">Información de la ruta</div>
                            <MDBRow>
                                <MDBCol md='4' className="Titulo3">Descripción Ruta:</MDBCol>
                                <MDBCol md='8'>
                                    <input
                                      className="form-control"
                                      id="descripcionruta"
                                      name="ruta"
                                      value={this.state.input}
                                      onChange={this.handleInputChange}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-2">
                        <MDBCol md='12'>
                            <MDBRow>
                                <MDBCol md='4' className="Titulo3">Descripción Origen:</MDBCol>
                                <MDBCol md='6'>
                                    <input
                                      className="form-control"
                                      id="origin"
                                      name="origin"
                                      value={this.state.input}
                                      onChange={this.handleInputChange}
                                    />
                                </MDBCol>
                                <MDBCol md='2'>
                                    <button id="submitO" className="btn btn-outline-secondary" onClick={this.asignarOrigen}>
                                        <FaMapMarkerAlt size={18} className="icono"/>
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-2">
                        <MDBCol md='12'>
                            <MDBRow>
                                <MDBCol md='4' className="Titulo3">Descripción Destino:</MDBCol>
                                <MDBCol md='6'>
                                    <input
                                      className="form-control"
                                      id="destination"
                                      name="destination"
                                      value={this.state.input}
                                      onChange={this.handleInputChange}
                                    />
                                </MDBCol>
                                <MDBCol md='2'>
                                    <button id="submitD" className="btn btn-outline-secondary" onClick={this.asignarDestino}>
                                        <FaMapMarkerAlt size={18} className="icono"/>
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBRow>

                <MDBRow className="mb-2">
                    <MDBCol md='12'>
                        <MDBRow>
                            <MDBCol md='8'>
                                <label className="mensaje">*Recuerda la distancia entre los puntos no debe ser mayor a 100km.</label>
                            </MDBCol>
                            <MDBCol md='4'>
                                <button id="submitW" className="btn btn-outline-secondary"  onClick={this.agregarWaypoints}>
                                    Añadir Puntos <FaPlusCircle size={18} className="icono"/>
                                </button>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2">
                    <MDBCol md='12'>
                        <table className="tablawp">
                            <thead>
                                <tr>
                                    <td>N°</td>
                                    <td>Latitud</td>
                                    <td>Longitud</td>
                                    <td>Distancia</td>
                                </tr>
                           </thead>
                           <tbody id="tablaway">
                           </tbody>
                        </table>
                        <label className="mensaje">*Para eliminar un waypoint oprima click derecho sobre el marcador.</label>
                    </MDBCol>
                </MDBRow>


                <MDBRow className="mb-2">
                    <MDBCol md='12'>
                        <div className="Titulo">Otras configuraciones</div>
                    </MDBCol>
                    <MDBRow>
                        <MDBCol md='4' className="Titulo3">Tolerancia:</MDBCol>
                        <MDBCol md='8'>
                            <input
                              className="form-control"
                              id="tolerancia"
                              name="tolerancia"
                              value={this.state.input}
                              onChange={this.handleInputChange}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBRow>

                <div className="demo-inline-spacing text-center" >
                  <button id="submit" className="btn btn-outline-secondary" onClick={this.checkThird3}>
                      {' '}
                      <FaSort size={18} className="icono"/> Ordenar
                  </button>
                  <button id="submit" className="btn btn-outline-secondary" onClick={this.checkThird}>
                      {' '}
                      <FaRoute size={18} className="icono"/> Dibujar
                  </button>
                  <button id="submit" className="btn btn-outline-secondary" onClick={this.checkThird2}>
                      {' '}
                      <FaSave size={18} className="icono"/> Guardar
                  </button>
                  <button id="submit" className="btn btn-outline-secondary" onClick={this.checkThird4}>
                      {' '}
                      <FaLocationArrow size={18} className="icono"/> Comprobar
                  </button>
                </div>

              </section>
          </MDBCol>
          <MDBCol md='7'>
              <input id="pac-input" className="controls center" type="text center" placeholder="Buscar sitio" />
              <div id="map" />
          </MDBCol>
      </MDBRow>

      </>
    );
  }



}

export default Trayecto;
