import React from 'react'
import { Spinner } from 'reactstrap'

const PreloaderHistorico = () => {
  return (
    <div className="text-center flex-column">
        <Spinner style={{ width: '3rem', height: '3rem' }} />{' '}
        <p>Cargando información...</p>
      </div>
  )
}

export default PreloaderHistorico
