import React, { useEffect } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import '../../Pages/Css/stylesRadix.css';
// import '@radix-ui/react-tooltip/dist/tooltip.css';

const TooltipComponent = ({ message }) => {
    useEffect(() => {
        console.log("Estoy mostrando el Tooltip");
    }, []);

    return (
        <div className="tooltip-container">
            <Tooltip.Provider>
                <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                        <QuestionMarkCircledIcon className="tooltip-trigger" />
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                            <div className="tooltip-inner">
                                {message}
                            </div>
                            <Tooltip.Arrow className="tooltip-arrow" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        </div>
    );
};

export default TooltipComponent;
