import React, { useEffect } from 'react';
import "../../Pages/Css/themes/base/all.css";
import "../../App.css";
import { event } from 'jquery';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const BaseModalCreateSolcitud = ({ isOpen, onClose, title, childComponent }) => {

    const backdropStyle = 'static';

    const handleClose = () => {
        onClose(); // Llamar a la función onClose para indicar que se ha cerrado el modal
    };

    useEffect(() => {
        console.log("Valor de visibilidad del modal : ", isOpen);
    }, [isOpen]);

    return (
        <Modal
            id='ModalCrearSolicitud'
            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            size='xl'
            zIndex={9999}

        >
            <ModalHeader toggle={onClose} >{title}</ModalHeader>
            <ModalBody style={{paddingTop:0}}>
                {childComponent}
            </ModalBody>
        </Modal >
    );
};

export default BaseModalCreateSolcitud;
