import React, { useEffect } from 'react'
import '../../Pages/Css/solicitudes.css'

const SolicitudesResponsive = (props) => {
    useEffect(() => {
        console.log("solcitudes filtradas", props.filteredSolicitudes);
        console.log("solcitudes list:", props.listSolicitudes);
    }, [props]);

    return (
        <div className='containerCardsSolicitudes'>
            {props.filteredSolicitudes && props.filteredSolicitudes.map((solicitud, index) => (
                <div key={index} className='cardSolicitudesInitial'>
                    <div className='header'>
                        <p><strong>{solicitud.IDSolicitudes}</strong></p>
                        <div className='opcionesSolicitud'>
                            <a href='#' onClick={(e) => { e.preventDefault(); props.showModalUpdateSolicitud(solicitud.IDSolicitudes) }}><i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i></a>
                            <a href='#' onClick={(e) => { e.preventDefault(); props.showModalAddReport(solicitud.IDSolicitudes) }}><i class='bx bxs-plus-square' style={{ color: '#006699' }}></i></a>
                            <a href='#' onClick={(e) => { e.preventDefault(); props.showModalCausal(solicitud.IDSolicitudes) }}><i class='bx bx-trash' style={{ color: '#006699' }}></i></a>
                        </div>
                    </div>
                    <div className='body'>
                        <p><strong>Empresa:</strong> {solicitud.NombreEmpresa}</p>
                        <p><strong>Ruta:</strong> {solicitud.Ruta}</p>
                        <p><strong>Placa: </strong>{solicitud.PlacaTruck}</p>
                        <p><strong>#Contenedor:</strong> {solicitud.Contenedor}</p>
                        <p><strong>Instalador:</strong> {solicitud.NombreInstalador}</p>
                        <p><strong>Hora servicio:</strong> {solicitud.Hora}</p>
                        <p><strong>Estado:</strong> {solicitud.estado}</p>
                        <p><strong>Nota:</strong> {solicitud.nota}</p>
                        <p><strong>Ult. Nota:</strong> {solicitud.hora_e}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SolicitudesResponsive
