export const formattedGlobalDate = (dateString) => {
    try {
        if (!dateString || typeof dateString !== 'string') {
            throw new Error('Fecha inválida');
        }
        const [date, time] = dateString.split('T');
        const formattedTime = time.split('.')[0];
        return `${date} ${formattedTime}`;
    } catch (error) {
        console.error('Error formateando la fecha:', error.message);
        return '';
    }
};

export function formatMinutesToHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60); 
    const minutes = totalMinutes % 60; 
    return `${hours}H ${minutes}MIN`;
}
