import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Carousel } from "react-responsive-carousel";
import '../Pages/Css/carousel.css';
import Swal from "sweetalert2";
import axios from "../utils/axiosConfig";

const VideoGallery = ({ contrato }) => {
  const [videos, setVideos] = useState([]);
  const [numVideos, setNumVideos] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const videoRefs = useRef([]);

  const handlePlay = () => {
    setPlaying((prev) => !prev);
  }

  const handleVideoSelect = (index) => {
    setSelectedItem(index);
    mostrarInfo(videos[index]);
  };

  useEffect(()=>{
    getVideos(contrato);
    setShowAlert(true);
  },[]);


  useEffect(() => {
    getVideos(contrato);
    console.log("**********Contrato videos: ", contrato);
    // Configurar el temporizador para ocultar el alert después de 6 segundos
    setShowAlert(true);

  }, [contrato]);





  useEffect(() => {
    console.log("valor de selectedItem " + selectedItem);
    console.log("Valor de playing ", playing);

  }, [selectedItem]);






  const getVideos = async (contrato) => {
    console.log("# ENTRO A CONTRATO*************");
    try {
      setNumVideos(-1);
      setCurrentIndex(0);
      setSelectedItem(0);
      const infojson = document.getElementById("infojson");
      infojson.innerHTML = "";
      let video = "vid";
      let data = { contrato, tipo: video }; // Para imagen es img;
      const json = JSON.stringify(data);
      if (contrato != -1) {
        console.log("si hay videos del contrato = " + contrato);
        const response = await axios.post(
          process.env.REACT_APP_SERVER + "/operaciones/getfotoscontrato",
          json,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
            withCredentials: true,
          }
        );
        console.log(response.data.data);
        if (response.data.success) {
          setVideos(response.data.data);
          setNumVideos(1);
          if (response.data.data.length > 0) {
            mostrarInfo(response.data.data[0]);
          }
        } else {
          window.history.go(0);
        }
      } else {
        console.log("no hay videos del contrato aun");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Función para pausar todos los videos
  const pauseAllVideos = () => {
    videoRefs.current.forEach((ref) => {
      if (ref && ref.current) {
        ref.current.getInternalPlayer().pause();
      }
    });
  };



  // Función para guardar la referencia del reproductor de video
  const saveVideoRef = (index) => (ref) => {
    videoRefs.current[index] = ref;
  };

  const loadVideosData = (videos) => {
    if (videos.length > 0) {
      return videos.map((video, index) => (
        <div key={index}>
          <ReactPlayer
            ref={saveVideoRef(index)}
            className="w-100"
            url={video.Source}
            height={500}
            controls
            light={video.previewImage}
            playsinline
            playing={index === selectedItem}
          />
        </div>
      ));
    } else {
      return (
        <div key={-1}>
          <p>No hay videos disponibles</p>
        </div>
      );
    }
  }

  const loadThumbnails = (videos) => {
    if (videos.length > 0) {
      return videos.map((video, index) => (
        <div
          key={index}
          className={`thumbnail ${index === selectedItem ? "selected" : ""}`}
          onClick={() => handleVideoSelect(index)}

        >
          <video src={video.Source} alt={`Thumbnail ${index}`} />
        </div>
      ));
    } else {
      return <p>No hay miniaturas disponibles</p>;
    }
  };

  const mostrarInfo = (videos) => {
    console.log(videos.Orden);
    document.getElementById('infovideo').innerHTML = videos.Evento + "<br/>" + videos.Descripcion + "<br/>" + videos.Latitud + " , " + videos.Longitud + "<br/>" + videos.Nombreempresa + "<br/>" + videos.Hora;
  }


  return (

    <>
      {showAlert && (
        <MDBRow>
          <MDBCol>
            <div class="alert">
              <p>Recuerda porfavor pausar el video antes de abandonar el modal</p>
              <button className="btn-close" onClick={() => setShowAlert(false)}></button>
            </div>
          </MDBCol>
        </MDBRow>
      )}
      <MDBRow>
        <MDBCol md="9" className="mb-4">
          <Carousel
            selectedItem={selectedItem}
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={false}
            onChange={handleVideoSelect}
          >

            {loadVideosData(videos)}
          </Carousel>
          <MDBCol md="9" className="mb-4">
            <div className="thumbnails" style={{marginTop:'24px'}}>{loadThumbnails(videos)}</div>
          </MDBCol>
        </MDBCol>
        <MDBCol id="panelinfo" md='3' style={{ margin: '0', padding: '0' }}>
          <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>Información</div>
          <div id="infovideo" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', textAlign: 'start' }}>
          </div>

          <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>Image Analytics  <img
            src="/images/reload.png" alt="Icono" style={{ marginLeft: '10px', width: '18px', cursor: 'pointer' }} /></div>
          <div id="infojson" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', height: '300px', overflow: 'auto' }}>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default VideoGallery;
