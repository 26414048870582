import React, { useEffect, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import '../../Pages/Css/stylesRadix.css';

const CheckboxComponent = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  // Sincroniza el estado local con el prop 'checked' usando useEffect
  useEffect(() => {
    setIsChecked(checked);
    console.log(checked)
  }, [checked]);

  // Maneja el cambio del checkbox
  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    console.log(checked)
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue); // Llama a la función onChange del padre con el nuevo valor
    }
  };

  return (
    <form>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        {isChecked === true ? (
          <Checkbox.Root
            className="CheckboxRoot"
            onChange={handleCheckboxChange}
          >
            <Checkbox.Indicator className="CheckboxIndicator">
              <CheckIcon />
            </Checkbox.Indicator>
          </Checkbox.Root>
        ) : (
          <Checkbox.Root
            className="CheckboxRoot"
            onChange={handleCheckboxChange}
            defaultChecked
          >
            <Checkbox.Indicator className="CheckboxIndicator">
              <CheckIcon />
            </Checkbox.Indicator>
          </Checkbox.Root>
        )}
        <label className="Label" style={{ marginTop: 15 }}>
          Restricción Activa
        </label>
      </div>
    </form>
  );
};

export default CheckboxComponent;
