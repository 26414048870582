import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ModalReporteServicio = ({ isOpen, onClose, childComponent }) => {
    const backdropStyle = 'static';
    const modalStyle = {
        // marginTop: '24px',
        // marginBottom: '24px',
        // height: `calc(100vh - 48px)`, // Altura del viewport menos el margen superior e inferior
        // maxHeight: 'calc(100vh - 48px)', // Asegura que no se exceda la altura disponible
    };
    return (
        <Modal

            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            size='lg'
            zIndex={9999}
            style={modalStyle}
            centered
        >
            <ModalHeader toggle={onClose}></ModalHeader>
            <ModalBody>
                {childComponent}
            </ModalBody>
        </Modal >
    )
}

export default ModalReporteServicio
