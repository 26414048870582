import React, { useEffect, useState } from 'react'
import Barra from '../Modulos/Barra'
import { FaCompress } from '@react-icons/all-files/fa/FaCompress'
import { FaExpand } from '@react-icons/all-files/fa/FaExpand'
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash'
import { FaEye } from '@react-icons/all-files/fa/FaEye'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter'
import $ from "jquery";
import './Css/solicitudes.css';
import SolicitudesResponsive from '../components/responsive/SolicitudesResponsive'
import BaseModalCreateSolcitud from '../components/modals/baseModal'
import FormSolicitudesLog from '../components/formsSolicitudes/formSolicitudesLog'
import FormSolicitudesCli from '../components/formsSolicitudes/formSolicitudesCli'
import Swal from 'sweetalert2'
import FormUpdateSolicitudCli from '../components/formsSolicitudes/formUpdateSolicitudCli'
import FormUpdateSolicitudLog from '../components/formsSolicitudes/formUpdateSolicitudLog'
import FormSolicitudesMult from '../components/formsSolicitudes/formSolicitudesMult'
import CausalModal from '../components/modals/causalModal'
import CausalAnularSolicitud from '../components/formsSolicitudes/causalAnularSolicitud'
import ReporteSeguimientoModal from '../components/modals/rmodalSolicitud'
import FormReporteSolicitudes from '../components/formsSolicitudes/formReporteSolicitudes'
import axios from '../utils/axiosConfig'
import { formattedGlobalDate } from '../utils/formattedDates'

const Solicitudes = () => {

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [listSolicitudes, setListSolicitudes] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [color, setColor] = useState('');
    const [statusForm, setStatusForm] = useState('');
    const [formType, setFormType] = useState(null);
    const [formTypeMult, setFormTypeMult] = useState(null);
    const [formTypeMCausalAnular, setFormTypeCausalAnular] = useState(null);
    const [formTypeReporteSeguimiento, setFormTypeReporteSeguimiento] = useState(null);
    const [formTypeUpdate, setFormTypeUpdate] = useState(null);
    const [clientes, setClientes] = useState(['-No asignado-']);
    const [infoByIdSolcitud, setInfoByIdSolcitud] = useState({});

    const [selectedCiudades, setSelectedCiudades] = useState(0);
    //Contadores de colores en solciitudes
    const [redCount, setRedCount] = useState(0);
    const [yellowCount, setYellowCount] = useState(0);
    const [blueCount, setBlueCount] = useState(0);
    const [greenCount, setGreenCount] = useState(0);
    const [transparentCount, setTransparentCount] = useState(0);

    const [isDialogOpenNewSolicitud, setIsDialogOpenNewSolicitud] = useState(false);
    const [isDialogOpenUpdateSolicitud, setIsDialogOpenUpdateSolicitud] = useState(false);
    const [isDialogOpenUpdateSolicitudMult, setIsDialogOpenUpdateSolicitudMult] = useState(false);
    const [isDialogOpenCausalAnular, setIsDialogOpenCausalAnular] = useState(false);
    const [isDialogOpenReportSeguimiento, setIsDialogOpenReportSeguimiento] = useState(false);
    const [isDialogOpenNewSolicitudDiff, setIsDialogOpenNewSolicitudDiff] = useState(false);

    const [filteredSolicitudes, setFilteredSolicitudes] = useState([]);



    const sortData = (field) => {
        const sortedData = [...filteredSolicitudes].sort((a, b) => {
            if (
                field === "IDSolicitudes" ||
                field === "NombreEmpresa" ||
                field === "Ruta" ||
                field === "Contenedor" ||
                field === "PlacaTruck" ||
                field === "Contacto" ||
                field === "NombreInstalador" ||
                field === "Hora" ||
                field === "estado" ||
                field === "nota" ||
                field === "hora_e"
            ) {
                // Ordenar cadenas alfabéticamente (campo 'empresa')
                return (a[field] || "").localeCompare(b[field] || "");
            } else {
                return (a[field] || 0) - (b[field] || 0);
            }
        });

        // Cambia la dirección de orden si es necesario
        if (sortDirection === "desc") {
            sortedData.reverse();
        }

        setFilteredSolicitudes(sortedData);
        setSortBy(field);
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    };

    const handleSelectChangeCiudades = (event) => {
        const selectedCityId = parseInt(event.target.value, 10); // Convertimos a número
        setSelectedCiudades(selectedCityId);
        console.log("Id ciudad seleccionado: ", selectedCityId);

        const filteredSolicitudesByCiudad = listSolicitudes.filter((city) => city.FKLokCiudadOrigen === selectedCityId);
        setFilteredSolicitudes(filteredSolicitudesByCiudad);
        console.log("Solicitudes filtradas por ciudad: ", filteredSolicitudesByCiudad);
    };


    const renderSortIcon = (column) => {

        if (sortBy === column) {
            if (sortDirection === "asc") {
                return <span>&uarr;</span>; // Icono de flecha hacia arriba
            } else {
                return <span>&darr;</span>; // Icono de flecha hacia abajo
            }
        }

        return null; // No mostrar ningún ícono si no está ordenado por esta columna
    }

    const [isFullscreen, setIsFullScreen] = useState(false);

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            // Activar el modo de pantalla completa
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            }
        } else {
            // Desactivar el modo de pantalla completa
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

        // Actualizar el estado
        setIsFullScreen(!isFullscreen);
    };

    const handleSearchChange = (event) => {

        const value = event.target.value.toLowerCase();
        console.log('Solictudes **:', value);
        localStorage.setItem("searchSolicitud", value);
        if (value === "") {
            setFilteredSolicitudes(listSolicitudes);
        } else {
            // Filtra los contratos basados en el valor de búsqueda
            var filteredSolicitudes = [];
            const lowerValue = value.toLowerCase();

            for (const contrato of listSolicitudes) {
                const lowerSolicitudID = contrato.IDSolicitudes
                    ? contrato.IDSolicitudes.toLowerCase()
                    : "";
                const lowerNombreEmpresa = contrato.NombreEmpresa
                    ? contrato.NombreEmpresa.toLowerCase()
                    : "";
                const lowerRuta = contrato.Ruta
                    ? contrato.Ruta.toLowerCase()
                    : "";
                const lowerPlacaTruck = contrato.PlacaTruck
                    ? contrato.PlacaTruck.toLowerCase()
                    : "";
                const lowerContenedor = contrato.Contenedor
                    ? contrato.Contenedor.toLowerCase()
                    : "";
                const lowerContacto = contrato.Contacto
                    ? contrato.Contacto.toLowerCase()
                    : "";
                const lowerInstalador = contrato.NombreInstalador
                    ? contrato.NombreInstalador.toLowerCase()
                    : "";
                const lowerHoraServ = contrato.Hora
                    ? contrato.Hora.toLowerCase()
                    : "";
                const lowerEstado = contrato.estado
                    ? contrato.estado.toLowerCase()
                    : "";
                const lowerNota = contrato.nota
                    ? contrato.nota.toLowerCase()
                    : "";
                const lowerUltimaNota = contrato.hora_e
                    ? contrato.hora_e.toLowerCase()
                    : "";
                const lowerRef = contrato.Ref ? contrato.Ref.toLowerCase() : "";

                if (
                    lowerSolicitudID.includes(lowerValue) ||
                    lowerNombreEmpresa.includes(lowerValue) ||
                    lowerRuta.includes(lowerValue) ||
                    lowerContenedor.includes(lowerValue) ||
                    lowerPlacaTruck.includes(lowerValue) ||
                    lowerContacto.includes(lowerValue) ||
                    lowerInstalador.includes(lowerValue) ||
                    lowerHoraServ.includes(lowerValue) ||
                    lowerEstado.includes(lowerValue) ||
                    lowerNota.includes(lowerValue) ||
                    lowerUltimaNota.includes(lowerValue) ||
                    lowerRef.includes(lowerValue)
                ) {
                    filteredSolicitudes.push(contrato);
                }
            }
            // Actualiza el estado con los contratos filtrados y el valor de búsqueda
            // this.setState({
            //     filteredContratos: filteredContratos,
            // });
            setFilteredSolicitudes(filteredSolicitudes);
        }
    };

    //SHOW MODAL CREAR NUEVA SOLICITUD
    const showModalNewSolicitud = () => {
        setIsDialogOpenNewSolicitud(true);
        console.log(formType);

    }
    //SHOW MODAL CREAR SOLCITUDmULTIPLE
    const showModalNewSolicitudMult = () => {
        console.log("Modal abiertto");
        setIsDialogOpenUpdateSolicitudMult(true);
        setFormTypeMult(<FormSolicitudesMult onClose={closeModalAndShowAlertDialogMult} clientes={clientes} />)
    }

    //SHOW MODAL CAUSAL ELIMINACION DE SOLICITUD
    const showModalCausalEliminacionSolicitud = (id) => {
        console.log("Estoy entrando al modal de causal");
        setIsDialogOpenCausalAnular(true);
        setFormTypeCausalAnular(<CausalAnularSolicitud idSolicitud={id} onClose={closeModalCausalAnular} />)
    }

    //SHOW MODAL REPORTE SEGUIMIENTO SOLICICITUD
    const showModalReporteSeguimientoSolicitud = (id) => {
        setIsDialogOpenReportSeguimiento(true);
        setFormTypeReporteSeguimiento(<FormReporteSolicitudes idSolicitud={id} onClose={closeModalReporteSolcicitud} />)
    }

    const showModalUpdateSolicitud = (IDSolicitud) => {
        CargarInfoByIdSolicitud(IDSolicitud);
        let timerInterval;
        Swal.fire({
            title: "Estamos cargando la información de la solicitud",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                // const timer = Swal.getPopup().querySelector("b");
                // timerInterval = setInterval(() => {
                //     timer.textContent = `${Swal.getTimerLeft()}`;
                // }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
                setIsDialogOpenUpdateSolicitud(true);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
            }
        });
    }

    //SHOW MODAL CREAR SOLICTUD SIN CAMPOS
    const showModalSolicitudDiffClient = () => {
        setIsDialogOpenNewSolicitudDiff(true);
        console.log(formType);
    }

    const closeModalSolicitud = () => {
        setIsDialogOpenNewSolicitud(false);
        setIsDialogOpenNewSolicitudDiff(false);
    }

    const closeModalCausalAnular = () => {
        setIsDialogOpenCausalAnular(false);
        ListarContratos();
    }

    const closeModalReporteSolcicitud = () => {
        setIsDialogOpenReportSeguimiento(false);
        ListarContratos();
    }

    const closeModalSolicitudMult = () => {
        setIsDialogOpenUpdateSolicitudMult(false);
        setIsDialogOpenNewSolicitud(false);
        setIsDialogOpenNewSolicitudDiff(false);
    }

    const closeModalUpdateSolicitud = () => {
        setIsDialogOpenUpdateSolicitud(false);
    }

    //MANEJO DE CIERRRE DEL MODAL 
    const closeModalAndShowAlertDialog = () => {
        closeModalSolicitud();
        ListarContratos();
    }

    const closeModalAndShowAlertDialogMult = () => {
        closeModalSolicitudMult();
        ListarContratos();
    }

    const closeModalAndShowAlertDialogUpdate = () => {
        closeModalUpdateSolicitud();
        ListarContratos();
    }

    //CARGAR INFO FORMULARIO
    const CargarInfoByIdSolicitud = (idSolcicitud) => {
        let dataSolicitud = {
            id: idSolcicitud
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getobtenerSolicitud", JSON.stringify(dataSolicitud), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion de la solicitud", res.data.data);
                    setInfoByIdSolcitud(res.data.data);
                }
            }).catch((err) => console.log(err));
    }


    // METODO PARA LISTAR SOLICITUDES
    const ListarContratos = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getsolicitudes", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Soliciitudes*******:", res.data.data);
                    setListSolicitudes(res.data.data);
                    setFilteredSolicitudes(res.data.data);
                    if (localStorage.getItem('searchSolicitud') !== '') {
                        const searchValue = document.getElementById('searchSolicitud').value;
                        handleSearchChange({ target: { value: searchValue } });
                    }

                }
            }).catch((err) => console.log(err));
    }

    //LISTAR CIUDADES
    const listarCiudades = () => {
        axios.get(process.env.REACT_APP_SERVER + "/solicitudes/getrutassolicitudesciudadorigen", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
            withCredentials: true,
        })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Ciudades select: ", res.data.data);
                    setCiudades(res.data.data);
                    // this.setState({ listContratos: res.data.data }, () => {
                    //     this.handleSearchChange({ target: { value: $("#search").val() } });
                    // });
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR CLIENTES
    const ListarClientes = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaempresas", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Xlientes", res.data);
                    console.log("Xlientes tipo formulario ", res.data.formulario);
                    setStatusForm(res.data.formulario);
                    if (res.data.formulario === 'reducido') {
                        setFormType(<FormSolicitudesCli clientes={res.data.data} onClose={closeModalAndShowAlertDialog} statusForm={res.data.formulario} />);
                    } else if (res.data.formulario === 'completo') {
                        setFormType(<FormSolicitudesLog clientes={res.data.data} onClose={closeModalAndShowAlertDialog} statusForm={res.data.formulario} />);
                    }
                    setClientes(res.data.data);

                }
            }).catch((err) => console.log(err));
    }

    const backgrounValidate = (data) => {
        if (data.estado === 'Solicitud Cliente por Confirmar') {
            return { backgroundColor: 'rgba(25, 160, 224, 0.5)' };
        }

        if (!data.Hora) {
            return { backgroundColor: 'transparent' }; // No hay hora, no cambiar el fondo
        }

        const now = new Date();
        const horaCita = new Date(data.Hora.replace(' ', 'T'));
        const diferencia = (now - horaCita) / 1000 / 60; // Diferencia en minutos
        console.log("diferencia: ", diferencia);

        if (diferencia >= 0) {

            return { backgroundColor: 'rgba(224, 43, 40, 0.5)', }; // Hora ya pasó, color rojo
        } else if (-120 <= diferencia && diferencia < 0) {

            return { backgroundColor: 'rgba(224, 160, 25, 0.5)' }; // Dentro de la próxima hora, color amarillo
        } else if (-240 <= diferencia && diferencia < -120) {

            return { backgroundColor: 'rgba(93, 224, 43, 0.5)' }; //  Dentro de las próximas 12 horas, color verde
        } else {

            return { backgroundColor: 'transparent' };
        }


    }

    const colorEstado = (estado) => {
        if (estado === 'Solicitud Cliente por Confirmar') {
            return { color: 'blue' };
        }
    }

    const countColorsSolicitudes = (solicitudes) => {
        let red = 0, yellow = 0, green = 0, transparent = 0, blue = 0;
        console.log("solicitudes desde el contador de colores: ***** ", solicitudes);
        solicitudes.forEach(data => {
            if (data.estado === 'Solicitud Cliente por Confirmar') {
                blue++;
                return;
            }
            if (!data.Hora) {
                transparent++;
                return;
            }

            const now = new Date();
            const horaCita = new Date(data.Hora.replace(' ', 'T'));
            const diferencia = (now - horaCita) / 1000 / 60; // Diferencia en minutos
            console.log("diferencia: ", diferencia);

            if (diferencia >= 0) {
                red++;
            } else if (-120 <= diferencia && diferencia < 0) {
                yellow++;
            } else if (-240 <= diferencia && diferencia < -120) {
                green++;
            } else {
                transparent++;
            }
            
        });

        setRedCount(red);
        setYellowCount(yellow);
        setGreenCount(green);
        setBlueCount(blue);
        setTransparentCount(transparent);
    }



    //CARGA DE SOLICITUDES
    const loadFillData = (array) => {
        console.log("este es el array de la funcion loadFillData", filteredSolicitudes);

        return filteredSolicitudes.map((data) => {
            return (
                <React.Fragment >
                    {/* <tr onClick={() => this.toggleRow(data.ContractID)}> */}
                    <tr style={backgrounValidate(data)}>

                        <td className="prueba" style={{ color: "#000" }}>
                            <strong>{data.IDSolicitudes}</strong>
                        </td>
                        <td className="prueba" style={{ color: "#000" }}>
                            <strong>{data.NombreEmpresa}</strong>
                        </td>


                        <td style={{ color: "#000" }}>{data.Ruta}</td>

                        <td style={{ color: "#000" }}>{data.PlacaTruck}</td>


                        <td style={{ color: "#000" }}>{data.Contenedor}</td>


                        <td style={{ color: "#000" }}>{data.Contacto}</td>


                        <td style={{ color: "#000" }}>{data.NombreInstalador}</td>


                        <td style={{ color: "#000" }}>{data.Hora}</td>


                        <td style={{ color: "#000" }}>{data.estado}</td>


                        <td style={{ color: "#000" }}>{data.nota}</td>


                        <td style={{ color: "#000" }}>{formattedGlobalDate(data.hora_e)}</td>


                        <td>
                            <a href='#' onClick={(e) => { e.preventDefault(); showModalUpdateSolicitud(data.IDSolicitudes) }}><i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i></a>
                        </td>


                        <td>
                            <a href='#' onClick={(e) => { e.preventDefault(); showModalReporteSeguimientoSolicitud(data.IDSolicitudes) }}><i class='bx bxs-plus-square' style={{ color: '#006699' }}></i></a>

                        </td>
                        <td>
                            <a href='#' onClick={(e) => { e.preventDefault(); showModalCausalEliminacionSolicitud(data.IDSolicitudes) }}><i class='bx bx-trash' style={{ color: '#006699' }}></i></a>

                        </td>


                    </tr>
                </React.Fragment>
            )
        })
    }

    useEffect(() => {
        if (infoByIdSolcitud && statusForm === 'reducido') {
            setFormTypeUpdate(<FormUpdateSolicitudCli clientes={clientes} onClose={closeModalAndShowAlertDialogUpdate} info={infoByIdSolcitud} />);
        } else if (infoByIdSolcitud && statusForm === 'completo') {
            setFormTypeUpdate(<FormUpdateSolicitudLog clientes={clientes} onClose={closeModalAndShowAlertDialogUpdate} info={infoByIdSolcitud} />);
        }
    }, [infoByIdSolcitud]);

    useEffect(() => {
        ListarContratos();
        listarCiudades();
        ListarClientes();
        localStorage.setItem("searchSolicitud", "");
    }, []);

    useEffect(() => {
        if (filteredSolicitudes) {
            countColorsSolicitudes(filteredSolicitudes);
        }
    }, [filteredSolicitudes]);


    useEffect(() => {
        if (selectedCiudades === 0 && listSolicitudes) {
            setFilteredSolicitudes(listSolicitudes);
        }
    }, [selectedCiudades]);



    return (
        <>
            <BaseModalCreateSolcitud isOpen={isDialogOpenNewSolicitud} onClose={closeModalSolicitud} title={'Crear solicitud1'} childComponent={formType} />
            <BaseModalCreateSolcitud isOpen={isDialogOpenUpdateSolicitud} onClose={closeModalUpdateSolicitud} title={'Actualizar solicitud'} childComponent={formTypeUpdate} />
            <BaseModalCreateSolcitud isOpen={isDialogOpenUpdateSolicitudMult} onClose={closeModalSolicitudMult} title={'Solicitudes multiples'} childComponent={formTypeMult} />
            <CausalModal isOpen={isDialogOpenCausalAnular} onClose={closeModalCausalAnular} title={'Causal de eliminacion de la solicitud'} childComponent={formTypeMCausalAnular} />
            <ReporteSeguimientoModal isOpen={isDialogOpenReportSeguimiento} onClose={closeModalReporteSolcicitud} title={'Reporte de Seguimiento de Solicitudes'} childComponent={formTypeReporteSeguimiento} />
            {/* <CreateSolicitud open={isDialogOpenNewSolicitud} onOpenChange={setIsDialogOpenNewSolicitud} /> */}
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <div className="layout-page">
                        <Barra />
                        <div className="content-wrapper">
                            <div className="container-fluid flex-grow-1 container-p-y">
                                <MDBRow>
                                    <MDBCol md="7">
                                        <label
                                            className="fw-bold mb-4 label_trafico"
                                            style={{ fontSize: "22px", marginRight: "10px" }}
                                        >
                                            {" "}
                                            Control de solicitudes
                                        </label>

                                    </MDBCol>
                                    <MDBCol md="1">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => toggleFullscreen()}
                                        >
                                            {isFullscreen ? (
                                                <FaCompress />
                                            ) : (
                                                <FaExpand />
                                            )}
                                        </button>
                                    </MDBCol>

                                    <MDBCol md="3">
                                        <div className="input-group col-md-12">
                                            <span className="input-group-text" id="b1">
                                                <FaSearch />
                                            </span>
                                            <input
                                                id="searchSolicitud"
                                                type="text"
                                                className="form-control"
                                                placeholder="Empresa"
                                                aria-describedby="b1"
                                                onKeyUp={handleSearchChange}
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="1">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasEnd"
                                            aria-controls="offcanvasEnd"
                                        >
                                            <FaFilter />
                                        </button>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow >
                                    <MDBCol md={6} className='labelRowSelect'>

                                        <label
                                            className="fw-bold label_trafico"
                                        >
                                            {" "}
                                            Solicitudes por ciudad de origen
                                        </label>
                                        <select className="form-select" value={selectedCiudades} onChange={handleSelectChangeCiudades}>
                                            <option value={0}>{'Todas'}</option>
                                            {ciudades.map((ciudad) => (
                                                <option key={ciudad.ID} value={ciudad.ID}>
                                                    {ciudad.NOMBRE}
                                                </option>
                                            ))}
                                        </select>

                                    </MDBCol>
                                    <MDBCol md={2} className='countersColors'>
                                        <div className='red'>{redCount}</div>
                                        <div className='blue'>{blueCount}</div>
                                        <div className='yellow'>{yellowCount}</div>
                                        <div className='green'>{greenCount}</div>
                                        <div className='trans'>{transparentCount}</div>
                                    </MDBCol>
                                    <MDBCol md={4} className='buttonSolicitudes'>
                                        <button onClick={showModalNewSolicitud}
                                        >

                                            Crear solicitud única
                                        </button>
                                        {statusForm === 'completo' ? (
                                            <button onClick={showModalNewSolicitudMult}>
                                                Crear solicitudes múltiples
                                            </button>
                                        ) : (null)}
                                    </MDBCol>
                                </MDBRow>

                                <div className="card cardContainerControlDevice controlSolicitudes">
                                    <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                            height: `calc(100vh - 250px)`,
                                            overflowY: "auto",
                                            overflowX: "auto",
                                            display: "flex",
                                            // flexDirection: "column",
                                        }}
                                    >
                                        <table className="table table-hover" id="tabla" style={{ overflowX: 'auto', width: 'auto' }}>
                                            <thead className="FondoHeaderTabla2">
                                                <tr>

                                                    <th
                                                        style={{ color: "#ffffff", width: '60px' }}
                                                        onClick={() => sortData("IDSolicitudes")}
                                                    >
                                                        ID {renderSortIcon("IDSolicitudes")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: '150px' }}
                                                        onClick={() => sortData("NombreEmpresa")}
                                                    >
                                                        EMPRESA{" "}
                                                        {renderSortIcon("NombreEmpresa")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: "150px" }}
                                                        onClick={() => sortData("Ruta")}
                                                    >
                                                        RUTA {renderSortIcon("Ruta")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: "100px" }}
                                                        onClick={() => sortData("PlacaTruck")}
                                                    >
                                                        PLACA {renderSortIcon("PlacaTruck")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: '150px' }}
                                                        onClick={() => sortData("Contenedor")}
                                                    >
                                                        #CONTENEDOR {" "}{renderSortIcon("Contenedor")}
                                                    </th>

                                                    <th style={{ color: "#ffffff" }}
                                                        onClick={() => sortData("Contacto")}
                                                    >
                                                        CONTACTO {" "}{renderSortIcon("Contacto")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff" }}
                                                        onClick={() => sortData("NombreInstalador")}
                                                    >
                                                        INSTALADOR {renderSortIcon("NombreInstalador")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: '150px' }}
                                                        onClick={() => sortData("Hora")}
                                                    >
                                                        HORA SERVICIO{" "}
                                                        {renderSortIcon("Hora")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff" }}
                                                        onClick={() => sortData("estado")}
                                                    >
                                                        ESTADO{" "}
                                                        {renderSortIcon("estado")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff" }}
                                                        onClick={() => sortData("nota")}
                                                    >
                                                        NOTA{" "}
                                                        {renderSortIcon("nota")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff" }}
                                                        onClick={() => sortData("hora_e")}
                                                    >
                                                        ULT.NOTA{" "}
                                                        {renderSortIcon("hora_e")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: "20px" }}
                                                        onClick={() => sortData("IconDesvio")}
                                                    >
                                                        {renderSortIcon("IconDesvio")}
                                                    </th>

                                                    <th
                                                        style={{ color: "#ffffff", width: "20px" }}
                                                        onClick={() => sortData("IconSeguro")}
                                                    >
                                                        {renderSortIcon("IconSeguro")}
                                                    </th>
                                                    <th
                                                        style={{ color: "#ffffff", width: "20px" }}
                                                        onClick={() => sortData("IconSeguro")}
                                                    >
                                                        {renderSortIcon("IconSeguro")}
                                                    </th>


                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0" id="tabladata">
                                                {loadFillData(listSolicitudes)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="controlSolicitudes2">
                                    <SolicitudesResponsive
                                        listSolicitudes={listSolicitudes}
                                        filteredSolicitudes={filteredSolicitudes}
                                        showModalUpdateSolicitud={showModalUpdateSolicitud}
                                        showModalCausal={showModalCausalEliminacionSolicitud}
                                        showModalAddReport={showModalReporteSeguimientoSolicitud}
                                    />

                                </div>

                            </div>
                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Solicitudes
