const SettingsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  console.log("SettingsModal", isOpen);

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box">
          <h1 className="title">Settings</h1>
          <p>Settings content</p>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
};

export default SettingsModal;
