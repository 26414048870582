import React, { Component } from 'react';
// import GoogleMap from 'google-map-react';
// import axios from 'axios';
import '../Pages/Css/reporteservicio.css'
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';
var miStorage = window.localStorage;
class StaticMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infocontrato: {},
      mapKey: 0,
      loading: true,
      imageUrl: null,
      LastMsgLat: null,
      LastMsgLong: null,
    };

  }

  toggleMap = () => {
    this.setState((prevState) => ({ mapKey: prevState.mapKey + 1 }));
  };

  componentDidMount() {
    this.setState({ infocontrato: this.props.contrato });
    console.log("Ultimo punto:", this.props.ultPoint);
    if (this.props.ultPoint === -1 || this.props.ultPoint === undefined) {
      console.log("entre valor undefined");
    } else {
      console.log("entre")
      console.log("este es el valor de ultPoint:", this.props.ultPoint);
      this.getLatLngContrato(this.props.ultPoint);

    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.contrato.ContractID !== prevProps.contrato.ContractID) {

      this.setState((prevState) => ({
        infocontrato: this.props.contrato,
        loading: true,
        mapKey: prevState.mapKey + 1,
        imageUrl: ""
      }), () => {
        this.updateImage();
      });
    }
  }

  getLatLngContrato = async (contrato) => {
    try {
      // Prepara los datos para enviar al endpoint

      let data = {
        "contrato": contrato
      };
      console.log(data);
      // Realiza la solicitud POST al endpoint usando axios
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getlatlngcontrato', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('tkn')}`
        },
        withCredentials: true // Incluye credenciales si es necesario
      });


      if (response.data.success) {
        console.log("Datos recibidos de latitud: ", response.data.data);
        this.setState({
          LastMsgLat: response.data.data[0].LastMsgLat,
          LastMsgLong: response.data.data[0].LastMsgLong,
          infocontrato: this.props.contrato,
          loading: true,
          mapKey: prevState.mapKey + 1,
          imageUrl: ""
        }, () => {
          this.updateImageAjustada();
        })

      } else {

        console.error("Error en la respuesta: ", response.data.message);

      }
    } catch (error) {

      console.error("Error al obtener la latitud y longitud del contrato: ", error);

    }
  }


  updateImageAjustada = () => {
    console.log("Latitud", this.state.LastMsgLat);
    console.log("Longitud", this.state.LastMsgLong);
    console.log("Input");
    const center = { lat: this.state.LastMsgLat, lng: this.state.LastMsgLong };
    const marker = `&markers=color:red|label:A|${center.lat},${center.lng}`;
    const size = '330x330';
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat},${center.lng}&zoom=16&size=${size}&maptype=satellite${marker}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    this.setState({ imageUrl: imageUrl, loading: true });
  }

  updateImage = () => {
    console.log("Normal");
    const center = { lat: this.state.infocontrato.LastMsgLat, lng: this.state.infocontrato.LastMsgLong };
    const marker = `&markers=color:red|label:A|${center.lat},${center.lng}`;
    const size = '330x330';
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat},${center.lng}&zoom=16&size=${size}&maptype=satellite${marker}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    this.setState({ imageUrl: imageUrl, loading: true });
  }

  render() {
    return (
      <div style={{ height: '330px', width: '100%' }} className='imgUltimoPuntoControl'>
        {this.state.loading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', zindex: 1000 }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <img
          onLoad={() => this.setState({ loading: false })}
          onError={() => this.setState({ loading: false })}
          src={this.state.imageUrl}
        />
      </div>
    );


  }
}



export default StaticMap;
